import { AppleMusicIcon, SpotifyIcon } from "../components/Icons/Icons";

const data = [
    {
        id: "1",
        logo: <div className="w-10"><SpotifyIcon /></div>,
        partner: "Sportify",
        streams: "214,208"
    },
    {
        id: "2",
        logo: <div className="w-10"><AppleMusicIcon /></div>,
        partner: "Apple Music",
        streams: "2,208"
    },
]

export default data