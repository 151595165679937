/* eslint-disable import/no-anonymous-default-export */
export default () => {
    return (
        <svg
            style={{ width: "100%", height: "100%" }}
            viewBox="0 0 86 86"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_4733_19460)">
                <circle cx="43.1568" cy="39.4693" r="33.1226" fill="#FFBD59" />
                <circle
                    cx="43.1568"
                    cy="39.4693"
                    r="31.5253"
                    stroke="#FAF7F2"
                    strokeWidth="3.19465"
                />
            </g>
            <rect
                x="22.5254"
                y="18.8379"
                width="41.2621"
                height="41.2621"
                rx="10.5945"
                fill="#FFBD59"
            />
            <path
                d="M34.7354 31.4595C34.7354 30.3972 35.5965 29.5361 36.6587 29.5361H47.341C49.163 29.5361 49.9638 31.8337 48.5365 32.9662L37.8541 41.4416C36.5936 42.4418 34.7354 41.5441 34.7354 39.9349V31.4595Z"
                fill="#1F1F1F"
            />
            <path
                d="M51.2119 47.2349C51.2119 48.2971 50.3508 49.1582 49.2886 49.1582L38.6062 49.1582C36.7843 49.1582 35.9835 46.8606 37.4108 45.7282L48.0931 37.2527C49.3537 36.2525 51.2119 37.1503 51.2119 38.7594L51.2119 47.2349Z"
                fill="#1F1F1F"
            />
            <defs>
                <filter
                    id="filter0_d_4733_19460"
                    x="0.916269"
                    y="0.875933"
                    width="84.4809"
                    height="84.4809"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3.64716" />
                    <feGaussianBlur stdDeviation="4.55896" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.35 0 0 0 0 0.161718 0 0 0 0 0.025375 0 0 0 0.21 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_4733_19460"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_4733_19460"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
