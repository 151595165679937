import MinusIcon from "../components/Icons/MinusIcon"
import Polygon from "../components/Icons/Polygon"

const data = [
    {
        id: "1",
        track: "TRUMPETS",
        artist: "Justfex",
        streams: "5,412",
        progress: "5.32%",
        icons: <div><Polygon fillColor="#FFBD59"/></div>
    },

    {
        id: "2",
        track: "Miss Ipipi ft Amada",
        artist: "Justfex",
        streams: "5,412",
        progress: "4.75%",
        icons: <div className="-rotate-180"><Polygon fillColor="#EB5757"/></div>
    },

    {
        id: "3",
        track: "Nervous",
        artist: "Justfex",
        streams: "5,412",
        progress: "5.04%",
        icons: <div><MinusIcon/></div>
    },

    {
        id: "4",
        track: "La Vie En Rose ft. Emily Watts",
        artist: "Justfex",
        streams: "5,412",
        progress: "5.70%",
        icons: <div><Polygon fillColor="#FFBD59"/></div>
    },
]

export default data