interface Props {
  image?: string;
  track?: string;
  artist?: string;
}

function Trackrelease({ track, artist, image }: Props) {
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 w-[50px] h-[50px]">
        <img className="w-full h-full rounded" src={image} alt="" />
      </div>
      <div className="ml-3">
        <p className="text-gray-900 text-xl whitespace-no-wrap">
          <span>{track||"N/A"}</span>
        </p>
        <p className="text-gray-900 whitespace-no-wrap">
          {<span>{artist}</span>}
        </p>
      </div>
    </div>
  );
}

export default Trackrelease;
