import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import BarIcon from "../Icons/BarIcon";
import WhiteLogo from "../Icons/LandingLogo";
import FacebookIcon from "../Icons/FacebookIcon";
import GoogleIcon from "../Icons/GoogleIcon";
import InstaIcon from "../Icons/InstaIcon";
import TwitterIcon from "../Icons/TwitterIcon";
import Xmark from "../Icons/Xmark";

export interface SideBarHanldeProps {
  onClick: () => void;
}

export function SideBarHandle({
  onClick,
  children,
}: React.PropsWithChildren<SideBarHanldeProps>) {
  return (
    <button
      className="flex items-center cursor-pointer h-[64px] w-[44px]"
      onClick={onClick}>
      {children}
    </button>
  );
}

const HomeMobileSideBar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const token = localStorage.getItem("token");
  return (
    <div className="">
      <div className="flex justify-between items-center">
        <div className="h-[54px] w-[54px]">
          <WhiteLogo />
        </div>
        <div>
          {showSidebar ? (
            <SideBarHandle onClick={() => setShowSidebar(!showSidebar)}>
              <Xmark strokeColor="white" />
            </SideBarHandle>
          ) : (
            <SideBarHandle onClick={() => setShowSidebar(!showSidebar)}>
              <BarIcon />
            </SideBarHandle>
          )}
        </div>
      </div>
      {showSidebar ? (
        <div className="text-white font-semibold text-2xl mt-5">
          <div className="px-3">
            <div className="flex flex-col gap-8">
              <div>
                <Link to={"/"}>Home</Link>
              </div>
              <div>
                <Link to="/partners">Partners</Link>
              </div>
              <div>
                <Link to="/faq">
                  FAQ
                </Link>
              </div>
              <div>
                <Link to="/privacypolicy">Privacy Policy</Link>
              </div>
              <div>
                <Link to="/termsofuse">Terms Of Service</Link>
              </div>
            </div>
            <div>
              {token ? (
                <div className="mt-10">
                  <Link to={"/dashboard/my-music"}>
                    <Button className="w-full">Dashboard</Button>
                  </Link>
                </div>
              ) : (
                <div className="mt-10">
                  <div>
                    <Link to={"/login"}>
                      <Button className="w-full" color="bg-white">
                        Log In
                      </Button>
                    </Link>
                  </div>
                  <div className="mt-3 mb-10">
                    <Link to={"/signup"}>
                      <Button className="w-full">Get Started</Button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default HomeMobileSideBar;

