/* eslint-disable import/no-anonymous-default-export */
interface Props {
  fillColor?: string;
}
export default ({ fillColor }: Props) => {
  return (
    <svg
      style={{ width: "100%", height: "100%" }}
      viewBox="0 0 11 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.0524902"
        y="0.202148"
        width="9.95154"
        height="1.66406"
        fill="#BDBDBD"
      />
    </svg>
  );
};
