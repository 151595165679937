import React, { useState } from "react";
import Input from "../Input/Index";
import Tooltip from "../Tooltip/Tooltip";
import CheckBox from "../CheckBox/CheckBox";
import Button from "../Button/Button";
import { PuffLoader } from "react-spinners";
import { useFormik } from "formik";
import * as Yup from "yup";
import sendRequest from "../axios";
import { toast } from "react-toastify";
import ErrorTypography from "../ErrorTypography";
import { error } from "console";
import { useDashboardContext } from "../Dashboard/DashboardContext";
import moment from "moment";

interface SelectBoxProps {
  id: string;
  checked?: boolean;
  value: string;
  label: string;
  name: string;
  type: "radio" | "checkbox";
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

interface formProps {
  onsumbit: () => void;
}

function SupportForm({ onsumbit }: formProps) {
  const { fetchPlaylistRequests } = useDashboardContext();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  type ITrackMood =
    | "chill"
    | "happy"
    | "fierce"
    | "sad"
    | "energetic"
    | "romantic"
    | "meditative"
    | "sexy"
    | "none";
  const TrackMood: { id: ITrackMood; value: string }[] = [
    {
      id: "chill",
      value: "Chill",
    },
    {
      id: "happy",
      value: "Happy",
    },
    {
      id: "fierce",
      value: "Fierce",
    },
    {
      id: "sad",
      value: "Sad",
    },
    {
      id: "energetic",
      value: "Energetic",
    },
    {
      id: "romantic",
      value: "Romantic",
    },
    {
      id: "meditative",
      value: "Meditative",
    },
    {
      id: "sexy",
      value: "Sexy",
    },
    {
      id: "none",
      value: "None Of These",
    },
  ];

  const release_schedule = [
    {
      value: "yes",
      label: "Yes",
    },
    {
      value: "no",
      label: "No",
    },
  ];

  const track_attributes = [
    {
      value: "cover",
      label: "Cover",
    },
    {
      value: "remix",
      label: "Remix",
    },
    {
      value: "instrumental",
      label: "Instrumental",
    },
    {
      value: "live",
      label: "Live Recording",
    },
  ];

  const SelectBox: React.FC<SelectBoxProps> = ({
    value,
    checked,
    onChange,
    label,
    name,
    type,
    className,
    id,
  }) => {
    return (
      <div className="flex gap-2">
        <CheckBox
          defaultChecked={checked}
          name={name}
          value={value}
          type={type}
          onChange={onChange}
          className={className || ""}
          id={id}
        />
        <label className="text-black" htmlFor={id}>
          {label}
        </label>
      </div>
    );
  };

  const SupportFormSchema = Yup.object().shape({
    artist_name: Yup.string().required("Required"),
    artist_bio: Yup.string().required("Required"),
    release_title: Yup.string().required("Required"),
    release_date: Yup.string().required("Required"),
    //press_photos: Yup.string().required("Required"),
    instagram_profile: Yup.string().url(),
    facebook_profile: Yup.string().url(),
    x_profile: Yup.string().url(),
    youtube_profile: Yup.string().url(),
    tiktok_profile: Yup.string().url(),
    soundcloud_profile: Yup.string().url(),
    website: Yup.string().url(),
    // probably want to add validation that the string is a base64 string
    focus_track: Yup.string().required("Required"),
    release_story: Yup.string(),
    release_schedule: Yup.string().oneOf(["yes", "no"]).required("Required"),
    similar_artist: Yup.string(),
    mood: Yup.object()
      .shape({
        chill: Yup.boolean(),
        happy: Yup.boolean(),
        fierce: Yup.boolean(),
        sad: Yup.boolean(),
        energetic: Yup.boolean(),
        romantic: Yup.boolean(),
        meditative: Yup.boolean(),
        sexy: Yup.boolean(),
        none: Yup.boolean(),
      })
      .test("Select at least two mood", "Select at least two mood", (value) => {
        const length = Object.values(value).filter((v) => v).length;
        return !(length < 1 || length > 2);
      }),
    attribute: Yup.string()
      .oneOf(track_attributes.map((a) => a.value)),
    interviews: Yup.string(),
    tours: Yup.string(),
    visual_assets: Yup.string(),
    ad_campaign: Yup.string(),
    radio_campaign: Yup.string(),
    physical_product: Yup.string(),
    press_release: Yup.string(),
    marketing_plan: Yup.object().shape({
      file: Yup.string().required("file is required"),
      file_name: Yup.string().required("file is required"),
    }),
  });

  const { handleSubmit, handleChange, touched, values, getFieldProps, errors } =
    useFormik({
      initialValues: {
        artist_name: "",
        artist_bio: "",
        release_title: "",
        release_date: "",
        press_photos: [],
        instagram_profile: "",
        facebook_profile: "",
        x_profile: "",
        youtube_profile: "",
        tiktok_profile: "",
        soundcloud_profile: "",
        website: "",
        focus_track: "",
        release_story: "",
        release_schedule: "",
        similar_artist: "",
        mood: {
          chill: false,
          happy: false,
          fierce: false,
          sad: false,
          energetic: false,
          romantic: false,
          meditative: false,
          sexy: false,
          none: false,
        },
        attribute: "",
        interviews: "",
        tours: "",
        visual_assets: "",
        ad_campaign: "",
        radio_campaign: "",
        physical_product: "",
        press_release: "",
        marketing_plan: {
          file: "",
          file_name: "",
        },
      },
      validationSchema: SupportFormSchema,
      onSubmit: async (vals) => {
        setLoading(true);
        const data = {
          ...vals,
          release_date: vals.release_date + "T00:00:00.000Z",
        };
        try {
          const response = await sendRequest(
            "POST",
            `/api/v1/playlist-support`,
            data
          );
          console.log(response);
          if (response.status < 300) {
            onsumbit();
            fetchPlaylistRequests();
            toast.success(`${response.data.message}`);
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.errors);
          setErrorMessage(error);
        } finally {
          setLoading(false);
        }
      },
    });
  return (
    <div className="border border-transparent rounded-big text-white text-center p-5  max-w-[1038px]">
      <div>
        <form onSubmit={handleSubmit}>
          <div className="lg:flex gap-16">
            <div className="basis-1/2">
              <Input
                required
                className=""
                id="artist_name"
                placeholder="Artist/Band Name"
                type="text"
                label="Artist/Band Name"
                {...getFieldProps("artist_name")}
              />
              {touched.artist_name && errors.artist_name && (
                <ErrorTypography error={errors.artist_name} />
              )}
            </div>
            <div className="basis-1/2">
              <Input
                required
                className=""
                id="artist_bio"
                placeholder="Artist Bio"
                type="text"
                label="Artist Bio?"
                info="(Please enter detailed description about yourself)"
                {...getFieldProps("artist_bio")}
              />
              {touched.artist_bio && errors.artist_bio && (
                <ErrorTypography error={errors.artist_bio} />
              )}
            </div>
          </div>

          <div className="lg:flex gap-16 my-5">
            <div className="basis-1/2">
              <Input
                required
                className=""
                id="release_title"
                placeholder="Release Title"
                type="text"
                label="Release Title"
                {...getFieldProps("release_title")}
              />
              {touched.release_title && errors.release_title && (
                <ErrorTypography error={errors.release_title} />
              )}
            </div>
            <div className="basis-1/2">
              <Input
                required
                className=""
                id="release_date"
                placeholder="Release Date"
                type="date"
                min={moment(new Date()).format('YYYY-MM-DD')}
                label="Release Date"
                info="(Please be sure your release is scheduled to go live in not less than 4 weeks before pitching. If your release is to go live in less than 4 weeks, there may not be sufficient time to pitch your music.)"
                {...getFieldProps("release_date")}
              />
              {touched.release_date && errors.release_date && (
                <ErrorTypography error={errors.release_date} />
              )}
            </div>
          </div>

          <div className="lg:flex gap-16 my-5">
            <div className="basis-1/2">
              <Input
                className=""
                border="bg-white border"
                id="press_photos"
                placeholder=""
                type="file"
                multiple
                accept=".jpeg, .jpg, .JPG, .png"
                label="5 Artist Profile/Press Photos"
                info="(HD Quality)"
                onChange={(e) => {
                  // convert both files to base64,
                  if (!e.target.files?.length) return;
                  let files: string[] = [];
                  for (let i = 0; i < e.target.files.length; i++) {
                    readFileAsDataURL(e.target.files[i]).then((result) => {
                      files.push(result as string);
                    });
                  }
                  handleChange({
                    target: {
                      name: "press_photos",
                      value: files,
                    },
                  });
                }}
              />
              {touched.press_photos && errors.press_photos && (
                <ErrorTypography error={errors.press_photos?.toString()} />
              )}
            </div>
            <div className="basis-1/2">
              <Input
                className=""
                id="instagram_profile"
                placeholder="Instagram Profile Link"
                type="text"
                label="Instagram Profile Link"
                {...getFieldProps("instagram_profile")}
              />
              {touched.instagram_profile && errors.instagram_profile && (
                <ErrorTypography error={errors.instagram_profile} />
              )}
            </div>
          </div>

          <div className="lg:flex gap-16 my-5">
            <div className="basis-1/2">
              <Input
                className=""
                id="facebook_profile"
                placeholder="Facebook Profile Link"
                type="text"
                label="Facebook Profile Link"
                {...getFieldProps("facebook_profile")}
              />
              {touched.facebook_profile && errors.facebook_profile && (
                <ErrorTypography error={errors.facebook_profile} />
              )}
            </div>
            <div className="basis-1/2">
              <Input
                className=""
                id="x_profile"
                placeholder="X Profile Link"
                type="text"
                label="X Profile Link"
                {...getFieldProps("x_profile")}
              />
              {touched.x_profile && errors.x_profile && (
                <ErrorTypography error={errors.x_profile} />
              )}
            </div>
          </div>

          <div className="lg:flex gap-16 my-5">
            <div className="basis-1/2">
              <Input
                className=""
                id="youtube_profile"
                placeholder="YouTube Channel Link"
                type="text"
                label="YouTube Channel Link"
                {...getFieldProps("youtube_profile")}
              />
              {touched.youtube_profile && errors.youtube_profile && (
                <ErrorTypography error={errors.youtube_profile} />
              )}
            </div>
            <div className="basis-1/2">
              <Input
                className=""
                id="tiktok_profile"
                placeholder="TikTok Profile Link"
                type="text"
                label="TikTok Profile Link"
                {...getFieldProps("tiktok_profile")}
              />
              {touched.tiktok_profile && errors.tiktok_profile && (
                <ErrorTypography error={errors.tiktok_profile} />
              )}
            </div>
          </div>

          <div className="lg:flex gap-16 my-5">
            <div className="basis-1/2">
              <Input
                className=""
                id="soundcloud_profile"
                placeholder="Soundcloud Profile Link"
                type="text"
                label="Soundcloud Profile Link"
                {...getFieldProps("soundcloud_profile")}
              />
              {touched.soundcloud_profile && errors.soundcloud_profile && (
                <ErrorTypography error={errors.soundcloud_profile} />
              )}
            </div>
            <div className="basis-1/2">
              <Input
                className=""
                id="website"
                placeholder="Website link"
                type="text"
                label="Website link"
                {...getFieldProps("website")}
              />
              {touched.website && errors.website && (
                <ErrorTypography error={errors.website} />
              )}
            </div>
          </div>

          <div className="lg:flex gap-16 my-5">
            <div className="basis-1/2">
              <Input
                className=""
                border="bg-white border"
                id="focus_track"
                placeholder="Focus Track"
                type="file"
                accept=".wav, .mp3"
                label="Focus Track?"
                info={
                  <div>
                    (1) If your release is an EP or an album, you must select a
                    focus track to be pitched to the DSP and must be an
                    unreleased track. <br />
                    (2) Please upload focus track. Track must be properly
                    labelled or titled and must already be scheduled for
                    release.
                  </div>
                }
                infoClass="text-start"
                onChange={(e) => {
                  // convert the file to base64
                  if (!e.target.files?.length) return;
                  const file = e.target.files[0];
                  readFileAsDataURL(file).then((result) => {
                    handleChange({
                      target: {
                        name: "focus_track",
                        value: result,
                      },
                    });
                  });
                }}
              />
              {touched.focus_track && errors.focus_track && (
                <ErrorTypography error={errors.focus_track} />
              )}
            </div>
            <div className="basis-1/2">
              <Input
                className=""
                id="release_story"
                placeholder="What is the story of the release?"
                type="text"
                label="What is the story of the release?"
                {...getFieldProps("release_story")}
              />
              {touched.release_story && errors.release_story && (
                <ErrorTypography error={errors.release_story} />
              )}
            </div>
          </div>

          <div className="lg:flex gap-16 my-5">
            <div className="basis-1/2 mt-5">
              <div className="text-start mb-2 text-xs text-xd_grey">
                Is this release part of a larger release schedule?
              </div>
              {release_schedule.map((value, i) => {
                return (
                  <div key={i}>
                    <SelectBox
                      label={value.label}
                      id={"release_schedule_" + value.value}
                      type={"radio"}
                      checked={values.release_schedule === value.value}
                      onChange={handleChange}
                      value={value.value}
                      name={"release_schedule"}
                    />
                  </div>
                );
              })}
              {touched.release_schedule && errors.release_schedule && (
                <ErrorTypography error={errors.release_schedule} />
              )}
            </div>

            <div className="basis-1/2">
              <Input
                className=""
                id="similar_artist"
                placeholder="Your Answer"
                type="text"
                label="What artists are similar to the artists on this release?"
                info="When you describe your music, what artist(s) come to mind?"
                {...getFieldProps("similar_artist")}
              />
              {touched.similar_artist && errors.similar_artist && (
                <ErrorTypography error={errors.similar_artist} />
              )}
            </div>
          </div>

          <div className="lg:flex gap-16 my-5">
            <div className="basis-1/2 mt-5">
              <div className="flex gap-2">
                <div className="text-start mb-2 text-xs text-xd_grey">
                  Track Mood
                </div>
                <div>
                  <Tooltip value="Select up to two" />
                </div>
              </div>
              {TrackMood.map((t, i) => (
                <div key={i}>
                  <SelectBox
                    id={"mood_" + t.id}
                    value={t.id}
                    label={t.value}
                    name={"mood"}
                    type={"checkbox"}
                    className="rounded-none"
                    checked={values.mood[t.id]}
                    onChange={(e) => {
                      values.mood[e.target.value as ITrackMood] =
                        e.target.checked;
                      handleChange({
                        target: {
                          name: "mood",
                          value: values.mood,
                        },
                      });
                    }}
                  />
                </div>
              ))}
              {touched.mood && errors.mood && (
                <ErrorTypography error={Object.values(errors.mood).join("")} />
              )}
            </div>
            <div className="basis-1/2 mt-5">
              <div className="flex gap-2">
                <div className="text-start mb-2 text-xs text-xd_grey">
                  Track Attributes
                </div>
                {/*
                <div>
                  <Tooltip value="Select up to two" />
                </div>
                */}
              </div>
              {track_attributes.map((attribute, i) => {
                return (
                  <div key={i}>
                    <SelectBox
                      id={"attribute_" + attribute.value}
                      label={attribute.label}
                      onChange={handleChange}
                      value={attribute.value}
                      name={"attribute"}
                      type={"radio"}
                      checked={values.attribute === attribute.value}
                    />
                  </div>
                );
              })}
              {touched.attribute && errors.attribute && (
                <ErrorTypography error={Object.values(errors.attribute).join("")} />
              )}
            </div>
          </div>

          <div className="text-start my-10 text-black">
            <div className="font-medium">
              Do you plan to have any of the following for your track?
            </div>

            <div className="my-5">
              <div>
                <div className="lg:flex gap-16 my-5">
                  <div className="basis-1/2">
                    <Input
                      className=""
                      id="interviews"
                      placeholder="Confirmed press or interviews"
                      type="text"
                      label="Confirmed press or interviews"
                      info="(Please list with relevant details, including dates if known)"
                      {...getFieldProps("interviews")}
                    />
                    {touched.interviews && errors.interviews && (
                      <ErrorTypography error={errors.interviews} />
                    )}
                  </div>
                  <div className="basis-1/2">
                    <Input
                      className=""
                      id="tours"
                      placeholder="Upcoming shows, tours, or livestreams"
                      type="text"
                      label="Upcoming shows, tours, or livestreams"
                      info="(Paste a link to the schedule or enter the routing below)"
                      {...getFieldProps("tours")}
                    />
                    {touched.tours && errors.tours && (
                      <ErrorTypography error={errors.tours} />
                    )}
                  </div>
                </div>

                <div className="lg:flex gap-16 my-5">
                  <div className="basis-1/2">
                    <Input
                      className=""
                      id="visual_assets"
                      placeholder="Visual assets"
                      type="text"
                      label="Visual assets"
                      info="(Will there be a video accompanying this release (official, lyric, visualizer, etc.? Please provide a link to the content, if possible. It will be kept private)"
                      {...getFieldProps("visual_assets")}
                    />
                    {touched.visual_assets && errors.visual_assets && (
                      <ErrorTypography error={errors.visual_assets} />
                    )}
                  </div>
                  <div className="basis-1/2">
                    <Input
                      className=""
                      id="ad_campaign"
                      placeholder="Advertising campaign"
                      type="text"
                      label="Advertising campaign"
                      info="(Please provide us with details on any campaigns you are running. How much is the advertising budget? Where will you be spending it?)"
                      {...getFieldProps("ad_campaign")}
                    />
                    {touched.ad_campaign && errors.ad_campaign && (
                      <ErrorTypography error={errors.ad_campaign} />
                    )}
                  </div>
                </div>

                <div className="lg:flex gap-16 my-5">
                  <div className="basis-1/2">
                    <Input
                      className=""
                      id="physical_product"
                      placeholder="Physical product"
                      type="text"
                      label="Physical product"
                      info=" (Will there be vinyl, merch, or any physical product around this release?)"
                      {...getFieldProps("physical_product")}
                    />
                    {touched.physical_product && errors.physical_product && (
                      <ErrorTypography error={errors.physical_product} />
                    )}
                  </div>
                  <div className="basis-1/2">
                    <Input
                      className=""
                      id="radio_campaign"
                      placeholder="Radio campaign"
                      type="text"
                      label="Radio campaign"
                      info=" (Please list the company you’ll be working with and any relevant details about the campaign and/or confirmed station adds)"
                      {...getFieldProps("radio_campaign")}
                    />
                    {touched.radio_campaign && errors.radio_campaign && (
                      <ErrorTypography error={errors.radio_campaign} />
                    )}
                  </div>
                </div>

                <div className="lg:flex gap-16 my-5">
                  <div className="basis-1/2">
                    <Input
                      className=""
                      id="press_release"
                      placeholder="Other press releases"
                      type="text"
                      label="Other press releases"
                      info=" (Got something else going on around this release that doesn’t fit one of the above areas? Tell us about it.)"
                      {...getFieldProps("press_release")}
                    />
                    {touched.press_release && errors.press_release && (
                      <ErrorTypography error={errors.press_release} />
                    )}
                  </div>
                  <div className="basis-1/2">
                    <Input
                      accept=".pdf, .doc, .docx"
                      className=""
                      border="bg-white border"
                      id="marketing_plan"
                      placeholder=""
                      type="file"
                      label="Upload your marketing plans"
                      info="(pdf, doc, docx)"
                      onChange={(e) => {
                        // convert the file to base64
                        if (!e.target.files?.length) return;
                        const file = e.target.files[0];
                        values.marketing_plan.file_name = file.name;
                        readFileAsDataURL(file).then((result) => {
                          values.marketing_plan.file = result as string;
                          console.log(values.marketing_plan)
                          handleChange({
                            target: {
                              name: "marketing_plan",
                              value: values.marketing_plan,
                            },
                          });
                        });
                      }}
                    />
                    {touched.marketing_plan && errors.marketing_plan && (
                      // we only want the first error because the errors are the same.
                      // we only want to make sure the file and file_name are present
                      <ErrorTypography error={Object.values(errors.marketing_plan)[0]} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="my-10 justify-end flex">
            <Button disabled={loading} type="submit">
              {loading ? (
                <div className="flex justify-center text-white">
                  <PuffLoader className="h-4 w-4" size={25} color="white" />
                  Please wait...
                </div>
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SupportForm;

// TODO: make this function available globally
async function readFileAsDataURL(file: File) {
  let result_base64 = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });

  return result_base64;
}
