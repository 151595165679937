import { Location } from "react-router-dom";

export function getQueriesMap(location: Location) {
  const queries = location.search.replace("?", "").split("&");
  let queriesMap: { [index: string]: string } = {};
  queries.forEach((element) => {
    const elements = element.split("=");
    queriesMap[elements[0]] = elements[1];
  });
  return queriesMap;
}