import React from 'react'
import TopPallets from './Pallets/TopPallets'

function TopPanel() {
    return (
        <div className=''>
            <TopPallets />
        </div>
    )
}

export default TopPanel