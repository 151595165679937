import { AdaptrIcon, AnghamiIcon, AppleMusicTextIcon, AudiomackPlaneIcon, BoomplayMusicIcon, ClaroMusicaIcon, DigitalIcon, FacebookIcon, IheartRadioIcon, InstagramIcon, JooxIcon, KDigitalIcon, KKboxIcon, LineMusicIcon, MediaNetIcon, NapsterIcon, NetEaseIcon, PandoraIcon, PelotonIcon, PretzelIcon, QobuzIcon, RessoIcon, SaavnIcon, SpotifyTextIcon, TencentIcon, TidalTexticon, TiktokIcon, YouTubeMusicIcon } from '../components/Icons/MusicDistributionIconList'

const text = [
  {
    id: 1,
    icon: (<SpotifyTextIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">Spotify </span>is the world’s leading music streaming service with more than 200 million users. Artists get paid every time someone listens to their music on Spotify, with the service paying out royalties after roughly 90 days.
      </div>
    ),
  },

  {
    id: 2,
    icon: <AppleMusicTextIcon />,
    body:
      <div className='lg:mt-0 mt-5'>
        With over 50 million subscribers, <span className="font-bold">Apple Music</span> is the second largest music streaming service in the world. Available in over 100 countries, you can upload your music for streaming on Apple Music, or downloads on iTunes.
      </div>
    ,
  },

  {
    id: 3,
    icon: (<TidalTexticon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">Tidal</span> is an artist-owned music streaming service, offering subscribers access to a catalog of high-fidelity, lossless audio, boasting as many as 25 million tracks and 75,000 HD quality music videos. TIDAL also features expert editorial from experienced music journalists.
      </div>
    ),
  },

  {
    id: 4,
    icon: (<BoomplayMusicIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">Boomplay</span> is the biggest music and video streaming & download service in Africa. It is currently the biggest and fastest growing music app in Africa with a catalog of over 5 million songs and videos. Users can stream songs and videos online free of charge or subscribe to one of the daily, weekly or monthly plans to save songs and videos offline or purchase content to download.
      </div>
    ),
  },

  {
    id: 5,
    icon: (<PandoraIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">Pandora</span> is driven by a single purpose: unleashing the infinite power of music by connecting artists and fans, whether through earbuds, car speakers, live on stage or anywhere fans want to experience it.
      </div>
    )
  },

  {
    id: 6,
    icon: (<AudiomackPlaneIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">Audiomack</span> is an on-demand music streaming and audio discovery platform that allows artists and creators to upload limitless music and podcasts for listeners through its mobile apps and website.
      </div>
    )
  },

  {
    id: 7,
    icon: (<div> <FacebookIcon /> <InstagramIcon /></div>),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">Meta</span> is the biggest social media network with more than 845 million active monthly users. Meta owns Facebook and Instagram. When we send your music to these social services, you get paid UGC royalties on these platforms.
      </div>
    )
  },

  {
    id: 8,
    icon: (<TiktokIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">TikTok</span> is the leading destination for short-form mobile video. Let your fans create and share fun viral videos using your music. When TikTok users lip-sync or dance along to your track, or play your song in the background of some hilarious, personal, or creative moment they’ve captured, your music will reach more listeners.
      </div>
    )
  },

  {
    id: 9,
    icon: (<SaavnIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">Saavn</span> is the leading streaming music provider in India, offering the largest selection of Bollywood, devotional, and regional Indian content, along with a catalog of over 25 million English and international tracks. Saavn’s award-winning apps provide both paid subscription and ad-supported options.
      </div>
    )
  },

  {
    id: 10,
    icon: (<IheartRadioIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">iHeartRadio</span> was created to combine America’s favorite radio stations, podcasts and music together in one mobile app. iHeartRadio has over 150M users, listening across 250 platforms and on over 2,000 different connected devices.
      </div>
    )
  },

  {
    id: 11,
    icon: (<KKboxIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">KKBox</span> is Asia’s leading music streaming service. With the support from more than 500 international major and local independent music labels, KKBox features 40 million legal tracks, including the most comprehensive Asia-Pop music library, and is currently available in Taiwan, Hong Kong, Japan, Singapore and Malaysia with over 10 million users.
      </div>
    )
  },

  {
    id: 12,
    icon: (<ClaroMusicaIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">ClaroMúsica</span> allows users to stream songs both online and offline, and also features an a la carte download option. This digital music service, available in 16 countries as part of America Móvil Group, offers weekly or monthly subscription plans for both streaming and download.
      </div>
    )
  },

  {
    id: 13,
    icon: (<YouTubeMusicIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">YouTube</span> Music is a music streaming service developed by YouTube, a subsidiary of Google. It provides a tailored interface for the service, oriented towards music streaming, allowing users to browse through songs and music videos on YouTube based on genres, playlists, and recommendations
      </div>
    )
  },

  {
    id: 14,
    icon: (<TencentIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">Tencent</span> Music Entertainment Group (“TME”) is the leading online music entertainment platform in China. They offer online music and music-centric social entertainment services with the four famous music mobile apps in China – QQ Music, Kugou Music, Kuwo Music and WeSing – to over 800 million highly diverse and engaged users..
      </div>
    )
  },

  {
    id: 15,
    icon: (<JooxIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">JOOX</span> is a music app made for music lovers. With more than 45 million songs in its music library from all over the world, JOOX connects you to your favorite artists, songs, albums and personalized playlists. Users can discover great new music with recommended songs and radio stations that fit your taste and mood.
      </div>
    )
  },

  {
    id: 16,
    icon: (<NetEaseIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">NetEase</span> Cloud Music is a freemium music streaming service developed and owned by NetEase, Inc, based in China. The streaming service has over 300 million users, and a music database consisting of over 10 million songs.
      </div>
    )
  },

  {
    id: 17,
    icon: (<NapsterIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">Napster</span> is the power to play the music you love anytime, anywhere. Stream million songs and dive into the Listener Network to connect with music fans from around the globe. Download your favorite songs, albums and playlists for offline access so you never miss a beat.
      </div>
    )
  },

  {
    id: 18,
    icon: (<DigitalIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        Established in 2004, <span className="font-bold">7digital</span> is a leading digital media delivery company based in London and operating globally.
      </div>
    )
  },

  {
    id: 19,
    icon: (<PretzelIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">Pretzel</span> is the world’s largest collection of Twitch and YouTube-safe music for creators. Aka, you.
      </div>
    )
  },

  {
    id: 20,
    icon: (<AdaptrIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">Adaptr</span> connects you with the newest startups and apps looking for quality music to enhance their customer experience. Adaptr’s all-in-one music solution provides businesses with the licensing, compliance, and robust SDKs they need to stream music legally. Best of all, our secure music APIs manage tracking, reporting, and payments, to protect your catalogs, and help you monetize new streaming platforms.
      </div>
    )
  },

  {
    id: 21,
    icon: (<LineMusicIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">LINE MUSIC</span> is a music streaming service with an expansive collection of music available for unlimited play and sharing with friends.
      </div>
    )
  },

  {
    id: 22,
    icon: (<PelotonIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        A new concept in fitness <span className="font-bold">Peloton</span> uses a combination of technology, content and best-in-class instructors to empower millions-strong communities through fitness.
      </div>
    )
  },

  {
    id: 23,
    icon: (<QobuzIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">Qobuz</span> is a music streaming and download service dedicated to all music lovers. Specifically, <span className="font-bold">Qobuz</span> offers streaming and download subscriptions at CD and Hi-Res Audio quality, a great variety of genres and exclusive editorial content.
        <span className="font-bold">Qobuz</span> is partnered with some of the biggest Hi-Fi brands such as Sony, Chromecast, DTS Play-Fi, Sonos, Yamaha and Devialet.
      </div>
    )
  },

  {
    id: 24,
    icon: (<MediaNetIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">MediaNet</span> is one of the biggest providers of digital music, calling upon a heavy-hitting list of distribution retailers. With domestic & international outlets, MediaNet places your music into a global marketplace
      </div>
    )
  },

  {
    id: 25,
    icon: (<KDigitalIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">Kdigital</span> acts as a content provider to a wide range of telecom-based music services throughout Asia, providing services spanning digital sync and streaming activities to their outlets.
      </div>
    )
  },

  {
    id: 26,
    icon: (<AnghamiIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">Anghami</span> is the leading music streaming platform in the Middle East and North Africa (MENA), amassing more than 30 million users, including 7 million monthly active users. With your music available at Anghami, fans are able to play and discover your music as a free ad-supported user or as a subscriber with various premium features.
      </div>
    )
  },

  {
    id: 27,
    icon: (<RessoIcon />),
    body: (
      <div className='lg:mt-0 mt-5'>
        <span className="font-bold">Resso</span> is a music streaming app designed for the next generation of music enthusiasts. Leveraging a powerful recommendation algorithm and innovative product features, Resso hopes to become the most influential platform for music discovery and artist promotion.
      </div>
    )
  },




];

export default text;