import Header from "../../components/Header/Header";
import TrackImg from "../../assets/TrackImg.png";
import IRelease from "../../interfaces/release";
import moment from "moment";
import YellowEllipse from "../../components/Icons/YellowEllipse";
import TrackReleaseDetailsField from "../../components/TrackReleaseDetailsField/TrackReleaseDetailsField";
import { Link } from "react-router-dom";
import BackArrowIcon from "../../components/Icons/BackArrowIcon";

function MultipleTracksReleaseDetails({ release }: { release: IRelease }) {
  const releaseDate = moment(
    release.distribution?.release_time
      ? release.distribution?.release_time
      : "N/A"
  ).format("LL");
  function formatSeconds(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  }
  return (
    <div>
      <div>
        <Header
          children={"My Music"}
          icon={
            <div className="h-[32px]">
              <Link to={"/dashboard/my-music"}>
                <BackArrowIcon />
              </Link>
            </div>
          }
        />
      </div>
      <div className="my-10">
        <div className="space-y-8">
          <div className="relative min-h-full flex items-center gap-10">
            <div>
              <img
                src={release.cover_art ? release.cover_art : TrackImg}
                alt=""
                className="rounded-[10px] w-[180px]"
              />
            </div>
            <div>
              <div className="lg:text-6xl md:text-3xl text-2xl font-semibold">{release.title}</div>
              <div className="text-lg font-medium">
                {release.release_artist?.name}
              </div>
            </div>
          </div>
          <div className="space-y-8">
            <div className="flex items-center gap-3">
              <div className="inline-block">Album Info</div>
              <hr className="border border-[#E0E0E0] flex-grow inline-block" />
            </div>
            <div>
              <div className="grid grid-cols-2 gap-4">
                <div className="">
                  <TrackReleaseDetailsField
                    label={"UPC:"}
                    field={release.upc_code}
                    textColor="text-blue-500"
                  />
                </div>

                <div className="">
                  <TrackReleaseDetailsField
                    label={"Release ID:"}
                    field={release.id}
                  />
                </div>
                <div className="">
                  <TrackReleaseDetailsField
                    label={"Genre:"}
                    field={release.genre?.name}
                  />
                </div>
                <div>
                  <TrackReleaseDetailsField
                    label={"Language:"}
                    field={release.language}
                  />
                </div>
                <div>
                  <TrackReleaseDetailsField
                    label={"Price Category:"}
                    field={release.distribution?.price_per_track ?
                      release.distribution?.currency_code +
                      " " +
                      release.distribution?.price_per_track / 100 : "N/A"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-8">
            <hr className="border border-[#E0E0E0] max-w-[900px] w-full inline-block" />
            <div className="">
              <div className="space-y-5 my-5">
                <div className="">
                  <TrackReleaseDetailsField
                    label={"Release Date:"}
                    field={releaseDate}
                  />
                </div>
                <div className="my-10">
                  <TrackReleaseDetailsField
                    label={"Pre-order Date:"}
                    field={release.distribution?.preorder_date ? moment(release.distribution?.preorder_date).format(
                      "LL"
                    ) : ""}
                  />
                </div>
                <div className="my-10">
                  <TrackReleaseDetailsField
                    label="Copyright (C):"
                    field={`${release.copyright_year} ${release.copyright_holder}`}
                  />
                </div>
              </div>
            </div>
          </div>
          <section className="pl-4 my-10">
            {release.tracks?.map((track, index) => (
              <div className="space-y-8 my-10" key={index + 1}>
                <div className="flex items-center gap-2">
                  <div className="w-3.5 h-3.5">
                    <YellowEllipse />
                  </div>
                  <p className="inline-block w-fit">Track Info</p>
                  <hr className="border border-[#E0E0E0] flex-grow inline-block" />
                </div>
                <div className="ml-5">
                  <div className="grid grid-cols-2 gap-5">
                    <div>
                      <TrackReleaseDetailsField
                        label={"Track Name:"}
                        field={track.title}
                      />
                    </div>
                    <div>
                      <TrackReleaseDetailsField
                        label={"Featured Artist"}
                        field={track.contributors?.
                          filter((c) => c.role === "Featured Artist").
                          map(a => a.name).join(", ")}
                      />
                    </div>
                    <div>
                      <TrackReleaseDetailsField
                        label={"ISRC:"}
                        field={track.isrc_code}
                      />
                    </div>
                    <div>
                      <TrackReleaseDetailsField
                        label={"Genre:"}
                        field={release.genre?.name}
                      />
                    </div>
                    <div>
                      <TrackReleaseDetailsField
                        label={"Explicit Status:"}
                        field={
                          track.explicit_content ? "EXPLICIT" : "NON EXPLICIT"
                        }
                      />
                    </div>
                    <div>
                      <TrackReleaseDetailsField
                        label={"Song Writers:"}
                        field={track.contributors
                          ?.filter((c) => {
                            return c.role === "Song Writer";
                          }).map((a) => a.name).join(", ")}
                      />
                    </div>
                    <div>
                      <TrackReleaseDetailsField
                        label={"Preview:"}
                        field={
                          formatSeconds(track.preview_start) +
                          " - " +
                          formatSeconds(track.preview_end)
                        }
                      />
                    </div>
                    <div>
                      <TrackReleaseDetailsField
                        label={"Language:"}
                        field={track.vocals_language}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </section>
          <section className="w-full">
            <div className="flex items-center gap-3">
              <p className="inline-block w-fit">Music Stores</p>
              <hr className="border border-[#E0E0E0] flex-grow inline-block" />
            </div>
            <div className="flex flex-row flex-wrap">
              {release.distribution?.stores?.map((store, index) => (
                <div className="basis-1/4 p-5" key={index + 1}>
                  <p>{store.name}</p>
                </div>
              ))}
            </div>
          </section>
          <section className="w-full mt-5">
            <div className="flex items-center gap-3">
              <p className="inline-block w-fit">Artist Profiles</p>
              <hr className="border border-[#E0E0E0] flex-grow inline-block" />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default MultipleTracksReleaseDetails;
