interface Data {
  id?: string;
  placeholder: string;
  type?: string;
  icon?: React.ReactNode;
  value?: string;
  bgColor?: string;
  maxWidth?: string
}

const SearchInput: React.FC<Data> = ({
  id,
  type,
  placeholder,
  icon,
  value,
  bgColor,
  maxWidth
}) => {
  function clearSearch() {
    // TODO: implement code
  }
  return (
    <div className={`${maxWidth || 'max-w-[220px]'}`}>
    <div className={`flex items-center rounded-lg bg-[#F2F2F2]  input:hidden border-xd_light_grey border-[1px] box-border p-2 ${bgColor}`}>
      <span className="inline-block mr-2 h-6" onClick={clearSearch}>
        {icon}
      </span>
      <input
        id={id}
        className={`font-normal bg-transparent text-sm text-black float-right focus:outline-none`}
        type={type}
        placeholder={placeholder}
        defaultValue={value}
      />
      
    </div>
    </div>
  );
};

export default SearchInput;
