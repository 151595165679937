import { getReleaseStatus } from "../../data/getReleaseStatus";
import Action, { ReleaseAction } from "../Action/Action";
import TrackImg from "../../assets/TrackImgLg.png";
import IRelease from "../../interfaces/release";
// import sendRequest from "../axios";
// import { toast } from "react-toastify";

interface props {
  release: IRelease;
  showModal: () => void;
}

function ReleaseGridViewCard({ release, showModal }: props) {
  let { title, id, track, release_artist, status, cover_art, distribution } = release;

  return (
    <div className="my-5">
      <div className="bg-white border border-[#F2F2F2] shadow-md rounded-lg p-2 w-full space-y-3">
        <div className="relative min-h-full">
          <img
            src={cover_art ? cover_art : TrackImg}
            alt={title + " cover art"}
            className="w-full h-full rounded-md"
          />
          <div className="absolute top-1 items-center w-full pt-2 text-center">
            {getReleaseStatus(status || "")}
          </div>
        </div>
        <div className="my-3 text-sm font-medium flex  justify-between">
          <p className="">
            {track}
            <span>{title}</span>
          </p>
          <div className="">
            {/* NOTE: update the other action if this is updated */}
            <ReleaseAction
              release={release}
              showModal={showModal}
            />
          </div>
        </div>
        <div className="text-xs font-normal">{release_artist?.name}</div>
        <div className="my-5 text-xd_lightblack text-xs">
          RD:{" "}
          {distribution?.release_time &&
            distribution.release_time !== "0001-01-01T00:00:00Z"
            ? new Date(distribution?.release_time || "").toLocaleString()
            : "N/A"}
        </div>
      </div>
    </div>
  );
}

export default ReleaseGridViewCard;
