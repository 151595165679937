const data = [
  {
    id: "1",
    header: "1. Introduction",
    body: (
      <div>
        Welcome to XDistro Music Limited.
        <p className="my-5">
          XDistro Music Limited (“XDistro Music”, “us”, “we”, or “our”) operates
          <a href="https://xdistromusic.com">
            <span className="text-link"> http://xdistromusic.com</span>
          </a>{" "}
          (hereinafter referred to as “Service”).
        </p>
        <p className="my-5">
          Our Privacy Policy governs your visit to
          <a href="http://xdistromusic.com">
            <span className="text-link"> http://xdistromusic.com</span>
          </a>{" "}
          and explains how we collect, safeguard and disclose information that
          results from your use of our Service.
        </p>
        <p className="my-5">
          We use your data to provide and improve Service. By using Service, you
          agree to the collection and use of information in accordance with this
          policy. Unless otherwise defined in this Privacy Policy, the terms
          used in this Privacy Policy have the same meanings as in our Terms and
          Conditions.
        </p>
        Our Terms and Conditions (“Terms”) govern all use of our Service and
        together with the Privacy Policy constitute your agreement with us
        (“agreement”).
      </div>
    ),
  },
  {
    id: "2",
    header: "2. Definitions",
    body: (
      <div>
        <span className="font-semibold">SERVICE</span> means the
        <a href="https://xdistromusic.com">
          <span className="text-link"> http://xdistromusic.com</span>
        </a>{" "}
        website operated by XDistro Music.
        <p className="my-5">
          <span className="font-semibold">PERSONAL DATA</span> means data about
          a living individual who can be identified from that data (or from
          those and other information either in our possession or likely to come
          into our possession).
        </p>
        <p className="my-5">
          <span className="font-semibold ">USAGE DATA</span> is data collected
          automatically either generated by the use of Service or from Service
          infrastructure itself (for example, the duration of a page visit).
        </p>
        <p className="my-5">
          <span className="font-semibold ">COOKIES</span> are small files stored
          on your device (computer or mobile device).
        </p>
        <p className="my-5">
          <span className="font-semibold">DATA CONTROLLER</span> means a natural
          or legal person who (either alone or jointly or in common with other
          persons) determines the purposes for which and the manner in which any
          personal data are, or are to be, processed. For the purpose of this
          Privacy Policy, we Control your data.
        </p>
        <p className="my-5">
          <span className="font-semibold">
            DATA PROCESSORS (OR SERVICE PROVIDERS)
          </span>{" "}
          mean any natural or legal person who processes the data on behalf of
          the Data Controller. We may use the services of various Service
          Providers in order to process your data more effectively.
        </p>
        <p className="my-5">
          <span className="font-semibold">DATA SUBJECT</span> is any living
          individual who is the subject of Personal Data.
        </p>
        <p className="my-5">
          <span className="font-semibold">THE USER</span> is the individual
          using our Service. The User corresponds to the Data Subject, who is
          the subject of Personal Data.
        </p>
      </div>
    ),
  },
  {
    id: "3",
    header: "3. Information Collection and Use",
    body: (
      <div>
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
      </div>
    ),
  },
  {
    id: "4",
    header: "4. Types of Data Collected",
    body: (
      <div>
        <span className="font-semibold">a. Personal Data:</span> While using our
        Service, we may ask you to provide us with certain personally
        identifiable information that can be used to contact or identify you
        (“Personal Data”). Personally identifiable information may include, but
        is not limited to:
        <p className="my-5">
          <ol className="list-[lower-alpha] px-[14px]">
            <li>Email address</li>
            <li className="my-3">First name and last name</li>
            <li className="my-3">Phone number</li>
            <li className="my-3">
              Address, State, Province, ZIP/Postal code, City
            </li>
            <li className="my-3">Cookies and Usage Data</li>
          </ol>
        </p>
        <p className="my-5">
          We may use your Personal Data to contact you with newsletters,
          marketing or promotional materials and other information that may be
          of interest to you. You may opt out of receiving any, or all, of these
          communications from us by following the unsubscribe link or by
          emailing at support@xdistromusic.com
        </p>
        <p className="my-5">
          <span className="font-semibold">b.Usage Data:</span> We may also
          collect information that your browser sends whenever you visit our
          Service or when you access Service by or through a mobile device
          (“Usage Data”). This Usage Data may include information such as your
          computer's Internet Protocol address (e.g. IP address), browser type,
          browser version, the pages of our Service that you visit, the time and
          date of your visit, the time spent on those pages, unique device
          identifiers and other diagnostic data. When you access Service with a
          mobile device, this Usage Data may include information such as the
          type of mobile device you use, your mobile device unique ID, the IP
          address of your mobile device, your mobile operating system, the type
          of mobile Internet browser you use, unique device identifiers and
          other diagnostic data.
        </p>
        <p className="my-5">
          <span className="font-semibold">c. Location Data:</span> We may use
          and store information about your location if you give us permission to
          do so (“Location Data”). We use this data to provide features of our
          Service, to improve and customize our Service. You can enable or
          disable location services when you use our Service at any time by way
          of your device settings.
        </p>
        <p className="my-5">
          <span className="font-semibold">d. Tracking Cookies Data:</span> We
          use cookies and similar tracking technologies to track the activity on
          our Service and we hold certain information.
        </p>
        <p className="my-5">
          Cookies are files with a small amount of data, which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Other tracking technologies are
          also used such as beacons, tags and scripts to collect and track
          information and to improve and analyze our Service. You can instruct
          your browser to refuse all cookies or to indicate when a cookie is
          being sent. However, if you do not accept cookies, you may not be able
          to use some portions of our Service. Examples of Cookies we use:
        </p>
        <p className="my-5">
          <ol className="list-[lower-alpha] px-[14px]">
            <li>
              Session Cookies: We use Session Cookies to operate our Service.
            </li>
            <li className="my-3">
              Preference Cookies: We use Preference Cookies to remember your
              preferences and various settings.
            </li>
            <li className="my-3">
              Security Cookies: We use Security Cookies for security purposes.
            </li>
            <li className="my-3">
              Advertising Cookies: Advertising Cookies are used to serve you
              with advertisements that may be relevant to you and your
              interests.
            </li>
          </ol>
        </p>
        <p className="my-5">
          <span className="font-semibold">e. Other Data:</span> We may also
          collect the following information: sex, age, date of birth, place of
          birth, passport details, citizenship, registration at place of
          residence and actual address, telephone number (work, mobile), details
          of documents on education, qualification, professional training,
          employment agreements, non disclosure agreements, information on
          bonuses and compensation, information on marital status, family
          members, social security (or other taxpayer identification) number,
          office location and other data.
        </p>
      </div>
    ),
  },
  {
    id: "5",
    header: "5. Use of Data",
    body: (
      <div>
        We use the collected data for various purposes:
        <p className="my-5">
          <ol className="list-[lower-alpha] px-[18px]">
            <li>to provide and maintain our Service;</li>
            <li className="my-3">
              to notify you about changes to our Service;
            </li>
            <li className="my-3">
              to allow you to participate in interactive features of our Service
              when you choose to do so;
            </li>
            <li className="my-3">to provide customer support;</li>
            <li className="my-3">
              to gather analysis or valuable information so that we can improve
              our Service;
            </li>
            <li className="my-3">to monitor the usage of our Service;</li>
            <li className="my-3">
              to detect, prevent and address technical issues;
            </li>
            <li className="my-3">
              to fulfill any other purpose for which you provide it;
            </li>
            <li className="my-3">
              to carry out our obligations and enforce our rights arising from
              any contracts entered into between you and us, including for
              billing and collection;
            </li>
            <li className="my-3">
              to provide you with notices about your account and/or
              subscription, including expiration and renewal notices,
              email-instructions, etc.;
            </li>
            <li className="my-3">
              to provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information;
            </li>
            <li className="my-3">
              in any other way we may describe when you provide the information;
            </li>
            <li className="my-3">for any other purpose with your consent..</li>
          </ol>
        </p>
      </div>
    ),
  },
  {
    id: "6",
    header: "6. Retention of Data",
    body: (
      <div>
        We will retain your Personal Data only for as long as is necessary for
        the purposes set out in this Privacy Policy. We will retain and use your
        Personal Data to the extent necessary to comply with our legal
        obligations (for example, if we are required to retain your data to
        comply with applicable laws), resolve disputes, and enforce our legal
        agreements and policies. We will also retain Usage Data for internal
        analysis purposes. Usage Data is generally retained for a shorter
        period, except when this data is used to strengthen the security or to
        improve the functionality of our Service, or we are legally obligated to
        retain this data for longer time periods.
      </div>
    ),
  },
  {
    id: "7",
    header: "7. Transfer of Data",
    body: (
      <div>
        Your information, including Personal Data, may be transferred to – and
        maintained on – computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ from those of your jurisdiction. If you are located
        outside England and choose to provide information to us, please note
        that we transfer the data, including Personal Data, to England and
        process it there. Your consent to this Privacy Policy followed by your
        submission of such information represents your agreement to that
        transfer. We will take all the steps reasonably necessary to ensure that
        your data is treated securely and in accordance with this Privacy Policy
        and no transfer of your Personal Data will take place to an organization
        or a country unless there are adequate controls in place including the
        security of your data and other personal information.
      </div>
    ),
  },
  {
    id: "8",
    header: "8. Disclosure of Data",
    body: (
      <div>
        We may disclose personal information that we collect, or you provide:
        <p className="my-5">
          <ol className="list-[lower-alpha] px-[14px]">
            <li>
              Disclosure for Law Enforcement. Under certain circumstances, we
              may be required to disclose your Personal Data if required to do
              so by law or in response to valid requests by public authorities.
            </li>
            <li className="my-3">
              Business Transaction. If we, or our subsidiaries are involved in a
              merger, acquisition or asset sale, your Personal Data may be
              transferred.
            </li>
            <li className="my-3">
              Other cases. We may disclose your information also:
            </li>
            <ol className="list-[lower-roman] px-[14px]">
              <li className="my-3">to our subsidiaries and affiliates;</li>
              <li className="my-3">
                to contractors, service providers, and other third parties we
                use to support our business;
              </li>
              <li className="my-3">
                to fulfill the purpose for which you provide it;
              </li>
              <li className="my-3">
                for the purpose of including your company’s logo on our website;
              </li>
              <li className="my-3">
                for any other purpose disclosed by us when you provide the
                information;
              </li>
              <li className="my-3">with your consent in any other cases;</li>
              <li className="my-3">
                if we believe disclosure is necessary or appropriate to protect
                the rights, property, or safety of the Company, our customers,
                or others.
              </li>
            </ol>
          </ol>
        </p>
      </div>
    ),
  },
  {
    id: "9",
    header: "9. Security of Data",
    body: (
      <div>
        The security of your data is important to us but remember that no method
        of transmission over the Internet or method of electronic storage is
        100% secure. While we strive to use commercially acceptable means to
        protect your Personal Data, we cannot guarantee its absolute security
      </div>
    ),
  },
  {
    id: "10",
    header:
      "10. Your Data Protection Rights Under General Data Protection Regulation (GDPR)",
    body: (
      <div>
        If you are a resident of the European Union (EU) and European Economic
        Area (EEA), you have certain data protection rights, covered by GDPR. –
        See more at{" "}
        <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">
          <span className="text-link">
            https://eur-lex.europa.eu/eli/reg/2016/679/oj
          </span>
        </a>
        <p className="my-5">
          We aim to take reasonable steps to allow you to correct, amend,
          delete, or limit the use of your Personal Data. If you wish to be
          informed what Personal Data we hold about you and if you want it to be
          removed from our systems, please email us at support@xdistromusic.com.
          In certain circumstances, you have the following data protection
          rights :
        </p>
        <p className="my-5">
          <ol className="list-[lower-alpha] px-[14px]">
            <li className="my-3">
              the right of rectification. You have the right to have your
              information rectified if that information is inaccurate or
              incomplete;
            </li>
            <li className="my-3">
              the right to access, update or to delete the information we have
              on you;
            </li>
            <li className="my-3">
              the right to object. You have the right to object to our
              processing of your Personal Data;
            </li>
            <li className="my-3">
              the right of restriction. You have the right to request that we
              restrict the processing of your personal information;
            </li>
            <li className="my-3">
              the right to data portability. You have the right to be provided
              with a copy of your Personal Data in a structured,
              machine-readable and commonly used format;
            </li>
            <li className="my-3">
              the right to withdraw consent. You also have the right to withdraw
              your consent at any time where we rely on your consent to process
              your personal information;
              <p className="my-2">
                Please note that we may ask you to verify your identity before
                responding to such requests. Please note we may not be able to
                provide Service without some necessary data. You have the right
                to complain to a Data Protection Authority about our collection
                and use of your Personal Data. For more information, please
                contact your local data protection authority in the European
                Economic Area (EEA).
              </p>
            </li>
          </ol>
        </p>
      </div>
    ),
  },
  {
    id: "11",
    header: "11. Service Providers",
    body: (
      <div>
        We may employ third party companies and individuals to facilitate our
        Service (“Service Providers”), provide Service on our behalf, perform
        Service-related services or assist us in analyzing how our Service is
        used. These third parties have access to your Personal Data only to
        perform these tasks on our behalf and are obligated not to disclose or
        use it for any other purpose.
      </div>
    ),
  },
  {
    id: "12",
    header: "12. Analytics",
    body: (
      <div>
        We may use third-party Service Providers to monitor and analyze the use
        of our Service.
        <p className="my-5">
          <div className="my-5 font-semibold">Google Analytics:</div>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our Service. This data is shared with
          other Google services. Google may use the collected data to
          contextualize and personalize the ads of its own advertising network.
          <p className="my-2">
            For more information on the privacy practices of Google, please
            visit the Google Privacy Terms web page:
            <p>
              <a href="https://policies.google.com/privacy?hl=en">
                <span className="text-link">
                  https://policies.google.com/privacy?hl=en
                </span>
              </a>
            </p>
            We also encourage you to review Google's policy for safeguarding
            your data:
            <p>
              <a href="https://support.google.com/analytics/answer/6004245?hl=en">
                <span className="text-link">
                  https://support.google.com/analytics/answer/6004245?hl=en
                </span>
              </a>
            </p>
          </p>
        </p>
        <p className="my-5">
          <div className="my-5 font-semibold">Firebase:</div>
          Firebase is an analytics service provided by Google Inc.
          <p>
            You may opt-out of certain Firebase features through your mobile
            device settings, such as your device advertising Settings or by
            following the instructions provided by Google in their Privacy
            Policy:
            <a href="https://policies.google.com/privacy?hl=en">
              <span className="text-link">
                https://policies.google.com/privacy?hl=en
              </span>
            </a>
          </p>
          <p>
            For more information on what type of information Firebase collects,
            please visit the Google Privacy Terms web page:
            <p></p>
            <a href="https://policies.google.com/privacy?hl=en">
              <span className="text-link">
                https://policies.google.com/privacy?hl=en
              </span>
            </a>
          </p>
        </p>
      </div>
    ),
  },
  {
    id: "13",
    header: "13. CI/CD tools",
    body: (
      <div>
        We may use third-party Service Providers to automate the development
        process of our Service.
      </div>
    ),
  },
  {
    id: "14",
    header: "14. Advertising",
    body: (
      <div>
        We may use third-party Service Providers to show advertisements to you
        to help support and maintain our Service.
        <p className="my-5">
          <div className="my-5">Google AdSense & DoubleClick Cookie:</div>

          <p>
            Google as a third party vendor uses cookies to serve ads on our
            Service. Google's use of the DoubleClick cookie enables it and its
            partners to serve ads to our users based on their visit to our
            Service or other websites on the Internet.You may opt out of the use
            of the DoubleClick Cookie for interest-based advertising by visiting
            the Google Ads Settings web page:
            <a href="http://www.google.com/ads/preferences/">
              <span className="text-link">
                http://www.google.com/ads/preferences/
              </span>
            </a>
          </p>
        </p>
        <p className="my-5">
          <div className="my-5">Bing Ads:</div>

          <p>
            Bing Ads is an advertising service provided by Microsoft Inc. You
            can opt-out from Bing Ads by following the instructions on Bing Ads
            Opt-out page:
            <p>
              <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
                <span className="text-link">
                  https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                </span>
              </a>
            </p>
            for more information about Bing Ads, please visit their Privacy
            Policy:
            <p>
              <a href="https://privacy.microsoft.com/en-us/PrivacyStatement">
                <span className="text-link">
                  https://privacy.microsoft.com/en-us/PrivacyStatement
                </span>
              </a>
            </p>
          </p>
        </p>
        <p className="my-5">
          <div className="my-5">AdMob by Google:</div>

          <p>
            Google Inc. provides AdMob by Google. You can opt-out from the AdMob
            by Google service by following the instructions described by Google:
            <p>
              <a href="https://support.google.com/ads/answer/2662922?hl=en">
                <span className="text-link">
                  https://support.google.com/ads/answer/2662922?hl=en
                </span>
              </a>
            </p>
            For more information on how Google uses the collected information,
            please visit the “How Google uses data when you use our partners'
            sites or app” page:
            <p>
              <a href=" http://www.google.com/policies/privacy/partners/">
                <span className="text-link">
                  http://www.google.com/policies/privacy/partners/
                </span>
              </a>
              or visit the Privacy Policy of Google:
              <a href="http://www.google.com/policies/privacy/">
                <span className="text-link">
                  http://www.google.com/policies/privacy/
                </span>
              </a>
            </p>
          </p>
        </p>
        <p className="my-5">
          <div className="my-5">AdButler:</div>
          <p>
            AdButler is an advertising service provided by Sparklit Networks
            Inc. For more information about AdButler, please visit their
            <p>
              Privacy Policy:
              <a href="https://www.sparklit.com/agreements.spark?agreement=privacy">
                <span className="text-link">
                  https://www.sparklit.com/agreements.spark?agreement=privacy
                </span>
              </a>
            </p>
          </p>
        </p>
        <p className="my-5">
          <div className="my-5">Unity Ads:</div>
          <p>
            Unity Ads is provided by Unity Technologies. You can opt-out from
            Unity Ads service by following the instructions as described by
            Unity Technologies on their PrivacyPolicy page:
            <a href="https://unity3d.com/legal/privacy-policy">
              <span className="text-link">
                https://unity3d.com/legal/privacy-policy
              </span>
            </a>
          </p>
          For more information about Unity Technologies, please visit Unity
          Technologies Privacy Policy: https://unity3d.com/legal/privacy-policy
          <p></p>
        </p>
      </div>
    ),
  },
  {
    id: "15",
    header: "15. Behavioral Remarketing",
    body: (
      <div>
        <div>
          We may use remarketing services to advertise on third party websites
          to you after you visited our Service. We, and our third-party vendors
          use cookies to inform, optimize and serve ads based on your past
          visits to our Service.
          <div>
            <p>
              <div className="my-5">Google Ads (AdWords):</div>

              <p className="my-5">
                Google Inc. provides Google Ads remarketing service.
              </p>

              <p className="my-5">
                You can opt-out of Google Analytics for Display Advertising and
                customize the Google Display Network ads by visiting the Google
                Ads Settings page:
                <a href="http://www.google.com/settings/ads">
                  <span className="text-link">
                    http://www.google.com/settings/ads
                  </span>
                </a>
              </p>

              <p className="my-5">
                Google also recommends installing the Google Analytics Opt-out
                Browser Add-on -
                <a href="https://tools.google.com/dlpage/gaoptout for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.">
                  <span className="text-link">
                    https://tools.google.com/dlpage/gaoptout for your web
                    browser. Google Analytics Opt-out Browser Add-on provides
                    visitors with the ability to prevent their data from being
                    collected and used by Google Analytics.
                  </span>
                </a>
              </p>

              <p>
                For more information on the privacy practices of Google, please
                visit the Google Privacy Terms web page:
                <a href="https://policies.google.com/privacy?hl=en">
                  <span className="text-link">
                    https://policies.google.com/privacy?hl=en
                  </span>
                </a>
              </p>
            </p>
          </div>
          <div className="my-5">
            Bing Ads Remarketing:
            <p className="my-3">
              Microsoft Inc. provides Bing Ads remarketing service. Microsoft
              Inc. provides Bing Ads remarketing service. You can opt-out of
              Bing Ads interest-based ads by following their instructions:
              <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
                <span className="text-link">
                  https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                </span>
              </a>
              <p>
                You can learn more about the privacy practices and policies of
                Microsoft by visiting their Privacy Policy page:
                <a href="https://privacy.microsoft.com/en-us/PrivacyStatement">
                  <span className="text-link">
                    https://privacy.microsoft.com/en-us/PrivacyStatement
                  </span>
                </a>
              </p>
            </p>
          </div>
          <div className="my-5">
            Twitter:
            <p className="my-3">
              Twitter Inc. provides twitter remarketing service.
              <p>
                You can opt-out from Twitter's interest-based ads by following
                their instructions:{" "}
                <a href="https://support.twitter.com/articles/20170405">
                  <span className="text-link">
                    https://support.twitter.com/articles/20170405
                  </span>
                </a>
              </p>
              <p>
                You can learn more about the privacy practices and policies of
                Twitter by visiting their Privacy Policy page:{" "}
                <a href="https://twitter.com/privacy">
                  <span className="text-link">https://twitter.com/privacy</span>
                </a>
              </p>
            </p>
          </div>
          <div className="my-5">
            Facebook:
            <p className="my-3">
              Facebook Inc. provides Facebook remarketing service. You can learn
              more about interest-based advertising from Facebook by visiting
              this page:{" "}
              <a href="https://www.facebook.com/help/164968693837950">
                <span className="text-link">
                  https://www.facebook.com/help/164968693837950
                </span>
              </a>
            </p>
            <p className="my-5">
              To opt-out from Facebook's interest-based ads, follow these
              instructions from Facebook:{" "}
              <a
                href="https://www.facebook.com/help/568137493302217"
                className="text-link">
                https://www.facebook.com/help/568137493302217
              </a>
            </p>
            <p>
              Facebook adheres to the Self-Regulatory Principles for Online
              Behavioral Advertising established by the Digital Advertising
              Alliance.
              <p>
                You can also opt-out from Facebook and other participating
                companies through the Digital Advertising Alliance in the USA{" "}
                <a
                  href="http://www.aboutads.info/choices/"
                  className="text-link">
                  http://www.aboutads.info/choices/
                </a>
                , The Digital Advertising Alliance of Canada in Canada{" "}
                <a href="http://youradchoices.ca/" className="text-link">
                  http://youradchoices.ca/
                </a>{" "}
                Or the European Interactive Digital Advertising Alliance in
                Europe{" "}
                <a
                  href="http://www.youronlinechoices.eu/"
                  className="text-link">
                  http://www.youronlinechoices.eu/
                </a>
                , or opt-out using your mobile device settings.
              </p>
              <p>
                For more information on the privacy practices of Facebook,
                please visit Facebook's Data Policy:{" "}
                <a
                  href="https://www.facebook.com/privacy/explanation"
                  className="text-link">
                  https://www.facebook.com/privacy/explanation
                </a>
              </p>
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: "16",
    header: "16. Payments",
    body: (
      <div>
        We may provide paid products and/or services within Service. In that
        case, we use third-party services for payment processing (e.g. payment
        processors). <br />
        We will not store or collect your payment card details. That information
        is provided directly to our third-party payment processors whose use of
        your personal information is governed by their Privacy Policy. <br />
        These payment processors adhere to the standards set by PCI-DSS as
        managed by the PCI Security Standards Council, which is a joint effort
        of brands like Visa, MasterCard, American Express and Discover. PCI-DSS
        requirements help ensure the secure handling of payment information.
      </div>
    ),
  },
  {
    id: "17",
    header: "17. Links To Other Sites",
    body: (
      <div>
        Our Service may contain links to other sites that are not operated by
        us. If you click a third party link, you will be directed to that third
        party's site. We strongly advise you to review the Privacy Policy of
        every site you visit. <br />
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </div>
    ),
  },
  {
    id: "18",
    header: "18. Children",
    body: (
      <div>
        Children (“Child” or “Children”) under the age of 18 are not allowed to
        use this service. <br />
        We do not knowingly collect personally identifiable information from
        Children under 18. If you become aware that a Child has provided us with
        Personal Data, please contact us. If we become aware that we have
        collected Personal Data from Children without verification of parental
        consent, we take steps to remove that information from our servers.
      </div>
    ),
  },
  {
    id: "19",
    header: "19. Changes To This Privacy Policy",
    body: (
      <div>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. <br />
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update “effective date” at
        the top of this Privacy Policy. <br />
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </div>
    ),
  },
];

export default data;
