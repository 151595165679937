import data from "../../data/TermsOfUseData";
import TermsOfUse from "./TermsofUse";
import Navbar from "../NavBar/Nav";
import Footer from "../Footer/Footer";

function Terms() {
  return (
    <div>
      <Navbar background={true} />
      <div className=" flex mx-5 md:mx-auto max-w-[750px]">
        <div className="mt-20">
          <div className="font-semibold text-[28px] my-10">
            Terms & Conditions
          </div>
          {data.map((d, i) => {
            return (
              <div key={i} className="w-full">
                <TermsOfUse header={d.header} body={d.body} />
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Terms;

