import { useState } from "react";
import Button from "../Button/Button";
import BankTransferIcon from "../Icons/BankTransferIcon";
import PayPalcon from "../Icons/PayPalcon";
import Modal from "../modal";
import { useLocation } from "react-router-dom";
import { getQueriesMap } from "../../utils/queries";
import PaypalPayment from "./PaypalPayment";
import BankTransferPayment from "./BankTransferPayment";
import { useDashboardContext } from "../Dashboard/DashboardContext";
import { toast } from "react-toastify";
import IUser from "../../interfaces/user";

function Payment() {
  const { user } = useDashboardContext();
  return (
    <div className="bg-white rounded-[10px]  w-full h-full">
      <div className="p-5">
        <div>Choose a Payment Method</div>
        <div className="md:flex my-5 gap-5 items-center w-full">
          <div className="md:basis-1/2 md:mt-0 mt-10  ">
            <PayPalPayment user={user} />
          </div>
          <div className="md:basis-1/2 md:mt-0 mt-10  ">
            <BankAccountPayment user={user} />
          </div>
        </div>
      </div>
    </div>
  );
}

function PayPalPayment({ user }: { user: IUser }) {
  const location = useLocation();
  const paypalmodalKey = "paypalwithdraw";
  const queries = getQueriesMap(location);
  const [openPaypalModal, setOpenPaypalModal] = useState(
    queries[paypalmodalKey] === "true"
  );

  function showPaypalPaymentModal() {
    setOpenPaypalModal(true);
  }

  function closePaypalPaymentModal() {
    setOpenPaypalModal(false);
  }

  function withdraw() {
    if (user.pay_pal_email) {
      if (user.balance < 1) {
        toast.info("you don't have any money to withdraw yet");
        return;
      }
      // send withdrawal request
    } else {
      showPaypalPaymentModal();
    }
  }

  return (
    <div className="bg-white py-8 px-3 shadow-md rounded-[10px] flex-shrink-0 items-center text-center space-y-6">
      <div className="flex justify-center items-center my-6">
        <div className="w-[80px] h-[20px]">
          <PayPalcon />
        </div>
      </div>
      <div className="text-sm font-medium">PayPal</div>
      {user.pay_pal_email ? "" : <Modal
        openModal={showPaypalPaymentModal}
        closeModal={closePaypalPaymentModal}
        open={openPaypalModal}
        closeIconColor="black">
        <PaypalPayment />
      </Modal>}
      <Button
        className="mx-auto"
        onClick={withdraw}>
        {user.pay_pal_email ? "Withdraw Now" : "Add PayPal account"}
      </Button>
    </div>
  )
}

function BankAccountPayment({ user }: { user: IUser }) {
  const location = useLocation();
  const queries = getQueriesMap(location);
  const bankPaymentModalKey = "bankwithdraw";
  const [openBankPaymentModal, setOpenBankPaymentModal] = useState(
    queries[bankPaymentModalKey] === "true"
  );

  function showBankPaymentModal() {
    setOpenBankPaymentModal(true);
  }

  function closeBankPaymentModal() {
    setOpenBankPaymentModal(false);
  }

  function withdraw() {
    if (user.bank_account) {
      if (user.balance < 1) {
        toast.info("you don't have any money to withdraw");
        return;
      }
      // send withdrawal request 
    } else {
      showBankPaymentModal()
    }
  }

  return (
    <div className="bg-white py-8 px-3 shadow-md rounded-[10px] flex-shrink-0 items-center text-center space-y-6">
      <div className="flex justify-center">
        <div className="w-[50px] h-[50px]">
          <BankTransferIcon />
        </div>
      </div>
      <div className="text-sm font-medium ">Bank Transfer</div>
      {user.bank_account ? "" : <Modal
        openModal={showBankPaymentModal}
        closeModal={closeBankPaymentModal}
        open={openBankPaymentModal}
        closeIconColor="black">
        <BankTransferPayment />
      </Modal>}
      <Button
        className="mx-auto"
        onClick={withdraw}>
        {user.bank_account ? "Withdraw Now" : "Add Bank Account"}
      </Button>
    </div>
  )
}

export default Payment;
