import React from "react";

interface Props {
  color?: string;
  hover?: string;
  children?: React.ReactNode;
  className?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean;
  textColor?: string;
  paddingX?: string;
  paddingY?: string;
  icon?: React.ReactNode;
}

const UpgradeButton: React.FC<Props> = ({
  color, hover, children,
  className, onClick, disabled,
  paddingX, paddingY, icon, textColor }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${color || "bg-xd_yellow"} ${hover || "hover:text-xd_yellow hover:bg-black"
        } ${paddingX || "px-[30px]"} ${paddingY || "py-[10px]"} font-sans ${textColor || "text-xd_black"} rounded ${className}`}>
      {icon}
      <div>
        {children}
      </div>
    </button>
  );
};

export default UpgradeButton;
