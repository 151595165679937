import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

interface Props {
  children?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  to?: string;
  onClick?: React.MouseEventHandler;
}

const MenuItem: React.FC<Props> = ({ children, icon, to, onClick }) => {
  const location = useLocation();
  if (!to && !onClick) {
    return null;
  }
  return (
    <Link
      onClick={onClick}
      to={to||"#"}
      className={`${
        to && location.pathname.startsWith(to) ? "bg-black text-white px-4" : "hover:bg-black hover:text-white hover:transition-all hover:px-4 hover:ease-in-out duration-500"
      } font-sans w-full py-4 px-2 font-medium text-[14px] rounded-[10px] flex items-center gap-2`}
    >
      {icon}
      {children}
    </Link>
  );
};

export default MenuItem;
