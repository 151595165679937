import LayoutIcon from "../Icons/LayoutIcon";
import MenuIcon from "../Icons/MenuIcon";
import Xmark from "../Icons/Xmark";
import SearchInput from "../SearchInput/SearchInput";
//import Select from "../Select/Select";

interface Props {
  toggleview: boolean;
  changeView: React.MouseEventHandler<HTMLDivElement>;
}

function ReleaseListHeader({ toggleview, changeView }: Props) {
  return (
    <div>
      <div className="md:flex items-center justify-between py-3 md:px-12 md:py-6">
        <strong className="font-semibold text-xl">Releases</strong>
        <div className="flex md:flex-row flex-col-reverse gap-3 md:items-center">
          <div className="flex justify-between md:gap-7">
            {/*<Select options={["Release"]} />*/}
            <SearchInput
              placeholder={"Input your keywords"}
              icon={<Xmark strokeColor="#4F4F4F" />}
            />
          </div>
          <div className="flex justify-end gap-7">
            {/*<div className="bg-transparent hover:bg-transparent p-0" >
              <MiniSettingsIcon />
            </div>*/}
            <div
              className="bg-transparent hover:bg-transparent p-0 cursor-pointer"
              onClick={changeView}
            >
              {toggleview ? <MenuIcon /> : <LayoutIcon />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseListHeader;
