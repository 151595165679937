import { useEffect, useState } from 'react'
import { Switch } from '@headlessui/react'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

export default function XDSwitch({checked, onChange}: Props) {
  const [enabled, setEnabled] = useState(false)
  useEffect(() => {
    setEnabled(checked || false)
  }, [checked])

  return (
    <Switch
      checked={enabled}
      onChange={(checked: boolean) => {
        setEnabled(checked);
        onChange && onChange(checked);
      }}
      className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
    >
      <span className="sr-only">Use setting</span>
      <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md" />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'bg-xd_yellow' : 'bg-gray-200',
          'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
        )}
      />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
        )}
      />
    </Switch>
  )
}
