import data from "../../data/DistributionAgreement";
import Footer from "../Footer/Footer";
import Navbar from "../NavBar/Nav";
import DistributionAgreementComponent from "./DistributionAgreementComponent";

function DistributionAgreementPage() {
  return (
    <div>
      <Navbar background={true} />
      <div className=" flex mx-5 md:mx-auto max-w-[750px]">
        <div className="mt-20">
          <div className="font-semibold text-[28px] my-10">
            Distribution Agreement
          </div>
          {data.map((d, i) => {
            return (
              <div key={i} className="w-full">
                <DistributionAgreementComponent header={d.header} body={d.body} />
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default DistributionAgreementPage;

