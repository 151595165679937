import React from "react";
import TrackImg from "../../assets/TrackImg.png";

interface Props {
  image?: string;
  track: string;
}

function Splitrelease({ track, image }: Props) {
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 w-[50px] h-[50px]">
        <img className="w-full h-full rounded " src={image} alt="" />
      </div>
      <div className="ml-3">
        <p className="text-gray-900 whitespace-no-wrap">
          <span>{track}</span>
        </p>
      </div>
    </div>
  );
}

export default Splitrelease;
