/* eslint-disable import/no-anonymous-default-export */
interface Props {
  strokeColor?: string;
}
export default ({ strokeColor }: Props) => {
  return (
    <svg
      style={{ width: "100%", height: "100%" }}
      viewBox="0 0 81 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3245_12768)">
        <path
          d="M7.88675 39.0666V14.7593C7.88675 14.7593 2.39131 27.2001 7.88675 39.0666Z"
          fill="white"
        />
        <path
          d="M2.57099 35.8826V17.9507C2.57099 17.9507 -1.48449 27.125 2.57099 35.8826Z"
          fill="white"
        />
        <path
          d="M73.4902 14.7656V39.0729C73.4902 39.0729 78.9859 26.6336 73.4902 14.7656Z"
          fill="white"
        />
        <path
          d="M78.8076 17.9507V35.8826C78.8076 35.8826 82.8621 26.707 78.8076 17.9507Z"
          fill="white"
        />
        <path
          d="M59.8166 8.41255C59.6133 8.20068 59.4058 7.99316 59.194 7.78706C54.2344 2.97739 47.5971 0.287598 40.6884 0.287598C33.7797 0.287598 27.1425 2.97739 22.1829 7.78706C21.9711 7.99316 21.7565 8.20068 21.5603 8.41255C16.751 13.372 14.0615 20.009 14.0615 26.9174C14.0615 33.8257 16.751 40.4626 21.5603 45.4222C21.765 45.6344 21.973 45.8419 22.1843 46.0447C27.1437 50.8532 33.78 53.5421 40.6877 53.5421C47.5954 53.5421 54.2318 50.8532 59.1911 46.0447C59.4043 45.8415 59.6176 45.6339 59.8166 45.4222C64.6258 40.4626 67.3154 33.8257 67.3154 26.9174C67.3154 20.009 64.6258 13.372 59.8166 8.41255ZM48.3253 35.1789C46.2487 37.1052 43.5209 38.1756 40.6884 38.1756C37.856 38.1756 35.1282 37.1052 33.0516 35.1789C32.834 34.9814 32.6251 34.7724 32.4261 34.5564C30.4986 32.4795 29.4275 29.7508 29.4275 26.9174C29.4275 24.0839 30.4986 21.3552 32.4261 19.2783C32.6236 19.0622 32.8312 18.8518 33.0487 18.6543C35.1264 16.728 37.8551 15.6577 40.6884 15.6577C43.5217 15.6577 46.2504 16.728 48.3282 18.6543C48.5456 18.8533 48.7546 19.0622 48.9508 19.2783C50.8782 21.3552 51.9493 24.0839 51.9493 26.9174C51.9493 29.7508 50.8782 32.4795 48.9508 34.5564C48.7518 34.7724 48.5428 34.9814 48.3253 35.1789Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3245_12768">
          <rect
            width="79.9434"
            height="53.373"
            fill="white"
            transform="translate(0.702148 0.225586)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
