import React from 'react'

interface Props {
    UpcCode: string;
}

function Upc({UpcCode}: Props) {
  return (
    <p className="text-gray-900 whitespace-no-wrap">{UpcCode}</p>
  )
}

export default Upc