import React from 'react'
import StatsImg from '../../assets/StatsImg.png'

function EarningStats() {
    return (
        <div className='w-full '>
            <div className='bg-white text-center items-center rounded-[10px] h-[375px] flex justify-center'>
                <div>
                    <div className='flex justify-center'><img src={StatsImg} alt="" /></div>
                    <div className='mt-10 text-xd_grey'>Current Available Earnings</div>
                    <div className="font-semibold text-[50px]">$0.00</div>
                </div>
            </div>
        </div>
    )
}

export default EarningStats
