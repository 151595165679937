export default function AuthLogo() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1033 877"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3095_21132)">
        <path
          d="M73.3141 782.046L91.9761 813.571H92.6918L111.445 782.046H133.594L105.353 828.288L134.218 874.548H111.666L92.6918 842.968H91.9761L73.0388 874.548H50.5967L79.5531 828.288L51.1472 782.046H73.3141Z"
          fill="white"
        />
        <path
          d="M171.03 874.556H138.238V782.053H171.268C179.618 781.819 187.891 783.714 195.307 787.558C201.95 791.175 207.352 796.705 210.813 803.431C214.395 811.198 216.251 819.65 216.251 828.204C216.251 836.757 214.395 845.209 210.813 852.976C207.354 859.746 201.921 865.307 195.233 868.922C187.775 872.826 179.445 874.765 171.03 874.556ZM157.781 857.802H170.296C175.367 857.966 180.404 856.902 184.976 854.701C188.962 852.607 192.115 849.215 193.912 845.086C196.104 839.736 197.13 833.981 196.922 828.204C197.126 822.468 196.1 816.756 193.912 811.45C192.115 807.336 188.969 803.958 184.994 801.871C180.413 799.682 175.37 798.63 170.296 798.807H157.818L157.781 857.802Z"
          fill="white"
        />
        <path
          d="M242.668 782.046V874.548H223.107V782.046H242.668Z"
          fill="white"
        />
        <path
          d="M301.327 808.656C301.221 806.987 300.75 805.361 299.948 803.893C299.146 802.425 298.032 801.151 296.685 800.16C293.386 797.982 289.475 796.921 285.528 797.132C282.769 797.054 280.021 797.509 277.435 798.472C275.448 799.199 273.705 800.471 272.407 802.142C271.214 803.665 270.568 805.546 270.572 807.482C270.469 809.001 270.841 810.514 271.637 811.812C272.443 813.096 273.528 814.181 274.811 814.987C276.28 815.942 277.857 816.718 279.509 817.299C281.369 817.965 283.269 818.517 285.197 818.95L293.51 820.932C297.33 821.781 301.055 823.01 304.63 824.602C307.816 825.971 310.769 827.828 313.383 830.107C315.822 832.266 317.785 834.91 319.145 837.869C320.569 841.1 321.289 844.597 321.255 848.127C321.35 853.348 319.838 858.473 316.925 862.807C313.854 867.137 309.588 870.479 304.648 872.422C298.539 874.845 292.006 876.018 285.436 875.872C278.777 876.022 272.155 874.856 265.948 872.441C260.753 870.392 256.283 866.847 253.103 862.257C249.861 857.283 248.169 851.458 248.24 845.521H267.159C267.269 848.313 268.208 851.009 269.857 853.265C271.504 855.381 273.709 856.995 276.224 857.926C279.118 859 282.185 859.528 285.271 859.486C288.223 859.552 291.162 859.06 293.932 858.036C296.154 857.221 298.123 855.835 299.639 854.017C300.984 852.337 301.704 850.242 301.676 848.09C301.737 846.179 301.083 844.314 299.841 842.861C298.373 841.244 296.552 839.989 294.519 839.191C291.739 838.06 288.874 837.151 285.95 836.475L275.876 833.942C269.084 832.549 262.743 829.498 257.415 825.061C255.155 823.01 253.378 820.483 252.213 817.662C251.049 814.841 250.525 811.796 250.681 808.748C250.54 803.495 252.119 798.341 255.177 794.068C258.384 789.743 262.693 786.359 267.655 784.269C273.365 781.838 279.524 780.638 285.73 780.746C291.914 780.609 298.054 781.811 303.731 784.269C308.555 786.364 312.703 789.756 315.714 794.068C318.616 798.365 320.162 803.434 320.154 808.619L301.327 808.656Z"
          fill="white"
        />
        <path
          d="M323.276 798.157V782.046H399.264V798.157H370.931V874.548H351.609V798.157H323.276Z"
          fill="white"
        />
        <path
          d="M404.183 874.557V782.054H440.682C446.857 781.857 452.992 783.114 458.591 785.724C463.341 788.011 467.293 791.675 469.932 796.239C472.626 801.12 473.975 806.629 473.84 812.203C473.999 817.748 472.621 823.229 469.858 828.039C467.151 832.512 463.144 836.052 458.371 838.187C452.641 840.678 446.433 841.881 440.186 841.71H415.744V825.984H437.03C440.2 826.076 443.358 825.559 446.334 824.461C448.628 823.568 450.563 821.943 451.839 819.837C453.137 817.508 453.772 814.868 453.674 812.203C453.764 809.499 453.131 806.819 451.839 804.441C450.574 802.284 448.642 800.596 446.334 799.633C443.362 798.44 440.175 797.878 436.975 797.982H423.781V874.502L404.183 874.557ZM454.151 832.462L477.125 874.557H455.545L433.048 832.462H454.151Z"
          fill="white"
        />
        <path
          d="M565.041 828.287C565.301 837.194 563.333 846.024 559.316 853.977C555.806 860.758 550.416 866.384 543.792 870.181C537.002 873.847 529.406 875.767 521.689 875.767C513.972 875.767 506.377 873.847 499.587 870.181C492.977 866.369 487.601 860.738 484.099 853.959C480.352 845.913 478.41 837.145 478.41 828.269C478.41 819.393 480.352 810.625 484.099 802.579C487.596 795.806 492.974 790.186 499.587 786.394C506.378 782.732 513.973 780.815 521.689 780.815C529.405 780.815 537 782.732 543.792 786.394C550.399 790.155 555.788 795.734 559.316 802.469C563.349 810.462 565.318 819.338 565.041 828.287ZM545.205 828.287C545.363 822.641 544.371 817.022 542.287 811.772C540.63 807.636 537.781 804.085 534.103 801.57C530.372 799.305 526.091 798.108 521.726 798.108C517.361 798.108 513.08 799.305 509.349 801.57C505.665 804.084 502.811 807.635 501.146 811.772C497.264 822.44 497.264 834.135 501.146 844.802C502.81 848.945 505.665 852.502 509.349 855.023C513.08 857.288 517.361 858.486 521.726 858.486C526.091 858.486 530.372 857.288 534.103 855.023C537.782 852.501 540.63 848.944 542.287 844.802C544.372 839.553 545.365 833.933 545.205 828.287Z"
          fill="white"
        />
        <path
          d="M575.026 782.046H588.385L619.874 858.822H620.956L652.39 782.046H665.749V874.548H655.271V804.304H654.372L625.379 874.548H615.25L586.403 804.304H585.504V874.585H575.026V782.046Z"
          fill="white"
        />
        <path
          d="M741.197 782.046H752.391V843.298C752.488 849.232 750.952 855.079 747.95 860.199C744.938 865.194 740.591 869.248 735.399 871.906C729.47 874.709 722.993 876.163 716.434 876.163C709.875 876.163 703.399 874.709 697.469 871.906C692.28 869.251 687.938 865.195 684.936 860.199C681.92 855.084 680.377 849.235 680.477 843.298V782.046H691.689V842.381C691.61 846.584 692.635 850.735 694.662 854.418C696.651 857.934 699.606 860.806 703.176 862.694C707.322 864.664 711.854 865.685 716.443 865.685C721.033 865.685 725.565 864.664 729.71 862.694C733.293 860.822 736.251 857.946 738.225 854.418C740.251 850.735 741.276 846.584 741.197 842.381V782.046Z"
          fill="white"
        />
        <path
          d="M817.758 805.165C817.547 803.003 816.844 800.918 815.702 799.07C814.56 797.222 813.01 795.66 811.17 794.504C806.921 791.864 801.985 790.543 796.986 790.705C793.308 790.621 789.652 791.302 786.251 792.705C783.419 793.863 780.952 795.765 779.113 798.21C777.421 800.497 776.525 803.275 776.562 806.119C776.509 808.374 777.15 810.59 778.397 812.469C779.595 814.241 781.16 815.737 782.985 816.854C784.836 818.007 786.805 818.96 788.857 819.698C790.912 820.432 792.784 821.038 794.509 821.533L803.904 824.066C806.633 824.799 809.322 825.675 811.959 826.69C814.987 827.826 817.855 829.35 820.492 831.222C823.245 833.154 825.549 835.658 827.245 838.562C829.125 841.919 830.052 845.727 829.924 849.572C829.982 854.378 828.623 859.095 826.016 863.133C823.197 867.346 819.247 870.678 814.62 872.748C808.919 875.275 802.724 876.492 796.49 876.308C790.605 876.45 784.756 875.363 779.315 873.115C774.776 871.216 770.838 868.121 767.919 864.161C765.133 860.251 763.511 855.632 763.24 850.838H774.819C774.986 854.043 776.234 857.096 778.361 859.5C780.51 861.819 783.212 863.555 786.214 864.546C789.523 865.668 792.997 866.226 796.49 866.198C800.431 866.259 804.346 865.548 808.014 864.106C811.167 862.878 813.949 860.855 816.088 858.234C818.084 855.723 819.142 852.595 819.079 849.389C819.193 846.659 818.276 843.987 816.51 841.902C814.623 839.853 812.304 838.249 809.721 837.204C806.771 835.94 803.736 834.886 800.638 834.048L789.26 830.782C782.932 829.178 777.059 826.132 772.103 821.882C770.015 819.971 768.367 817.629 767.274 815.018C766.181 812.408 765.669 809.59 765.772 806.762C765.677 801.898 767.157 797.135 769.993 793.183C772.93 789.222 776.861 786.107 781.388 784.154C786.43 781.947 791.886 780.846 797.39 780.925C802.859 780.827 808.286 781.91 813.299 784.099C817.645 786.021 821.433 789.014 824.309 792.797C827.005 796.423 828.511 800.795 828.621 805.312L817.758 805.165Z"
          fill="white"
        />
        <path
          d="M851.851 782.046V874.548H840.657V782.046H851.851Z"
          fill="white"
        />
        <path
          d="M940.999 810.951H929.787C929.211 807.919 928.039 805.031 926.337 802.455C924.738 800.057 922.717 797.968 920.373 796.29C918.02 794.613 915.402 793.342 912.629 792.528C909.759 791.678 906.779 791.251 903.785 791.262C898.413 791.194 893.135 792.665 888.573 795.501C883.852 798.553 880.107 802.897 877.783 808.015C872.449 821.014 872.449 835.589 877.783 848.587C880.105 853.703 883.851 858.041 888.573 861.084C893.13 863.933 898.411 865.411 903.785 865.341C906.779 865.352 909.759 864.925 912.629 864.075C915.403 863.255 918.02 861.977 920.373 860.295C925.28 856.792 928.651 851.536 929.787 845.615H940.999C940.244 850.085 938.683 854.382 936.393 858.295C934.229 861.947 931.411 865.169 928.08 867.8C924.742 870.416 920.973 872.428 916.942 873.745C912.694 875.125 908.251 875.813 903.785 875.782C896.36 875.913 889.054 873.906 882.737 870.002C876.44 865.974 871.409 860.249 868.222 853.487C864.737 845.509 862.937 836.897 862.937 828.191C862.937 819.485 864.737 810.874 868.222 802.896C871.409 796.134 876.44 790.409 882.737 786.381C889.054 782.476 896.36 780.47 903.785 780.6C908.251 780.57 912.694 781.258 916.942 782.637C920.975 783.941 924.746 785.947 928.08 788.564C931.405 791.195 934.222 794.41 936.393 798.051C938.71 802.031 940.271 806.404 940.999 810.951Z"
          fill="white"
        />
        <path
          d="M957.229 787.318V783.929H975.037V787.318H968.138V806.249H964.128V787.318H957.229ZM978.47 783.929H983.418L990.044 800.102H990.306L996.932 783.929H1001.88V806.249H998V790.915H997.793L991.624 806.183H988.725L982.557 790.882H982.35V806.249H978.47V783.929Z"
          fill="white"
        />
        <path
          d="M93.3752 501.158V187.372C93.3752 187.372 22.4339 347.972 93.3752 501.158Z"
          fill="white"
        />
        <path
          d="M24.7464 460.057V228.571C24.7464 228.571 -27.6063 347.002 24.7464 460.057Z"
          fill="white"
        />
        <path
          d="M940.25 187.452V501.238C940.25 501.238 1011.19 340.657 940.25 187.452Z"
          fill="white"
        />
        <path
          d="M1008.9 228.571V460.057C1008.9 460.057 1061.23 341.607 1008.9 228.571Z"
          fill="white"
        />
        <path
          d="M763.74 105.437C761.116 102.703 758.437 100.024 755.703 97.3629C691.679 35.2742 605.997 0.55127 516.812 0.55127C427.627 0.55127 341.945 35.2742 277.922 97.3629C275.188 100.024 272.417 102.703 269.885 105.437C207.801 169.46 173.082 255.137 173.082 344.318C173.082 433.499 207.801 519.177 269.885 583.199C272.527 585.94 275.212 588.619 277.94 591.237C341.961 653.309 427.631 688.022 516.803 688.022C605.975 688.022 691.645 653.309 755.666 591.237C758.418 588.613 761.171 585.934 763.74 583.199C825.823 519.177 860.543 433.499 860.543 344.318C860.543 255.137 825.823 169.46 763.74 105.437ZM615.398 450.969C588.591 475.835 553.377 489.652 516.812 489.652C480.248 489.652 445.034 475.835 418.226 450.969C415.419 448.418 412.721 445.721 410.152 442.932C385.271 416.121 371.443 380.896 371.443 344.318C371.443 307.741 385.271 272.515 410.152 245.705C412.703 242.916 415.382 240.2 418.19 237.649C445.011 212.782 480.237 198.965 516.812 198.965C553.388 198.965 588.613 212.782 615.435 237.649C618.242 240.218 620.94 242.916 623.472 245.705C648.354 272.515 662.181 307.741 662.181 344.318C662.181 380.896 648.354 416.121 623.472 442.932C620.903 445.721 618.205 448.418 615.398 450.969Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3095_21132">
          <rect
            width="1032"
            height="876"
            fill="white"
            transform="translate(0.868164 0.447754)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
