import Header from "../../components/Header/Header";
import InsightChart from "../../components/InsightChart/InsightChart";
import TopPanel from "../../components/InsightPanels/TopPanel";
import InsightTable from "../../components/InsightTable/InsightTable";
import InsightTableHeader from "../../components/InsightTable/InsightTableHeader";
import TerritoryHeader from "../../components/Territory/TerritoryHeader";
import TerritoryTable from "../../components/Territory/TerritoryTable";
import TerritoryMap from "../../components/Territory/TerritoryMap";
import Button from "../../components/Button/Button";

function Insight() {
  return (
    <div>
      <Header children={"Insights"} />
      <div className="my-8">
        <TopPanel />
        <div className="my-7 md:flex flex-row gap-10 h-full">
          <div className="w-full basis-2/3">
            <InsightChart />
          </div>
        </div>
        <div className="bg-white rounded-[10px]">
          <InsightTableHeader />
          <div className="overflow-x-scroll">
            <InsightTable />
            <div className="my-5 flex justify-center">
              <Button color="bg-black" textColor="text-white">Load More</Button>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-[10px] my-7">
          <TerritoryHeader />
          <div>
            <div className="flex overflow-x-scroll gap-5">
              <div className="w-full">
                <TerritoryTable />
              </div>
              <div className="">
                {/* This space is for the Map */}
                <TerritoryMap />
              </div>
            </div>
            <div className="my-5 py-5 flex justify-center">
              <Button color="bg-black" textColor="text-white">View all Territories</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Insight;
