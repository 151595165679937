import React from "react";

interface Option {
  value: string;
  label: string;
}

interface Props {
  options: Option[];
  id?: string;
  bgColor?: string;
  label?: string;
  labelColor?: string;
  labelClassName?: string;
  info?: string;
  infoClass?: string;
  icon?: React.ReactNode;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  error?: string;
  name?: string;
}

const Select: React.FC<Props> = ({
  defaultValue, icon, label, info, infoClass, error, name,
  labelColor, labelClassName, options, id, bgColor, onChange }) => {
  return (
    <div>
      {label ? (
        <label
          htmlFor={id}
          className={`flex font-normal text-xs ${labelColor || "text-xd_grey"
            } my-3 ${labelClassName}`}>
          <div className="flex gap-2 items-center">
            <div>
              <div className={`${info ? "text-start" : ""}`}>{label}</div>
              <div className={`text-start opacity-50 ${infoClass}`}>{info}</div>
            </div>
            <div>{icon}</div>
          </div>
        </label>
      ) : (
        ""
      )}
      <select
        id={id}
        name={name}
        onChange={onChange}
        defaultValue={defaultValue}
        //value={defaultValue}
        className={`h-full inline-block w-full rounded-md border-xd_grey p-4 ` +
          ` text-base focus:border-xd_lightYellow focus:outline-none focus:ring-xd_lightYellow ` +
          ` sm:text-sm ${bgColor ? bgColor : "bg-white"}`}>
        {options.map((option, k) => (
          <option key={k} value={option.value}>{option.label}</option>
        ))}
      </select>

      {error ? (
        <p className="text-red text-xs font-light my-2">{error}</p>
      ) : null}
    </div>
  );
};

export default Select;
