import Earnings from "./Earnings/Earnings";

function Transactions() {
  return (
    <div className="md:bg-white rounded-[10px] p-2 md:p-10">
      <div className="md:flex justify-between items-center">
        <div className="font-medium text-sm">
          You can withdraw all your earnings when you reach the threshold of $50
        </div>
      </div>
      <div className="my-10">
        <Earnings />
      </div>
    </div>
  );
}

export default Transactions;
