import { useState } from "react";
import { Fragment } from "react";
import BarIcon from "../Icons/BarIcon";
import Xmark from "../Icons/Xmark";
import SideBar from "../SideBar/DashMobileSideBar";
import { Dialog, Transition } from "@headlessui/react";

interface Props {
  showSidebar: boolean;
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

const MiniSidebar = ({ showSidebar, setShowSidebar }: Props) => {
  return (
    <div>
      {/* <Transition show={showSidebar} as={Fragment}>
        <Transition
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div
            className="fixed inset-0 bg-black_grove bg-opacity-80 transition-opacity"
            onClick={() => setShowSidebar(!showSidebar)}
          />
        </Transition>
      </Transition>
      <div className="md:invisible visible items-center">
        {showSidebar ? null : (
          <div
            onClick={() => setShowSidebar(true)}
            className="fixed z-30 flex items-center cursor-pointer right-10 top-10 h-[30px] w-[30px]">
            <BarIcon strokeColor="black" />
          </div>
        )}
      </div> */}

      <div className={"z-40 ease-in-out duration-300"}>
        <div className="">
          <SideBar
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            display="inline-block top-0 right-0 md:left-0"
          />
        </div>
      </div>
    </div>
  );
};

export default MiniSidebar;
