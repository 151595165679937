import Select from "../../Select/Select";
import Button from "../../Button/Button";

function TopPallets() {
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="md:bg-white py-3 md:px-10 rounded-[10px]">
          <span className="mx-1">Artist:</span>{" "}
          <div className="inline-block">
            <Select options={[{value: "1", label: "Justfex"}]} />
          </div>
        </div>
        <div className="md:bg-white py-3 md:px-10 rounded-[10px] align-middle items-center">
          <span className="mx-1">Streams this month:</span>{" "}
          <div className="inline-block">
            <div className="text-xd_yellow">5.42% increase from last month</div>
          </div>
          <div className="inline-block align-middle items-center ml-5">
            <Button className="font-semibold">Generate Graphic</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopPallets;
