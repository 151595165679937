import IUser from "../interfaces/user";

export default function UserIcon({ user }: { user: IUser }) {
  return user.image ? (
    <img
      src={user?.image}
      alt="user"
      className="rounded-full"
    />) :
    (<div className="rounded-full bg-xd_yellow size-full  font-bold text-white flex items-center justify-center">
      <span className="">{user?.first_name?.charAt(0) + (user?.last_name?.charAt(0) || "")}</span>
    </div>
    );
}
