import React from "react";
import XPieChart from "../PieChart/PieChart";
interface Props {
  split: number;
}
function SpiltsAmount({ split }: Props) {
  return (
    <div className="flex items-start">
      <div className="flex-shrink-0 h-[23px]">
        <XPieChart split={split} startAngle={90} />
      </div>
      <div className="ml-3">
        <p className="text-gray-900 whitespace-no-wrap">
          <span>{split}%</span>
        </p>
      </div>
    </div>
  );
}

export default SpiltsAmount;
