import React, { useState } from "react";
import Table from "../Table/Table";
import IRelease from "../../interfaces/release";
import Trackrelease from "./TrackRelease";
import Upc from "./upc";
import { getReleaseStatus } from "../../data/getReleaseStatus";
import Action, { ReleaseAction } from "../Action/Action";
import Datedata from "./Datedata";
import { useDashboardContext } from "../Dashboard/DashboardContext";
import Modal from "../modal";
import NewReleaseForm from "../Release/NewReleaseForm";
import { useCreateReleaseContext } from "../Release/CreateReleaseContext";
import { useSearchParams } from "react-router-dom";
import ReleaseGridViewCard from "./ReleaseGridViewCard";
import logoLoader from "../../assets/logo_loader.png";
// import ReleaseGridData from "../../data/ReleaseGridData";
// import Button from "../Button/Button";

interface Props {
  toggleview: boolean;
}

function ReleaseTable({ toggleview }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const editReleaseModalQuery = "editreleasemodal";
  const { setRelease } = useCreateReleaseContext();
  const { getReleases } = useDashboardContext();
  const [openModal, setOpenModal] = useState<boolean>(
    searchParams.get(editReleaseModalQuery) === "true"
  );
  const [editRelease, setEditRelease] = useState<IRelease>();
  const { releases } = useDashboardContext()

  function GetReleaseTableData(
    data: IRelease[]
  ): { [index: string]: React.ReactNode }[] {
    return data.map((r) => {
      // console.log(d);
      return {
        id: r.id,
        release: <Trackrelease image={r.cover_art} track={r.title} artist={r.release_artist?.name} />,
        upc: <Upc UpcCode={r.upc_code} />,
        upload_date: <Datedata UploadDate={new Date(r.created_at * 1000)} />,
        release_date: r.distribution?.release_time ? <Datedata UploadDate={new Date(r.distribution?.release_time || "")} /> : "N/A",
        status: getReleaseStatus(r.status),
        /* FIXME: update the other action if this is updated */
        action: <ReleaseAction
          release={r}
          showModal={() => {
            setEditRelease(r);
            showModal();
          }}
        />
      };
    });
  }

  const columns = [
    { title: "Releases", dataIndex: "release", key: "1" },
    { title: "UPC", dataIndex: "upc", key: "2" },
    { title: "Upload Date", dataIndex: "upload_date", key: "3" },
    { title: "Release Date", dataIndex: "release_date", key: "4" },
    { title: "Status", dataIndex: "status", key: "5" },
    { title: "", dataIndex: "action", key: "6" },
  ];

  function showModal() {
    setOpenModal(true);
    setSearchParams(params => {
      params.set("tabpage", "release-details");
      params.set(editReleaseModalQuery, "true");
      return params;
    });
  }

  function closeModal() {
    setSearchParams((prev) => {
      prev.delete(editReleaseModalQuery);
      prev.delete("tabpage");
      return prev;
    });
    setOpenModal(false);
    setRelease({} as IRelease);
  }

  return (
    <div className="">
      <Modal
        className="min-h-screen"
        open={openModal}
        closeModal={closeModal}
        openModal={showModal}
      >
        <NewReleaseForm prevRelease={editRelease} callback={() => {
          getReleases();
          closeModal();
        }} />
      </Modal>
      <div className="inline-block min-w-full">
        {toggleview ? (
          <Table data={GetReleaseTableData(releases)} columns={columns} />
        ) : (
          <div className="py-6 md:px-12">
            {releases && releases.length ?
              <div className="grid grid-cols-2 gap-1 md:grid-cols-3 lg:grid-cols-4 md:gap-2 lg:gap-3 items-start">
                {releases.map((release, i) => {
                  return (
                    <div key={i} className="w-full">
                      <ReleaseGridViewCard release={release} showModal={() => {
                        setEditRelease(release);
                        showModal();
                      }} />
                    </div>
                  );
                })}
              </div> :
              <div className="text-center w-full">
                <div className="mx-auto">
                  <div className="flex justify-center ">
                    <img src={logoLoader} alt="No releases" className="size-20" />
                  </div>
                  <div className="text-center text-lg mt-4">
                    You have no releases yet
                  </div>
                </div>
              </div>}
          </div>
        )}
        {/*
        <div className="my-5 flex justify-center">
          <Button
            color="bg-xd_black"
            textColor="text-white"
            className="rounded-[10px]"
          >
            Load More
          </Button>
        </div>
        */}
      </div>
    </div>
  );
}

export default ReleaseTable;
