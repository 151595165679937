import { Link } from 'react-router-dom'
import RedirectIcon from '../Icons/RedirectIcon'

function TermsAndPolicies() {
  return (
    <div className='flex justify-end gap-5 font-semibold'>
      <div>
        <a className="flex gap-2" href="https://support.xdistromusic.com/portal/en/newticket" target="_blank">
          <div className=''>
            Get help
          </div>
          <div className='w-[15px]'><RedirectIcon /></div>
        </a>.
      </div>
      <div>
        <Link to={'/termsofuse'} className="flex gap-2">
          <div>Terms of Use</div>
          <div className='w-[15px]'><RedirectIcon /></div>
        </Link>
      </div>
      <div>
        <Link to={'/privacypolicy'} className="flex gap-2">
          <div>Privacy Policy</div>
          <div className='w-[15px]'><RedirectIcon /></div>
        </Link>
      </div>
    </div>
  )
}

export default TermsAndPolicies;
