import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Alert from '../../components/alert'
import sendRequest from '../../components/axios'
import { useDashboardContext } from '../../components/Dashboard/DashboardContext'
import UpgradePrices from '../../components/UpgradePrices/UpgradePrices'
import SettingsHeader from './SettingsHeader'

function Subscriptions() {
  const { currentPlan } = useDashboardContext();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    let session_id = searchParams.get("session_id");
    //console.log(session_id);
    if (session_id) {
      // fetch the sessionid details from the backend
      sendRequest("GET", `/api/v1/payment/validate-session/${session_id}`, null).then(res => {
        console.log(res.data.data)
        toast.success("payment made successfully");
        searchParams.delete("session_id");
        setSearchParams(searchParams);
      }).catch(err => toast.error("error verifying session id"));
    }
  }, []);
  
  return (
    <div>
      <div>
        <SettingsHeader heading={"Subscriptions"} />
        <div>
          {currentPlan ?
            <div className='text-sm font-bold'>
              You’re currently on the {currentPlan?.name} Plan.
              {/*<span className='text-xd_yellow font-medium px-2'>
                {currentPlan?.amount / 100}
              </span>*/}
            </div> :
            <Alert body={
              <div>
                Select a plan so you can start releasing your music
              </div>
            } />
          }
        </div>
        <div>
          <UpgradePrices />
        </div>
      </div>
    </div>
  )
}

export default Subscriptions;
