/* eslint-disable import/no-anonymous-default-export */
interface Props {
    strokeColor?: string
}
export default ({ strokeColor }: Props) => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.4481 3.05273H5.44812C4.20548 3.05273 3.19812 4.06009 3.19812 5.30273V8.30273C3.19812 9.54537 4.20548 10.5527 5.44812 10.5527H19.4481C20.6908 10.5527 21.6981 9.54537 21.6981 8.30273V5.30273C21.6981 4.06009 20.6908 3.05273 19.4481 3.05273Z" stroke="#828282" strokeWidth="1.5" />
            <path d="M19.4481 13.9453H5.44812C4.20548 13.9453 3.19812 14.9527 3.19812 16.1953V19.1953C3.19812 20.438 4.20548 21.4453 5.44812 21.4453H19.4481C20.6908 21.4453 21.6981 20.438 21.6981 19.1953V16.1953C21.6981 14.9527 20.6908 13.9453 19.4481 13.9453Z" stroke="#828282" strokeWidth="1.5" />
        </svg>
    );
};