import React from "react";
import { PieChart } from "react-minimal-pie-chart";

interface Props {
    split: number
    startAngle: number
}

function XPieChart({split,startAngle}:Props) {
  return (
    <PieChart
      startAngle={startAngle}
      data={[
        { value: 100 - split, color: "#FFBD5940" },
        { value: split, color: "#FFBD59" },
      ]}
    />
  );
}

export default XPieChart;
