import { useState } from 'react'
import MonthlyRecords from './MonthlyRecords';
import ShowYear from './ShowYear'

interface IEarning {
  id: string,
  month: string,
  year: string,
  earned: string
}

/*
const data: IEarning[] = [
    {
        id: "1",
        month: "January",
        earned: "$655.00"
    }
]
*/

function Earnings() {
  const [toggleview, setToggleview] = useState(false);
  const [earnings, setEarnings] = useState<IEarning[]>([]);

  function handleClick() {
    setToggleview((toggleview) => !toggleview);
  }

  return (
    <div>
      <ShowYear year={earnings.length ? earnings[0].year : `${new Date().getFullYear()}`} toggleview={toggleview} changeView={handleClick} />
      <div className={` grid-cols-2 gap-3 lg:grid-cols-3 md:gap-6 lg:gap-12 items-start my-5 ${toggleview ? "hidden" : "grid"}`}>
        {earnings.map((data, i) => {
          return (
            <div key={i}>
              <MonthlyRecords key={i} month={data.month} earned={data.earned} toggleview={toggleview} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Earnings
