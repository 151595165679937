import React from "react";
//import Xmark from "../Icons/Xmark";
//import SearchInput from "../SearchInput/SearchInput";

interface Props {
  children: React.ReactNode;
  icon?: React.ReactNode;
}

function Header({ children, icon }: Props) {
  return (
    <div className=" flex items-center justify-between">
      <div className="items-center font-semibold text-4xl text-black">
        {children}
      </div>

      <div className="md:flex gap-5 items-center hidden">
        <div className="flex-shrink-0">
          {icon}
        </div>
        {/*<SearchInput
          type={""}
          placeholder={"Input your keywords"}
          icon={<Xmark strokeColor="#4F4F4F" />}
        />*/}
      </div>
    </div>
  );
}

export default Header;

