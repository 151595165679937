import { Fragment } from 'react'
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import XMark from '../Icons/Xmark';

interface Props {
  children: React.ReactNode
  open: boolean
  // TODO: remove openModal, it doesn't seem necesary at all
  openModal(): void
  closeModal(): void;
  className?: string;
  closeIconColor?: string 
}

export default function Modal({children, open, closeModal, className, closeIconColor}: Props) {

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className={`relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:w-full ${className}`}>
                <div className="absolute top-0 right-0 pt-4 pr-4 inline-block">
                  <div
                    className="cursor-pointer size-10"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMark strokeColor={`${closeIconColor ? closeIconColor : "white"}`} aria-hidden="true" />
                  </div>
                </div>
                {children}
                </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
