import React from "react";

interface Props {
    Icon?: React.ReactNode;
    children?: React.ReactNode;
}

const Content: React.FC<Props> = ({ Icon, children }) => {
    return (
        <div>
            <div className="my-10">
                    <div className="lg:flex">
                        <div className="basis-1/2">
                            {Icon}
                        </div>
                        <div className="basis-1/2 font-light">
                            {children}
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default Content;
