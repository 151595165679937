import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Authorization": "Bearer " + localStorage.getItem("token"),
    "Content-Type": "application/json"//"application/x-www-form-urlencoded"
  }
});

function sendRequest(method: string, url: string, data: any) {
  // console.log({data})
  if (data instanceof FormData) {
    instance.defaults.headers["Content-Type"] = "multipart/form-data";
  }
  return instance({ method, url, data }).then((res) => {
    return res
  }).catch((err) => {
    if (!err.response) {
      // network error
      // console.log({err})
      //alert(err);
      throw err;
    }
    switch (err.response.status) {
      case 402:
        window.location.href = "/dashboard/settings/subscriptions";
        break
      case 401:
        localStorage.clear();
        localStorage.setItem("redirect", window.location.pathname);
        window.location.href = "/login";
        return err
      case 400:
        var errs = err.response.data.errors.split(",");
        if (errs.length > 1) {
          err.response.data.error = errs.reduce((acc: any, error: any) => {
            const [key] = error.split(" ", 1)
            acc[key] = error
            return acc
          }, {})
        }
    }
    throw err
  });
}

export default sendRequest;
