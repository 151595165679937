interface Props {
  border?: string;
  columns: {
    title: string;
    dataIndex: string;
    key: string;
  }[];
  data: { [index: string]: React.ReactNode }[]; //{string: React.ReactNode[]};
}

function Table({ columns, data, border }: Props) {
  return (
    <div className="inline-block min-w-full">
      <table className="w-full border-spacing-x-2 lg:border-spacing-x-4">
        <thead className="">
          <tr className="">
            {columns.map((column, key) => (
              <th
                key={key}
                className={`md:pl-10 pl-3 py-3 ${border || "border-y"} text-left text-13 font-medium text-[#BDBDBD] `}
              >
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((d, k) => (
            <tr key={k} className="">
              {columns.map((column, i) => (
                <td
                  className={`md:pl-10 pl-3 max-w-[25rem] md:min-w-full min-w-[7rem] py-5 text-sm `}
                  key={i}
                >
                  {d[column.dataIndex]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
