import React from "react";
import { Link } from "react-router-dom";
import data from "../../data/BenefitsData";
import Button from "../Button/Button";

function Features() {
  return (
    <div>
      <div className="text-center items-center flex justify-center">
        <div className="max-w-[600px] space-y-8">
          <div className="font-bold text-4xl leading-[36px]">
            Flexibility is key: We offer features that best{" "}
            <span className="italic">suits you.</span>
          </div>
          <div className="font-medium text-lg text-xd_gray">
            Have a look below to see some of the features we offer.
          </div>
        </div>
      </div>
      <div className="md:grid md:grid-cols-2 justify-center mt-10 lg:px-5 w-full container mx-auto gap-20 text-white">
        {data.map((d, i) => (
          <div key={i} className={`p-8 ${d.className}`}>
            <div
              className={`bg-white min-h-full relative text-black`}>
              <div className="p-6">
                <div
                  className={`absolute -top-8 w-[66px] h-[66px] transform ${d.position}`}>
                  {d.icon}
                </div>
                <div>
                  <div className="font-semibold text-4xl leading-tight">
                    {d.header}
                  </div>
                  <div className="my-3 text-[28px] leading-[43.4px] opacity-40">{d.text}</div>
                  {d.button ? (
                    <div className="mt-5 md:items-start items-center flex md:justify-start justify-center">
                      <Link to="/signup" className="inline-block text-black">
                        <Button children="Get Started" />
                      </Link>
                    </div>
                  ) : (null)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Features;
