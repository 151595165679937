import React from 'react'
import DownArrowIcon from '../../Icons/DownArrowIcon';
import UpArrowIcon from '../../Icons/UpArrowIcon';
import VerticalLine from '../../Icons/VerticalLine';

interface Props{
    year:string;
    toggleview: boolean;
    changeView: React.MouseEventHandler<HTMLDivElement>
}

function ShowYear({year, toggleview, changeView}:Props) {
  return (
    <div className='flex items-center justify-between gap-2 align-middle'>
        <div>{year}</div>
        <div className='md:inline-block hidden w-full'><VerticalLine /></div>
        <div className='w-[25px] flex-shrink-0 cursor-pointer' onClick={changeView}>{toggleview ? <DownArrowIcon strokeColor='#BDBDBD' /> : <UpArrowIcon strokeColor='#BDBDBD' /> }</div>
    </div>
  )
}

export default ShowYear