import React, { useState } from "react";
import { CloseIcon } from "../Icons/Icons";

interface Props {
  Header?: React.ReactNode;
  Icons?: React.ReactNode;
  children?: React.ReactNode;
}

const Question: React.FC<Props> = ({ Header, children }) => {
  const [hidden, setHidden] = useState(true);
  return (
    <div className="font-medium text-xl border-t-2 border-xd_black w-full cursor-pointer" onClick={() => {
      setHidden(!hidden);
    }}>
      <div className="my-7 clear-both">
        <div className="inline-block max-w-[90%]">{Header}</div>
        <div className="inline-block float-right">
          <CloseIcon />
        </div>
      </div>
      {/* TODO: add a transition so that the collapse is animated */}
      <div className={`${hidden ? "hidden" : ""} font-normal text-sm text-xd_grey`}>
        {children}
      </div>
    </div>
  );
};

export default Question;
