import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Link } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import Input from "../components/Input/Index";
import Button from "../components/Button/Button";
import PuffLoader from "react-spinners/PuffLoader";
import sendRequest from "../components/axios";

function ForgotPassword() {
  const [apiMessage, setApiMessage] = useState<string>("");
  const [apiError, setApiError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { handleSubmit, resetForm, getFieldProps, errors, touched } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      setApiMessage("");
      setApiError("");
      resetForm();
      sendRequest("POST", "/api/v1/forgot-password", values)
        .then((res) => {
          // console.log({ res });
          setApiMessage(res.data.message);
          resetForm();
        }).catch((err) => {
          // console.log({ err });
          if (err.response?.data?.errors) {
            setApiError(err.response.data?.errors);
            return;
          }
          setApiError("An error occurred");
        }).finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <AuthLayout>
      <h1 className="text-3xl md:text-big">Forgot Password</h1>
      <p className="text-xd_gray font-normal text-sm my-2">
        Don't have an account yet?{" "}
        <Link to="/signup" className="text-blue-600">
          signup
        </Link>
      </p>
      <div className="mt-7">
        {apiMessage ? (
          <p className="text-green-700 font-normal text-sm">{apiMessage}</p>
        ) : (
          ""
        )}
        {apiError ? (
          <p className="text-xd_red font-normal text-sm">{apiError}</p>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit}>
          <div className="">
            <Input
              className=""
              id="email"
              placeholder="Email"
              type="text"
              label="Email"
              {...getFieldProps("email")}
              error={errors.email && touched.email ? errors.email : ""}
            />
          </div>
          <div className="mt-5">
            <Button disabled={loading} type="submit" className="w-full">
              {loading ? <PuffLoader className="h-4 w-4" size={30} color="white" /> : "Submit "}
            </Button>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
}

export default ForgotPassword;
