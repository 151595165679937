import Footer from '../components/Footer/Footer'
import Hero from '../components/HeroSection/hero'
import Navbar from '../components/NavBar/Nav'
import Music from '../data/MusicListData'
import MusicContent from '../components/Music/MusicContent'

function MusicDistribution() {
  return (
    <div>
      <Navbar />
      <Hero
        action=''
        background='md:bg-Music bg-MobileMusic bg-right'
        header='Digital Distribution Stores'
        subheader='Upload your music to the largest music streaming, download, and social platforms, including Spotify, Apple Music, TikTok, Amazon, Deezer, Instagram, Tidal, and more.
Reach and connect with new audiences and fans across the globe.' />
      <div className="mt-12 px-5 ">
        <div className="lg:px-36 px-2 divide-y divide-black">
          {Music.map((text, i) => {
            return (
              <div className="mt-7" key={i}>
                <MusicContent key={text.id} Icon={text.icon} children={text.body} />
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default MusicDistribution
