import Button from '../components/Button/Button'
import FaqMainList from '../components/Faq/FaqMainList'
import Footer from '../components/Footer/Footer'
import Hero from '../components/HeroSection/hero'
import Navbar from '../components/NavBar/Nav'

function FaqPage() {
  return (
    <div>
      <Navbar />
      <div>
        <Hero
          background='md:bg-HeroFaq bg-MobileHeroFaq bg-right'
          action={
            <a className='w-full' target={"_blank"} href="https://support.xdistromusic.com/portal/en/newticket">
              <Button color="bg-white w-full">Ask Your Questions</Button>
            </a>
          }
          header='Frequently Asked Questions'
        />
        {/*
          <a href="mailto:admin@xdistromusic.com" className="flex justify-center md:justify-start">
            <Button color="bg-white">Ask Your Questions</Button>
          </a>
        */}
      </div>
      <div className="my-20 px-3 lg:px-40">
        <FaqMainList />
      </div>
      <Footer />
    </div >
  )
}

export default FaqPage
