import React from "react";
import XPieChart from "../PieChart/PieChart";

interface Props {
  split: number;
}

function MySplits({ split }: Props) {
  return (
    <div className="flex gap-2">
      {split ? (
        <div className="flex justify-between gap-2">
          <div className="h-5 flex-shrink-0">
            <XPieChart split={split} startAngle={90} />
          </div>
          <div>{split}</div>
        </div>
      ) : (
        "N/A"
      )}
    </div>
  );
}

export default MySplits;
