import React from 'react'
interface Props {
  header: React.ReactNode;
  body: React.ReactNode;
}

function PrivacyPolicy({ header, body }: Props) {
  return (
          <div className='my-8'>
            <div className='font-medium text-xl'>{header}</div>
            <div className='text-xd_grey text-sm my-5'>
              {body}
            </div>
          </div>
  )
}

export default PrivacyPolicy