/* eslint-disable import/no-anonymous-default-export */
interface Props {
    strokeColor?: string;
}
export default ({ strokeColor }: Props) => {
    return (
        <svg
            style={{ width: "100%", height: "100%" }}
            viewBox="0 0 33 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="16.1714" cy="17.0581" r="16.1714" fill="#1F1F1F" />
            <path
                d="M9.17139 16.94L13.3901 22.318M24.1714 16.94H9.17139H24.1714ZM9.17139 16.94L13.3901 12.0586L9.17139 16.94Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
