import {format} from 'date-fns'

interface Props {
    UploadDate: number | Date
}

function Datedata({UploadDate}: Props) {
  //console.log(UploadDate);
  return (
    <p className="text-gray-900 whitespace-no-wrap">{format(UploadDate, "dd MMM yyyy")}</p>
  )
}

export default Datedata
