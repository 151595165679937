/* eslint-disable import/no-anonymous-default-export */
interface Props {
    strokeColor?: string;
}
export default ({ strokeColor }: Props) => {
    return (
        <svg style={{ width: "100%", height: "100%" }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0005 8.94141L12.5005 16.1289L5.00049 8.94141" stroke={`${strokeColor || "#1F1F1F"}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};