import { Link } from "react-router-dom";
import LandingLogo from "../components/Icons/LandingLogo";
import AuthLogo from "./Primary Lockup_White";

export default function AuthLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <section className="bg-HeroImg bg-right bg-no-repeat bg-cover min-h-screen ">
      <div className="grid md:grid-cols-2 md:place-items-center my-auto min-h-screen px-3 md:px-0">
        <h1 className="text-center p-10">
          <div className="w-[50%] mx-auto">
            <Link to="/">
              <div className="lg:hidden">
                <LandingLogo />
              </div>
              <div className="hidden lg:block">
                <AuthLogo />
              </div>
            </Link>
          </div>
        </h1>

        <div className="h-max bg-white p-8 md:p-10 w-full lg:max-w-[35em] rounded-lg">
          {children}
        </div>
      </div>
    </section>
  );
}
