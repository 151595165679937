import React from 'react'

function InsightTableHeader() {
    return (
        <div>
            <div className="flex items-center justify-between md:px-10 px-5 py-6">
                <strong className="font-semibold text-xl">Releases</strong>
            </div>
        </div>
    )
}

export default InsightTableHeader