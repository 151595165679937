import React, { useState } from 'react'
import IStreams from '../../interfaces/streams'
import Trackrelease from '../ReleaseTable/TrackRelease'
import StreamData from '../../data/StreamsData'
import Table from '../Table/Table'
import Progress from './Progress'

function GetInsightTableData(
    data: IStreams[]
): { [index: string]: React.ReactNode }[] {
    return data.map((d, i) => {
        return {
            id: d.id,
            release: <Trackrelease track={d.track} artist={d.artist} />,
            streams: d.streams,
            progress: <Progress progress={d.progress} icons={d.icons} />,
        }
    })
}


function InsightTable() {
    const [streams] = useState(StreamData)
    const columns = [
        { title: "Release", dataIndex: "release", key: "1" },
        { title: "Streams", dataIndex: "streams", key: "2" },
        { title: "Progress", dataIndex: "progress", key: "3" },
    ];
    return (
        <div className="">
            <div className="inline-block min-w-full">
                <Table data={GetInsightTableData(streams)} columns={columns} />
            </div>
        </div>
    )
}

export default InsightTable