/* eslint-disable import/no-anonymous-default-export */
export default () => {
  return (
    <svg
      style={{ width: "100%", height: "100%" }}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.48572" cy="6.51575" r="6.51428" fill="#FFBD59" />
    </svg>
  );
};
