import React from "react";
import data from "../../data/PrivacyPolicyData";
import PrivacyPolicy from "./PrivacyPolicy";
import Navbar from "../NavBar/Nav";
import Footer from "../Footer/Footer";

function Policies() {
  return (
    <div>
      <Navbar background={true} />
      <div className="flex mx-5 md:mx-96 max-w-[750px]">
        <div>
          <div className="font-semibold text-[28px] my-10">Privacy Policy</div>
          {data.map((d, i) => {
            return (
              <div key={i} className="w-full">
                <PrivacyPolicy header={d.header} body={d.body} />
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Policies;

