/* eslint-disable import/no-anonymous-default-export */
interface Props {
  strokeColor?: string;
}
export default ({ strokeColor }: Props) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.7543 7.48877H3.87598"
        stroke={strokeColor || "white"}
        strokeWidth="2.43092"
        strokeLinecap="round"
      />
      <path
        d="M25.7543 14.7812H3.87598"
        stroke={strokeColor || "white"}
        strokeWidth="2.43092"
        strokeLinecap="round"
      />
      <path
        d="M25.7543 22.0742H3.87598"
        stroke={strokeColor || "white"}
        strokeWidth="2.43092"
        strokeLinecap="round"
      />
    </svg>
  );
};
