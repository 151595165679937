/* eslint-disable import/no-anonymous-default-export */
interface Props {
    strokeColor?: string
}
export default ({ strokeColor }: Props) => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 3.36133H5C3.75736 3.36133 2.75 4.36869 2.75 5.61133V8.61133C2.75 9.85397 3.75736 10.8613 5 10.8613H8C9.24264 10.8613 10.25 9.85397 10.25 8.61133V5.61133C10.25 4.36869 9.24264 3.36133 8 3.36133Z" stroke="#828282" strokeWidth="1.5" />
            <path d="M19 3.36133H16C14.7574 3.36133 13.75 4.36869 13.75 5.61133V8.61133C13.75 9.85397 14.7574 10.8613 16 10.8613H19C20.2426 10.8613 21.25 9.85397 21.25 8.61133V5.61133C21.25 4.36869 20.2426 3.36133 19 3.36133Z" stroke="#828282" strokeWidth="1.5" />
            <path d="M19 14.3613H16C14.7574 14.3613 13.75 15.3687 13.75 16.6113V19.6113C13.75 20.854 14.7574 21.8613 16 21.8613H19C20.2426 21.8613 21.25 20.854 21.25 19.6113V16.6113C21.25 15.3687 20.2426 14.3613 19 14.3613Z" stroke="#828282" strokeWidth="1.5" />
            <path d="M8 14.3613H5C3.75736 14.3613 2.75 15.3687 2.75 16.6113V19.6113C2.75 20.854 3.75736 21.8613 5 21.8613H8C9.24264 21.8613 10.25 20.854 10.25 19.6113V16.6113C10.25 15.3687 9.24264 14.3613 8 14.3613Z" stroke="#828282" strokeWidth="1.5" />
        </svg>


    );
};
