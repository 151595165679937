
import React, { useState } from 'react'
import TerritoryData from '../../data/TerritoryData'
import Table from '../Table/Table'
import ITerritory from '../../interfaces/territory'
import Territories from './Territories'

function GetTerritoryTableData(
    data: ITerritory[]
): { [index: string]: React.ReactNode }[] {
    return data.map((d, i) => {
        return {
            id: d.id,
            territory: <Territories country={d.country} />,
            streams: d.streams,
        }
    })
}


function TerritoryTable() {
    const [streams] = useState(TerritoryData)
    const columns = [
        { title: "Territory", dataIndex: "territory", key: "1" },
        { title: "Streams", dataIndex: "streams", key: "2" },
    ];
    return (
        <div className="">
            <div className="inline-block min-w-full">
                <Table data={GetTerritoryTableData(streams)} columns={columns} />
            </div>
        </div>
    )
}

export default TerritoryTable
