import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import AboutPage from "./pages/AboutPage";
import FaqPage from "./pages/FaqPage";
import Index from "./pages/Index";
import Login from "./pages/Login";
import MusicDistribution from "./pages/MusicDistribution";
import Signup from "./pages/Signup";
import MyMusic from "./pages/dashboard/MyMusic";
import Settings from "./pages/dashboard/Settings";
import Wallet from "./pages/dashboard/Wallet";
import Reciepts from "./pages/dashboard/Reciepts";
import Subscriptions from "./pages/dashboard/Subscriptions";
import RoyaltySplits from "./pages/dashboard/RoyaltySplit";
import TrackSplit from "./pages/dashboard/TrackSplit";
import Insight from "./pages/dashboard/Insight";
import PrivateRoutes from "./PrivateRoute";
import Policies from "./components/PrivacyPolicy/Policies";
import ScrollToTop from "./components/ScrollTop/ScrollTop";
import Terms from "./components/TermsOfUse/Terms";
import ReleaseDetails from "./pages/dashboard/ReleaseDetails";
import Api from "./pages/dashboard/Api";
import ErrorNopage from "./pages/error";
import Artists from "./pages/dashboard/Artists";
import PlaylistSupport from "./components/PlaylistSupport/PlaylistSupport";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import DistributionAgreementPage from "./components/DistributionAgreements/DistributionAgreementPage";
//import SingleTrackReleaseDetail from "./pages/dashboard/SingleTrackReleaseDetail";
//import MultipleTracksReleaseDetails from "./pages/dashboard/MultipleTracksReleaseDetails";
//import Dashboard from "./components/Dashboard/Dashboard";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/aboutus" element={<AboutPage />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/partners" element={<MusicDistribution />} />
            <Route path="/privacypolicy" element={<Policies />} />
            <Route path="/termsofuse" element={<Terms />} />
            <Route path="/distributionagreement" element={<DistributionAgreementPage />} />
            <Route path="/*" element={<ErrorNopage/>} />
            <Route element={<PrivateRoutes/>}>
              <Route path="/dashboard/my-music" element={<MyMusic />} />
              <Route path="/dashboard/my-music/:id" element={<ReleaseDetails />} /> 
              <Route path="/dashboard/wallet" element={<Wallet />} />
              <Route path="/dashboard/settings" element={<Settings />} />
              <Route path="/dashboard/settings/receipts" element={<Reciepts />} />
              <Route path="/dashboard/settings/subscriptions" element={<Subscriptions />} />
              <Route path="/dashboard/settings/api" element={<Api />} />
              <Route path="/dashboard/royalty-splits" element={<RoyaltySplits />} />
              <Route path="/dashboard/royalty-splits/:track_id" element={<TrackSplit />} />
              <Route path="/dashboard/playlist-support" element={<PlaylistSupport />} />
              {/* <Route path="/dashboard/royalty-splits/artist-split" element={<ArtistSplit />} /> */}
              <Route path="/dashboard/artists" element={<Artists />} />
              <Route path="/dashboard/insight" element={<Insight />} />
            </Route>
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
