import AnalyticsIcon from "../components/Icons/AnalyticsIcon";
import BoltIcon from "../components/Icons/BoltIcon";
import SettingIcon from "../components/Icons/SettingIcon";
import ShareIcon from "../components/Icons/ShareIcon";
import SplitIcon from "../components/Icons/SplitIcon";

const data = [
  {
    id: "1",
    icon: <ShareIcon />,
    header: "Global Music Distribution",
    text: (
      <div>
        Send your music to over 150+ DSPs and social platforms globally.
      </div>
    ),
    position: "-right-5 ",
  },

  {
    id: "2",
    icon: <SplitIcon />,
    header: "Share Royalties",
    text: (
      <div>Share royalties from any track automatically with your Team.</div>
    ),
    position: "md:-right-5 -left-8",
  },

  {
    id: "3",
    icon: <BoltIcon />,
    header: "Playlist Support",
    text: (
      <div>
        Have your rollout plans? We’ll pitch your track so you can land those
        important editorial playlist placements.
      </div>
    ),
    position: "-right-5",
  },

  {
    id: "4",
    icon: <SettingIcon />,
    header: "Manage Multiple Artists",
    text: (
      <div>
        Manage all your artists and catalog in one place. No need for different
        accounts.
      </div>
    ),
    position: "md:-right-5 -left-8",
  },

  {
    id: "5",
    icon: <AnalyticsIcon />,
    header: "Priority Support",
    text: (
      <div>
        We maintain a customer-oriented culture. You can speak to our team
        whenever you need us.
      </div>
    ),
    position: "md:-right-5 left-1/2  -translate-x-1/2",
    className: "col-start-1 col-end-3 md:text-start text-center",
    button: true
  },
];
export default data;
