export default function formatAmount(amount: number, currency: string) {
  // get user's locale
  const locale = navigator.language || "en-US";
  currency = currency || "USD";
  // console.log({ amount, currency, locale });
  return (Math.abs(amount)).toLocaleString(locale, {
    style: "currency",
    currency: currency,
    currencyDisplay: 'narrowSymbol',
  });
}
