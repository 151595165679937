/* eslint-disable import/no-anonymous-default-export */
interface Props {
  fillColor?: string;
}
export default ({ fillColor }: Props) => {
  return (
    <svg
      style={{ width: "100%", height: "100%" }}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.02952 0.444392C4.5642 -0.072474 5.41236 -0.0724745 5.94704 0.444392L8.58732 2.9967C9.47905 3.85872 8.86883 5.36796 7.62857 5.36796H2.34799C1.10773 5.36796 0.497512 3.85872 1.38924 2.99671L4.02952 0.444392Z"
        fill={fillColor}
      />
    </svg>
  );
};
