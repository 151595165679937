import SettingsHeader from "./SettingsHeader";
import { useEffect, useState } from "react";
import sendRequest from "../../components/axios";

interface ApiKeys {
  live_key: string;
  test_key: string;
}

function Api() {
  const [apikeys, setApiKeys] = useState<ApiKeys>({
    live_key: "",
    test_key: ""
  });

  function getCredentials() {
    sendRequest("GET", "/api/v1/credentials", null).then(res => {
      setApiKeys(res.data.data);
    }).catch(err => {
      console.log(err);
    })
  }

  useEffect(() => {
    getCredentials();
  }, []);

  return (
    <div>
      <div>
        <SettingsHeader heading="Api" />
        <div className="flex justify-center">
          <div>
            <p>Contact us to get API Access</p>
            {/*<ApiKey name="Live Api Key" value={apikeys.live_key} />*/}
          </div>
        </div>
      </div>
    </div>
  );
}

function ApiKey({ name, value }: { name: string, value: string }) {
  return (
    <div>
      <div className="my-3">{name}</div>
      <p className="border-2 border-black text-gray-500 p-3 rounded-md">{value}</p>
    </div>
  );
}

export default Api;
