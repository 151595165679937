import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import ISubscriptionPlan from "../../interfaces/subplan";
import formatAmount from "../../utils/money";
import sendRequest from "../axios";
import Button from "../Button/Button";
import { useDashboardContext } from "../Dashboard/DashboardContext";
import Modal from "../modal";
import Feature from "./Feature";
import PaystackPop from "@paystack/inline-js";

interface PriceProps {
  index: number;
  className?: string;
  selected?: boolean;
  subscription_plan: ISubscriptionPlan;
}

const Price: React.FC<PriceProps> = ({ subscription_plan: {
  id,
  subscription_price_id,
  name,
  description,
  amount,
  features,
  currency,
},
  className,
  selected,
  index,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { user } = useDashboardContext();
  const navigate = useNavigate();

  const isFirst = index === 0;

  function startSubscription() {
    if (user) {
      if (currency === "NGN") {
        sendRequest("POST", "/api/v1/create-checkout-session", {
          subscription_plan_id: id,
          subscription_price_id: subscription_price_id,
          email: user.email,
        }).then((res) => {
          const { access_code } = res.data.data;
          const popup = new PaystackPop();
          popup.resumeTransaction(access_code);
          // popup.initialize({ access_code, callback: function (response) { console.log(response) } })
        });
      } else {
        setOpenModal(true);
      }
    } else {
      // toast.info("Select the plan you want to subscribe to.");
      navigate("/dashboard/settings/subscriptions");
    }
  }

  return (
    <div
      className={` ${isFirst ? "md:col-span-2 grid md:grid-cols-3 gap-4 " : ""
        } p-8 bg-white rounded-big ${selected
          ? "bg-xd_black text-white"
          : "hover:bg-xd_black hover:text-white"
        } ${className}`}>
      <StripePaymentModal id={id} subscription_price_id={subscription_price_id} openModal={openModal} setOpenModal={setOpenModal} />
      <div className={`${isFirst ? "col-span-1" : ""}`}>
        <h1 className="font-sans font-medium text-18">{name}</h1>
        <div className="py-2 text-13 opacity-40 ">{description}</div>
        <div className="font-medium  my-[-10px] text-5xl">
          {formatAmount(amount / 100, currency)}
          <span className="text-medium font-medium">/Year</span>
        </div>
        <div className="text-center py-4">
          <Button
            className={`w-full`}
            color={selected ? "bg_black" : ""}
            disabled={selected}
            onClick={startSubscription}
            hover={
              selected
                ? `text-xd_yellow bg-black `
                : `hover:text-xd_yellow hover:bg-black`
            }>
            {selected ? "Current Plan" : "Subscribe"}
          </Button>
        </div>
      </div>
      <div
        className={`grid ${isFirst
          ? " md:grid-rows-4 md:grid-flow-col gap-3 md:justify-evenly col-span-2"
          : " md:grid-cols-2 grid-flow-row gap-3 col-span-full"
          } leading-6`}>
        {features ? features.map((f, i) => <Feature key={i}>{f}</Feature>) : ""}
      </div>
    </div>
  );
};

interface PaymentModalProps {
  id: string;
  subscription_price_id: string;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export function StripePaymentModal({ id, subscription_price_id, openModal, setOpenModal }: PaymentModalProps) {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || "");

  const fetchClientSecret = useCallback(() => {
    console.log("fetching client secret");
    // Create a Checkout Session
    return sendRequest("POST", "/api/v1/create-checkout-session", {
      subscription_plan_id: id,
      subscription_price_id,
      host: window.location.origin,
    }).then((res) => {
      //console.log(res);
      return res.data.data.clientSecret;
    });
  }, []);

  const options = { fetchClientSecret };

  return <Modal
    closeIconColor="black"
    open={openModal}
    openModal={() => {
      setOpenModal(true);
    }}
    className="w-full md:w-[60%] p-10"
    closeModal={() => {
      setOpenModal(false);
    }}>
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  </Modal>
}

export default Price;
