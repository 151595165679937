import UploadBanner from "../../components/UploadBanner/UploadBanner";
import Header from "../../components/Header/Header";
import Releases from "../../components/ReleaseTable/Releases";
import { useDashboardContext } from "../../components/Dashboard/DashboardContext";
import Alert from "../../components/alert";
import { useState } from "react";
import { StripePaymentModal } from "../../components/Prices/Price";
import ISubscriptionPlan from "../../interfaces/subplan";
import sendRequest from "../../components/axios";
import PaystackPop from "@paystack/inline-js";

function MyMusic() {
  const { currentPlan, plans } = useDashboardContext();
  return (
    <div className="space-y-8">
      <div>
        <Header children="My Music" />
      </div>
      <div className="space-y-4">
        {plans.length ?
          currentPlan ? null : <Alert body={
            <div>
              Select a plan so you can start releasing your music:
              {plans?.map((p, index) => <SubscriptionButton key={index} plan={p} />)}
            </div>}
          /> : null}
        <UploadBanner
          header="Upload New Music"
          subheader="Create a new release in just a few minutes"
          buttonText="Start Upload"
        />
      </div>
      <Releases />
    </div>
  );
}

function SubscriptionButton({ plan }: { plan: ISubscriptionPlan }) {
  const { user } = useDashboardContext();
  const [openModal, setOpenModal] = useState(false);

  // FIXME: consolidate this into one component
  function startSubscription() {
    if (plan.currency === "NGN") {
      sendRequest("POST", "/api/v1/create-checkout-session", {
        subscription_plan_id: plan.id,
        subscription_price_id: plan.subscription_price_id,
        email: user.email,
      }).then((res) => {
        const { access_code } = res.data.data;
        const popup = new PaystackPop();
        popup.resumeTransaction(access_code);
        // popup.initialize({ access_code, callback: function (response) { console.log(response) } })
      });
    } else {
      setOpenModal(true);
    }
  }


  return <button className="ml-2 underline" onClick={startSubscription}>
    <StripePaymentModal
      subscription_price_id={plan.subscription_price_id}
      id={plan.id}
      openModal={openModal}
      setOpenModal={setOpenModal} />
    {plan.name}
  </button>
}

export default MyMusic;
