import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import IRelease from "../../interfaces/release";
import sendRequest from "../../components/axios";
import SingleTrackReleaseDetail from "./SingleTrackReleaseDetail";
import MultipleTracksReleaseDetails from "./MultipleTracksReleaseDetails";

export default function ReleaseDetails() {
  let { id } = useParams();
  const [release, setRelease] = useState({
    id: `${id}`,
    title: "",
    track:
      "Sample Track very long name and an even longer name that is longer than river naija ",
    release_artist_id: "Justfex",
    date: "17 sep 2022",
    upc_code: "109575903GSISK9823",
    created_at: 1685893559,
    release_date: "2022-09-17",
    status: "FAILED",
    image: "",
    tracks: [],
  } as IRelease);

  const getRelease = () => {
    sendRequest("GET", `/api/v1/release/${id}`, null)
      .then((res: any) => {
        // console.log(res.data);
        setRelease(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getRelease();
  }, []);

  return (
     <MultipleTracksReleaseDetails release={release} />
  )
}
