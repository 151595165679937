/* eslint-disable import/no-anonymous-default-export */
interface Props {
  strokeColor?: string
}
export default ({strokeColor}: Props) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox= "0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5323 8.22021L7.8877 22.8648"
        stroke={strokeColor || "black"}
        strokeWidth="1.83058"
        strokeLinecap="round"
      />
      <path
        d="M22.5323 22.8648L7.8877 8.22021"
        stroke={strokeColor || "black"}
        strokeWidth="1.83058"
        strokeLinecap="round"
      />
    </svg>
  );
};
