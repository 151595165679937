import React from "react";
import Hero from "../components/HeroSection/hero";
import Navbar from "../components/NavBar/Nav";
import AboutUsCard from "../assets/AboutusCard.png";
import Button from "../components/Button/Button";
import Footer from "../components/Footer/Footer";

function AboutPage() {
  document.title = "About - XDistro Music";
  return (
    <div>
      <Navbar />
      <Hero action="" header="All about XDistro" background="bg-DeskImgLight" />
      <div className="mt-12 px-5">
        <div className="container mx-auto lg:px-36 px-2">
          <div className="lg:flex gap-10">
            <div className="lg:basis-1/2 mb-8 font-bold text-3xl">
              Your No #1 Music Distribution Platform
            </div>
            <div className="lg:basis-1/2 font-normal text-xd_grey">
              XDistro Music is an independent digital music distribution,
              licensing and royalty collection company that is addressing the
              need for transparent, professional and affordable content
              distribution service through a simple content management system.
              <p className="mt-4">
                We offer a simple content management system, 24/7 communication
                channels, and informational resources independent musicians need
                to handle their careers in a successful way.
              </p>
              <p className="mt-4">
                {" "}
                XDistro Music is focused on supporting independent musicians and
                creators on digital distribution, publishing, copyright
                registration and royalty collection.
              </p>
            </div>
          </div>
          <div className="lg:flex gap-10 py-20">
            <div className="lg:basis-1/2 mb-5 font-bold text-3xl">
              What We Offer
              <div className="my-20">
                <img src={AboutUsCard} alt="" />
              </div>
            </div>
            <div className="lg:basis-1/2 font-normal flex flex-col px-5">
              <div className="">
                <ol className="list-decimal font-bold">
                  <li>
                    Music Distribution:
                    <p className="text-xd_grey font-normal">
                      Get your music distributed to all networks in the
                      industry. Over 150+ DSPs worldwide including Spotify,
                      Apple Music, Amazon, Deezer. Send your music to social
                      platforms such as: TikTok, Facebook, Instagram, and more.
                    </p>
                  </li>

                  <li className="my-8">
                    Content Management:
                    <p className="text-xd_grey font-normal">
                      Our technology offers a simple interface for content
                      upload, reporting, streaming analytics, royalty payments,
                      team split tool, and more.
                    </p>
                  </li>

                  <li className="my-8">
                    Royalty Collection:
                    <p className="text-xd_grey font-normal">
                      We offer swift royalty payment options via PayPal, Bank
                      transfer and Crypto payment options. We ensure that
                      wherever you are, your royalty gets to you without
                      hassles.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 lg:flex lg:flex-row flex flex-col-reverse">
        <div className="lg:basis-1/2 bg-XdistroAboutUs h-[27em] w-full"></div>
        <div className="lg:basis-1/2 h-[27em] bg-[#242827] grid place-items-center">
          <div className="text-center lg:text-left p-8 text-white">
            <h1 className="font-sans font-bold  text-3xl mb-8">
              Teamwork Makes The Dream Work
            </h1>
              <p className="leading-[27px] font-normal  opacity-70 text-sm align-top">
                Our customer support team answers every single email in less
                than 24hours.
                <br />
                We maintain a customer-oriented culture and offer the best
                support to every client in time.
              </p>
          </div>
        </div>
      </div>
      <div className="mt-12 px-5">
        <div className="text-center my-28">
          <div className="inline-block w-full">
            <h1 className="font-bold text-large">Get started with us Today!</h1>
            <div className="mt-10">
              <Button>Get Started</Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutPage;
