import React, { useEffect, useState } from "react";
import IReciepts from "../../interfaces/reciepts";
import Table from "../Table/Table";
import Datedata from "../ReleaseTable/Datedata";
import sendRequest from "../axios";
import formatAmount from "../../utils/money";

function GetRecieptsTableData(
  data: IReciepts[]
): { [index: string]: React.ReactNode }[] {
  return data.map((d) => {
    return {
      id: d.id,
      date: <Datedata UploadDate={new Date(d.start_date * 1000)} />,
      ref_id: d.id,
      plan: d.plan,
      price: formatAmount(d.price / 100, d.currency),
      status: d.active ? "Active" : d.terminated_at ?
        "Ended at " + new Date(d.terminated_at * 1000).toLocaleDateString() : "Inactive",
    };
  });
}


function RecieptsTable() {
  const [reciepts, setReciepts] = useState<IReciepts[]>([]);

  function getReceipts() {
    sendRequest("GET", "/api/v1/subscriptions", {}).then((res: any) => {
      console.log(res.data);
      setReciepts(res.data.data);
    }).catch((err: any) => {
      console.log(err);
    });
  }

  const columns = [
    { title: "Date", dataIndex: "date", key: "1" },
    { title: "Reference ID", dataIndex: "ref_id", key: "2" },
    { title: "Plan", dataIndex: "plan", key: "3" },
    { title: "Price", dataIndex: "price", key: "4" },
    { title: "Status", dataIndex: "status", key: "5" },
  ];

  useEffect(() => {
    getReceipts();
  }, []);

  return (
    <div className="">
      <div className="inline-block min-w-full">
        <Table data={GetRecieptsTableData(reciepts)} columns={columns} />
      </div>
    </div>
  );
}

export default RecieptsTable;
