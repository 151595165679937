const text = [
  {
    id: 1,
    header: <h1>What is XDistro Music?</h1>,
    body: (
      <div className="leading-6 space-y-2">
        <p className='ssss'>
          XDistro Music is a digital music company, offering services in music distribution,
          marketing and artist support to self-releasing,
          independent artists and record labels.
        </p>
        <p>
          With Us, you can upload your music to top stores including Spotify,
          Apple Music, Deezer, TikTok and more, starting at just $13.99/year. Keeping 100%
          of your earnings, rights and staying in control of your music.
        </p>
        <p>
          In addition to our distribution services, We also offer Marketing Support and a variety
          of additional promotional services and tools.
        </p>
        {/*
        XDistro Music is a digital distributions service that musicians and
        labels use to upload music to online stores & streaming services such as
        Apple Music, Spotify, Deezer, Tidal and more.
        <p className="my-2">
          XDistro Music collects earnings in royalties and sends 100% of these
          payments to artists and labels. XDistro Music will however minus bank
          processing fees and taxes where applicable.
        </p>
        */}
      </div>
    ),
  },

  {
    id: 2,
    header: <h1>How Much Does XDistro Music Cost?</h1>,
    body: (
      <div className="leading-6 space-y-2">
        <p>
          You can start releasing unlimited music to Spotify, Apple Music, TikTok, Amazon Music,
          Tidal, Deezer, Instagram, YouTube Music and every other global streaming,
          download and social platform for as little as $13.99 a year.
        </p>
        <p>You can find details of our distribution subscription plans on our Home page.</p>
        <p>Our Artist, Pro and Label subscription plans are based on the number of artists you'd like to manage from your account.</p>
        <ol>
          <li>(a). The "Artist" plan: This plan accommodates a single artist, and costs $13.99/year.</li>
          <li>(b). The "Pro" plan: This plan accommodates up to five (5) artists, and costs $49.99/year.</li>
          <li>(c). The "Label" Plan: This plan is made for labels or managements with up to twenty (50) artists, and costs just $149.99</li>
        </ol>
      </div>
    ),
  },

  {
    id: 3,
    header: <h1>How do I remove my music from stores?</h1>,
    body: (
      <div className="leading-6">
        <p>You can remove your music from stores at any time.</p>
        <p>Simply send a Takedown request to support@xdistromusic.com copying your Email address, Release Title and UPC Code.</p>
        <p>It usually takes between 2 - 4 weeks for takedowns to be removed from all stores.</p>
      </div>
    )
  },

  {
    id: 4,
    header: (<h1>How Long Will It Take for My Music to Be Available in stores?</h1>),
    body: (
      <div className="leading-6 space-y-2">
        <p>Depending on the release date you choose, it takes 1–7 days for your release to appear in stores after it is approved.</p>
        <p>We will send your music to retailers right away as soon as we review your release and are satisfied with every element.</p>
        <p>NOTE: In order to prevent needless delays that might be outside of our control, please make sure to submit your release as early as four weeks before your scheduled release date.</p>
      </div>
    ),
  },

  {
    id: 5,
    header: <h1>What countries will my music be available in?</h1>,
    body: (
      <div className="leading-6">
        We are sure you would prefer your release to be available everywhere.
        Your music will be released worldwide.
      </div>
    ),
  },


  {
    id: 6,
    header: <h1>Can I upload cover songs, remixes, or songs using samples?</h1>,
    body: (
      <div className="leading-6">
        <p>Of course!</p>
        <p>
          Though some cover songs might require a license, you are still able to upload them.
          Before uploading, do make sure that all of the cover samples have been cleared.
        </p>
        <p>
          To protect yourself from potential legal action from the original copyright holders,
          you'll most likely need to buy a license if you intend to release a cover, remix,
          or song that uses samples.
        </p>
        <p>
          After obtaining the required license, you may add the copyright information for the
          song you've obtained the rights to and upload your documentation at the Release Builder's
          TrackList step.
        </p>
      </div>
    ),
  },

  {
    id: 7,
    header: <h1>Who owns the rights to my music?</h1>,
    body: (
      <div className="leading-6">
        <p>
          You own 100% of your music.
        </p>
        <p>
          XDistro Music does not take any ownership or
          intellectual property rights of any artist.
        </p>
      </div>
    ),
  },

  {
    id: 8,
    header: (
      <h1>What is the minimum age requirement to use XDistro Music service?</h1>
    ),
    body: (
      <div className="leading-6">
        <p>
          To utilize the XDistro Music service, you must be at least 18 years old.
          If you are under this age, please have an adult register on your behalf.
        </p>
      </div>
    ),
  },

  {
    id: 9,
    header: <h1>Do I need to take down my music on other distributors to switch to XDistro Music?</h1>,
    body:
      <div className="leading-6 space-y-2">
        <p>
          Yes, you do; otherwise, the distribution of your music via us will be delayed.
        </p>
        <p>
          When uploading music to XDistro Music, if you include the UPC/EAN Code, all of your streaming data for previously released music will be kept. It really is that simple.
        </p>
        <h1 className="fond-bold">
          The following steps will help you successfully transition to XDistro Music:
        </h1>
        <p>
          1. Locate all original files and metadata related to the music you want to move.
        </p>
        <p>
          <em>
            This comprises:
          </em>
        </p>
        <ol>
          <li>
            a. UPC and ISRC codes
          </li>
          <li>
            b. Track titles and artist names
          </li>
          <li>
            c. Original audio recordings
          </li>
          <li>
            d. Original artistic creations
          </li>
        </ol>
        <p>
          When you re-upload your music to XDistro Music, all of this information needs to be EXACTLY the same as your original release, so take note of it, double-check everything, and store it somewhere safe.
        </p>
        <p>
          2. Send your present distributor a takedown request.
        </p>
        <p>
          Ask your current distributor to take your music out of stores by logging into your account. You can initiate this by clicking the "takedown request" button, which is typically present.
        </p>
        <p>
          3. Upload your songs to XDistro Music once more.
        </p>
        <p>
          You can re-upload your music to XDistro Music once your current distributor has completed the process of taking down your release and it is no longer available in stores.
        </p>
        <p>
          Use the original audio files and artwork, along with the exact same ISRC codes, artist names, and track titles that you previously noted.
        </p>
        <p>
          What comes next?
        </p>
        <p>
          There might be a brief outage, but we'll work quickly to get your music back up in stores with all of your original streaming numbers & playlist placements intact.
        </p>
        <p>
          If you’re thinking of moving to XDistro Music, contact us at support@xdistromusic.com with the subject line ‘Switch To XDistro Music,’ and we’ll help you with the process.
        </p>
      </div>
  },

  {
    id: 10,
    header: <h1>How do I upload my music to XDistro Music?</h1>,
    body: (
      <div className="leading-6 space-y-2">
        It’s super easy to upload your music on XDistro Music.
        <p className="my-3">
          Just head over to "new upload" on your dashboard, our content builder
          walks you through everything you need to do to get your music up
          successfully.
        </p>
        <p className="my-3">[Upload form guide]</p>
        <p className="my-3">
          <span className="font-bold">Artist Name:</span> This is where you
          input the name of the Main artist(s) of the release. The Main artist
          or "performer" is the major artist at the album level. The main
          artist(s) will be listed on every track on the album.
        </p>
        <p className="my-3">
          <span className="font-bold">Album title:</span> Here you need to input
          a title for your album. If you are submitting more than one song, then
          you will have to input titles for all the tracks.
        </p>
        <p className="my-3">
          <span className="font-bold">Release date:</span> The release date
          section of the upload form is where you indicate when you would like
          your release to go live in stores.
        </p>
        <p className="my-3">
          <span className="font-bold">Language:</span> This must select the
          primary language used on the release. The language selected here must
          match the language used for the track titles.
        </p>
        <p className="my-3">
          <span className="font-bold">Pre-order:</span> Here, you can choose
          whether or not you would like to opt your release into Pre-Order in
          services that support it.
        </p>
        <p className="my-3">
          <span className="font-bold">Record label:</span> Here you can input a
          custom label for your music.
        </p>
        <p className="my-3">
          <span className="font-bold">Album cover:</span> This section is where
          you upload your music cover design. High quality cover art formats
          (jpg, png) with dimensions 3000 x 3000 works best.
        </p>
        <p className="my-3">
          <span className="font-bold">Album price:</span> You can indicate a
          custom album price in this section. If your release has more than 1
          song then in this section you can set the album price for iTunes and
          Amazon, you have options ranging from $2.99 to $11.99.
        </p>
        <p>
          <span className="font-bold">Stores:</span>
          This section is where you can select which services you want your music to be sent to.
          Simply check the boxes next to your desired services and uncheck the boxes
          of the services you wish to leave out.
        </p>
        <p className="my-3">
          <span className="font-bold">Audio file:</span> This is the most
          important, the reason we are here in the first place. This section is
          where you get to select the audio file for your track.
        </p>
        <p className="my-3">
          {" "}
          Audio files should be{" "}
          <span className="font-bold">WAV, MP3, M4A, FLAC, AIFF, </span>or{" "}
          <span className="font-bold">Windows Media (WMA).</span>
        </p>
        <p>
          There is no limitation to the length in minutes that a track can be. However,
          albums cannot contain songs where the average track length is less than 60 seconds.
        </p>
        <div className="my-3">
          <span className="font-bold">Songwriter/Composer/Producer:</span> For
          every original release, you will need to list the contributor’s real
          names. Typically, songwriters are listed using real names, not stage
          names or rapper names or band names. For example, Wizkid the song
          "Come Closer (feat Drake)" on Spotify. You would expect the artist
          names listed as:
          <ol className="my-3 list-decimal list-inside">
            <li>Wizkid</li>
            <li>Drake</li>
          </ol>
          <p className="my-3">
            However, if you look at the credits for that song on Spotify, you'll
            notice those artists' real names are listed as songwriters. Their
            real names are, respectively:
          </p>
          <ol className="my-3 list-decimal list-inside">
            <li>Ayodeji Balogun</li>
            <li>Aubrey Graham</li>
          </ol>
          <p className="my-3">
            These real names will be displayed when a listener views the
            "credits" of a song.
          </p>
        </div>
        <p className="my-3">
          <span className="font-bold">Explicit lyrics:</span> If your release
          has explicit lyrics you’ll need to indicate so in this section.
        </p>
        <p className="my-3">
          <span className="font-bold">Instrumental:</span> In this section you
          can indicate whether your song contains lyrics or is just an
          instrumental.
        </p>
        <p className="my-3">
          <span className="font-bold">Track Price:</span> Here you can indicate
          a custom track price for iTunes and Amazon. You can select $0.69,
          $0.99, or $1.29, just keep in mind that tracks over 10-minutes long
          will be priced higher.
        </p>
      </div>
    ),
  },

  {
    id: 11,
    header: <h1>What are UPC and ISRC codes?</h1>,
    body: (
      <div className="leading-6">
        <p>
          These are unique identifiers – an ISRC (International Standard Recording
          Code) identifies a specific recording, and a UPC (Universal Product
          Code) identifies a bundle, such as a single, EP, or album.
        </p>
        <p>
          These are required for every release, but don’t worry if you don’t already have
          them, as we will assign them free of charge. If you want us to assign
          them, just leave these fields blank when uploading your release.
        </p>
      </div>
    ),
  },

  {
    id: 12,
    header: <h1>How do I solve incorrect artist mapping on DSPs?</h1>,
    body: (
      <div className="leading-6 space-y-2">
        Unfortunately, this can occur from time to time. Occasionally, artists have similar
        or identical names, and DSPs may automatically place content under the incorrect profile.
      </div>
    )
  },

  {
    id: 13,
    header: <h1>My releases are on the wrong artist page for Spotify:</h1>,
    body: (
      <div className="leading-6 space-y-2">
        This may be the case from time to time. To correct this, you can use the
        <a href="https://support.spotify.com/uk/artists/contact-spotify-anonymous"
          className="text-link"
          target="_blank">
          {" "} contact form
        </a> or <a className="text-link"
          href="https://artists.spotify.com/c/content-mismatch" target="_blank">
          report
        </a> on Spotify for Artists for immediate assistance.
      </div>
    )
  },

  {
    id: 14,
    header: <h1>My releases are on the wrong artist page for Apple:</h1>,
    body: (
      <div className="leading-6 space-y-2">
        <p>
          This may be the case from time to time. To correct this, please reach out to Apple
          via Apple Music for Artists.
        </p>
        <p>
          If you need your artist page corrected on partners other than Spotify and Apple, please send us an email in this form (Below)
        </p>
        <ol className="list-inside list-decimal">
          <li>
            YourXDistroMusicAccountEmail*
          </li>
          <li>
            (Insertoneofthefollowing*)
            <ol className="ml-3 list-disc list-inside">
              <li>
                My or my artists' 1st release is listed on an incorrect profile
              </li>
              <li>
                My release/track is on an incorrect artist page (Not my artist page).
              </li>
              <li>
                My artist page has an incorrect release on it. (A release from someone other than me)
              </li>
            </ol>
          </li>
          <li>
            Specify which partners are affected?*
            <p className="ml-3">
              Tidal, Pandora, iHeartRadio, Napster, Deezer, or YouTube Music.
            </p>
          </li>
          <li>
            Release Details
            <ol className="list-disc list-inside ml-3">
              <li>
                UPC of Release
              </li>
              <li>
                Artist Name
              </li>
              <li>
                Partner Affected
              </li>
              <li>
                Correct artist page URL for Partner affected*
              </li>
              <li>
                Provide the URLs that do not belong to your artist on Partner affected*
              </li>
            </ol>
          </li>
        </ol>
      </div>
    )
  },
  {
    id: 15,
    header: <h1>When will I get paid?</h1>,
    body: <div className="leading-6 space-y-2">
      <p>
        We deposit royalties into your account as soon as we get them. Every month, royalties from key platforms including Spotify, Apple Music, iTunes, YouTube, and Amazon Music are paid directly into your XDistro Music account.
      </p>
      <p>
        Other stores can take up to 3-4 months to declare their sales. Rest assured, we will update your royalty balance as soon as we receive them.
      </p>
      <p>
        If you're having trouble withdrawing your available royalties, please open a
        <a className="text-link" href="https://support.xdistromusic.com/portal/en/newticket" target="_blank">
          {" "} support ticket
        </a>.
      </p>
    </div>,
  },

  {
    id: 16,
    header: <h1>Is There a Minimum Payout Threshold?</h1>,
    body: <div className="leading-6">Yes there is. The current payout threshold is $50</div>,
  },

  {
    id: 17,
    header: <h1>How do I add my royalty payout method?</h1>,
    body: (
      <div className="leading-6">
        <p>
          Before you can get the royalties you've earned, you'll need to set up your payout method. You can withdraw royalties directly to your bank account or to a PayPal account.
        </p>
        <p>
          Head to the Wallet section of your XDistro Music dashboard to add a payout method.
        </p>
        <p>
          Select the payout method you would like to add - PayPal or Bank Transfer.
        </p>
        <p>
          After your payout method is added, you can hit Withdraw Now to request a payout.
        </p>
      </div>
    ),
  },

  {
    id: 18,
    header: <h1>How much of my sales does XDistro Music keep?</h1>,
    body: (
      <div className="leading-6">
        XDistro Music keeps none! You'll keep 100% of the royalties you earn 🤑
      </div>
    ),
  },

  {
    id: 19,
    header: <h1>How do I upgrade my account?</h1>,
    body: (
      <div className="leading-6">
        Simply login to your XDistro Music account and navigate to account
        upgrade. Choose a higher plan you wish to upgrade to and proceed. All
        your previous releases will be intact and safe, your account will
        however be upgraded and additional features added.
      </div>
    ),
  },

  {
    id: 20,
    header: <h1>How do I change my password?</h1>,
    body: (
      <div className="leading-6">
        Go to xdistromusic.com and click "Sign In" --- "Forgot your password?"
        <p className="my-3">
          You will then be asked to enter the email you used when signing up and
          we'll send an email with steps to reset your password.
        </p>
      </div>
    ),
  },

  {
    id: 21,
    header: <h1>What is Playlist Support and how do I use it?</h1>,
    body: (
      <div className="leading-6 space-y-2">
        <p>
          All clients using our service can submit marketing drivers directly within the XDistro
          Music dashboard, streamlining playlist placements for your releases!
        </p>
        <p>
          Once you have created and submitted a release, you can head over to Playlist Support
          to submit marketing drivers. Click Submit Marketing Drivers to begin the submission.
        </p>
        <h1 className="font-bold">
          [Marketing Driver Requirements]
        </h1>
        <h1 className="font-bold">
          Focus Track:
        </h1>
        <p>
          If your release has more than one track (e.g., an EP or album), you will have to specify
          your focus track by labeling and uploading it. This will help our client marketing
          team determine which track to pitch to our partners. The focus track is often the most
          important track on your release that you would like XDistro Music to emphasize to partners.
        </p>
        <h1 className="font-bold">
          Driver submission timeframe:
        </h1>
        <p>
          Be sure to submit your drivers at least four weeks before your release.
          There may not be sufficient time to pitch your music if your release date is
          less than four weeks away.
        </p>
        <p>
          If you wish to be included in playlist pitching, you're welcome to edit your release date
          to four to six weeks in advance.
        </p>
        <h1 className="font-bold">
          Supply complete Artist Profile details:
        </h1>
        <p>
          You also need to submit a correct and complete artist profile as requested on the form.
        </p>
        <p>
          A complete artist profile will include quality artist profile photos and social
          media and DSP links and must be filled to continue.
        </p>
        <h1 className="font-bold">
          Marketing Details:
        </h1>
        <p>
          These details help us craft the best possible pitch for your music! You can add
          information about any of your planned marketing efforts by entering more details.
          The more information you can provide, the better. Click Submit once you’ve completed
          all required fields.
        </p>
      </div>
    ),
  },

  {
    id: 22,
    header: <h1>
      What happens if I use a service that promises more streams,
      or I play my own music on repeat?
    </h1>,
    body: (
      <div className="leading-6 space-y-2">
        <p>
          The platforms can tell if you're artificially increasing your streams.
          They won't pay you for them, and you risk having your music taken down.
        </p>
        <p>
          Services that provide "guaranteed" streams or playlist placements are not legitimate.
          You should be wary of the attractiveness of increasing vanity metrics because intentionally
          generated streams are a type of fraud. They also don't bring in new fans or listeners
          since bots don't have friends. The platforms may simply remove those streams
          from your account and pay only for those they believe are authentic, but they
          may also delete your music from their platforms. We will be unable to challenge
          these decisions on your behalf.
        </p>
        <p>
          "Any service that promises streams or playlist placement in exchange for money is
          deceiving you about its credibility. We never associate with services like that,
          and anyone who attempts to artificially inflate stream counts risks having their
          music removed. It's critical to understand that any interaction with one of these
          criminal actors jeopardizes your job" - Spotify
        </p>
        <p>
          Services that guarantee streams often rely on click farms or other unscrupulous
          practices to generate activity. Since streams are monetized, this is a form of
          fraud and may result in a “takedown” where digital streaming platforms remove your music.
        </p>
        <p>
          Similarly, services that guarantee playlist placements are often either involved in
          payola (paying for placement) or artificially inflating their own network of playlist
          followers with “listeners” who actually never listen to the playlist.
        </p>
        <p>
          See Spotify’s message about this issue below:
        </p>
        <p>
          As your trusted partner, we wanted to take a moment to warn you about some deceptive
          advertisements we’ve recently seen for illegitimate music promotion services.
        </p>
        <p>
          Third parties that promise playlist placements or a specific number of streams
          in exchange for compensation are likely using illegitimate practices without your
          knowledge. These services can threaten your hard work, resulting in the potential
          withholding of streams or royalties, or even complete removal of your catalog from
          streaming services.
        </p>
        <p>
          Our streaming partners work diligently to ensure streams are legitimate,
          meaning they reflect genuine user listening intent. If a service finds that you 
          (or a third party hired by you or on your behalf) have boosted play counts through any
          automated, deceptive, fraudulent or other invalid means (digital bots, “click farms”,
          payment for placement on playlists, etc.), the service may permanently remove your
          entire catalog.
        </p>
        <p>
          If you’re in the market for a music promotion service,
          make sure to do your research before you hire them.
        </p>
        <p>
          <a className="text-link" target={"_blank"}
            href="https://artists.spotify.com/help/article/third-party-services-that-guarantee-streams">
            https://artists.spotify.com/help/article/third-party-services-that-guarantee-streams
          </a>
          <a className="text-link" target={"_blank"}
            href="https://artists.spotify.com/blog/what-is-artificial-streaming-the-latest-episode-of-the-ga me-plan-explains">
            https://artists.spotify.com/blog/what-is-artificial-streaming-the-latest-episode-of-the-ga me-plan-explains
          </a>
        </p>
        <h1 className="font-bold">
          <em>DO NOT stream your own music on repeat</em>
        </h1>
        <p>
          This is also a type of fraud, even if done unintentionally. It is unethical to
          repeatedly stream your own music in order to increase play counts or streaming money.
        </p>
        <p>
          Of course, it's great to listen to your own songs every now and again, but if you leave
          your latest album on repeat for two days straight, streaming services will notice and may delete your music from their service. Do not do it!
        </p>
        <h1 className="font-bold">
          <em>A note about penalty charges:</em>
        </h1>
        <p>
          You have probably heard that Spotify will charge distribution companies a penalty fee
          if fake streaming activity is discovered on their content. XDistro Music is committed to
        </p>
        <p>
          ensuring that this punishment does not apply to non-offending artists who have not
          participated in this activity. XDistro Music's expenses for false streaming will have
          no effect on your legitimate income.
        </p>
      </div>
    ),
  },

];

export default text;
