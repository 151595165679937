import Table from "../Table/Table";
import { useDashboardContext } from "../Dashboard/DashboardContext";

interface IPlaylistRequest {
  artist_name: string;
  artist_bio: string;
  release_title: string;
  release_date: string;
  press_photos: string[];
  instagram_profile: string;
  facebook_profile: string;
  x_profile: string;
  youtube_profile: string;
  tiktok_profile: string;
  soundcloud_profile: string;
  website: string;
  focus_track: string;
  release_story: string;
  release_schedule: string;
  similar_artist: string;
  mood: string;
  attribute: string;
  interviews: string;
  tours: string;
  visual_assets: string;
  ad_campaign: string;
  radio_campaign: string;
  physical_product: string;
  press_release: string;
  marketing_plan: string;
  created_at: number;
  status: string;
}

function ListPlaylistSupportRequest() {
  const { playlistRequests } = useDashboardContext();
  const columns = [
    { title: "Artist", dataIndex: "artist_name", key: "1" },
    { title: "Release Title", dataIndex: "release_title", key: "2" },
    { title: "Release Date", dataIndex: "release_date", key: "3" },
    { title: "Track Mood", dataIndex: "mood", key: "4" },
    { title: "Data Created", dataIndex: "created_at", key: "5" },
    { title: "Status", dataIndex: "status", key: "6" },
  ];

  return (
    <div className="space-y-5">
      <h1 className="text-2xl">Playlist Support Requests</h1>
      <div className="overflow-auto">
        <Table
          columns={columns}
          data={playlistRequests.map((p) => ({
            ...p,
            release_date: new Date(p.release_date).toDateString(),
            created_at: new Date(p.created_at * 1000).toDateString(),
          }))}
        />
      </div>
    </div>
  );
}

export default ListPlaylistSupportRequest;
