/* eslint-disable import/no-anonymous-default-export */
export default () => {
    return (
        <svg
            style={{ width: "100%", height: "100%" }}
            viewBox="0 0 86 86"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_4733_19400)">
                <circle cx="42.7681" cy="39.4693" r="33.1226" fill="#FFBD59" />
                <circle
                    cx="42.7681"
                    cy="39.4693"
                    r="31.5253"
                    stroke="#FAF7F2"
                    strokeWidth="3.19465"
                />
            </g>
            <rect
                x="20.4551"
                y="17.1562"
                width="44.6294"
                height="44.6294"
                rx="11.4591"
                fill="#FFBD59"
            />
            <path
                d="M39.9982 39.9935L38.4327 48.6068C38.353 49.0455 38.9077 49.3045 39.1928 48.9616L48.8108 37.3938C49.0457 37.1113 48.8448 36.6829 48.4773 36.6829H45.1182C44.7371 36.6829 44.5413 36.2266 44.8039 35.9505L49.3138 31.2081C49.5765 30.9319 49.3807 30.4756 48.9996 30.4756H41.6044C41.4575 30.4756 41.3206 30.55 41.2406 30.6732L35.9607 38.8127C35.7736 39.1012 35.9807 39.4824 36.3245 39.4824H39.5716C39.8421 39.4824 40.0466 39.7274 39.9982 39.9935Z"
                fill="#1F1F1F"
            />
            <defs>
                <filter
                    id="filter0_d_4733_19400"
                    x="0.527597"
                    y="0.875933"
                    width="84.4809"
                    height="84.4809"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3.64716" />
                    <feGaussianBlur stdDeviation="4.55896" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.35 0 0 0 0 0.161718 0 0 0 0 0.025375 0 0 0 0.21 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_4733_19400"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_4733_19400"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
