/* eslint-disable import/no-anonymous-default-export */
export default () => {
  return (
    <svg
      style={{ width: "100%", height: "100%" }}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0894 8.67099V12.4969C13.0894 12.9231 12.9201 13.3318 12.6188 13.6332C12.3174 13.9345 11.9087 14.1038 11.4825 14.1038H2.6443C2.21811 14.1038 1.80938 13.9345 1.50802 13.6332C1.20666 13.3318 1.03735 12.9231 1.03735 12.4969V3.6587C1.03735 3.23251 1.20666 2.82378 1.50802 2.52242C1.80938 2.22106 2.21811 2.05176 2.6443 2.05176H5.9911"
        stroke="#1F1F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.698 0.736328H14.5188V5.55716"
        stroke="#1F1F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.05542 7.19938L14.5185 0.736328"
        stroke="#1F1F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
