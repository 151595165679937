import React from 'react'

interface Props {
  progress: string
  icons: React.ReactNode;
}

function Progress({progress, icons}: Props) {
  return (
    <div className='flex flex-shrink-0 gap-2 items-center'>
      <div className='h-[20px] w-[20px] flex flex-shrink-0'>{icons}</div>
      <div>{progress}</div>
    </div>
  )
}

export default Progress