import {
  ACRCloudIcon,
  AdaptrIcon,
  AlibabaIcon,
  AmazonMusicIcon,
  AmlIcon,
  AnghamiIcon,
  AppleMusicTextIcon,
  AudibleMagicIcon,
  AudiomackPlaneIcon,
  BeatDappIcon,
  BeatportIcon,
  BMatIcon,
  BoomplayMusicIcon,
  ClaroMusicaIcon,
  ClickNCLearIcon,
  DeezerIcon,
  DigitalIcon,
  DmusicIcon,
  ExpressInMusicIcon,
  FacebookIcon,
  GraceNoteIcon,
  HuaweiIcon,
  InstagramIcon,
  JaxstaIcon,
  JooxIcon,
  JunoDownloadIcon,
  KDigitalIcon,
  KKboxIcon,
  LineMusicIcon,
  ListenOnBadgeIcon,
  LiveXLiveIcon,
  MediaNetIcon,
  MixUploadIcon,
  MusicReportIcon,
  MusicTimeIcon,
  MusicWorxIcon,
  MXCloudIcon,
  NapsterIcon,
  NetEaseIcon,
  PandoraIcon,
  PelotonIcon,
  PexIcon,
  PretzelIcon,
  QobuzIcon,
  RessoIcon,
  SaavnIcon,
  SoundExchangeIcon,
  SpotifyTextIcon,
  TencentIcon,
  TidalTexticon,
  TiktokIcon,
  TouchTunesIcon,
  YouSeeIcon,
  YoutubeIcon,
  YouTubeMusicIcon,
} from "../components/Icons/Icons";
import IheartRadioIcon from "../components/Icons/IHeartRadio";

const text = [
  {
    id: 1,
    name: "Spotify",
    icon: <SpotifyTextIcon />,
  },

  {
    id: 2,
    name: "Apple Music",
    icon: <AppleMusicTextIcon />,
  },

  {
    id: 3,
    name: "Tidal",
    icon: <TidalTexticon />,
  },

  {
    id: 4,
    name: "Deezer",
    icon: <DeezerIcon />,
  },

  {
    id: 5,
    name: "Boomplay Music",
    icon: <BoomplayMusicIcon />,
  },

  {
    id: 6,
    name: "Pandora",
    icon: <PandoraIcon />,
  },

  {
    id: 7,
    name: "Audiomack",
    icon: <AudiomackPlaneIcon />,
  },

  {
    id: 8,
    name: "Amazon Music",
    icon: <AmazonMusicIcon />,
  },

  {
    id: 9,
    name: "Instagram",
    icon: <InstagramIcon />,
  },

  {
    id: 10,
    name: "Tiktok",
    icon: <TiktokIcon />,
  },

  {
    id: 11,
    name: "Youtube",
    icon: <YoutubeIcon />,
  },

  {
    id: 12,
    name: "Beatport",
    icon: <BeatportIcon />,
  },

  {
    id: 13,
    name: "Anghami",
    icon: <AnghamiIcon />,
  },

  {
    id: 14,
    name: "Huawei",
    icon: <HuaweiIcon />,
  },

  {
    id: 15,
    name: "IHeartRadio",
    icon: <IheartRadioIcon />,
  },

  {
    id: 16,
    name: "Facebook",
    icon: <FacebookIcon />,
  },

  {
    id: 17,
    name: "Saavn",
    icon: <SaavnIcon />,
  },

  {
    id: 18,
    name: "KKbox",
    icon: <KKboxIcon />,
  },

  {
    id: 19,
    name: "ClaroMusica",
    icon: <ClaroMusicaIcon />,
  },

  {
    id: 20,
    name: "YouTube Music",
    icon: <YouTubeMusicIcon />,
  },

  {
    id: 21,
    name: "Tencent",
    icon: <TencentIcon />,
  },

  {
    id: 22,
    name: "Joox",
    icon: <JooxIcon />,
  },

  {
    id: 23,
    name: "Jaxsta",
    icon: <JaxstaIcon />,
  },

  {
    id: 24,
    name: "MXCloud",
    icon: <MXCloudIcon />,
  },

  {
    id: 25,
    name: "NetEase",
    icon: <NetEaseIcon />,
  },

  {
    id: 26,
    name: "Napster",
    icon: <NapsterIcon />,
  },

  {
    id: 27,
    name: "Digital",
    icon: <DigitalIcon />,
  },

  {
    id: 28,
    name: "JunoDownload",
    icon: <JunoDownloadIcon />,
  },

  {
    id: 29,
    name: "SoundExchange",
    icon: <SoundExchangeIcon />,
  },

  {
    id: 30,
    name: "Pretzel",
    icon: <PretzelIcon />,
  },

  {
    id: 31,
    name: "YouSee",
    icon: <YouSeeIcon />,
  },

  {
    id: 32,
    name: "Adaptr",
    icon: <AdaptrIcon />,
  },

  {
    id: 33,
    name: "Alibaba",
    icon: <AlibabaIcon />,
  },

  {
    id: 34,
    name: "Line Music",
    icon: <LineMusicIcon />,
  },

  {
    id: 35,
    name: "Dmusic",
    icon: <DmusicIcon />,
  },

  {
    id: 36,
    name: "LiveXLive",
    icon: <LiveXLiveIcon />,
  },

  {
    id: 37,
    name: "ACRCloud",
    icon: <ACRCloudIcon />,
  },

  {
    id: 38,
    name: "MixUpload",
    icon: <MixUploadIcon />,
  },

  {
    id: 39,
    name: "BeatDapp",
    icon: <BeatDappIcon />,
  },

  {
    id: 40,
    name: "BMat",
    icon: <BMatIcon />,
  },

  {
    id: 41,
    name: "AudibleMagic",
    icon: <AudibleMagicIcon />,
  },

  {
    id: 42,
    name: "Aml",
    icon: <AmlIcon />,
  },

  {
    id: 43,
    name: "GraceNote",
    icon: <GraceNoteIcon />,
  },

  {
    id: 44,
    name: "MusicReport",
    icon: <MusicReportIcon />,
  },

  {
    id: 45,
    name: "TouchTunes",
    icon: <TouchTunesIcon />,
  },

  {
    id: 46,
    name:   "Pex",
    icon: <PexIcon />,
  },

  {
    id: 47,
    name: "Peloton",
    icon: <PelotonIcon />,
  },

  {
    id: 48,
    name: "Qobuz",
    icon: <QobuzIcon />,
  },

  {
    id: 49,
    name: "MediaNet",
    icon: <MediaNetIcon />,
  },

  {
    id: 50,
    name: "ExpressInMusic",
    icon: <ExpressInMusicIcon />,
  },

  {
    id: 51,
    name: "MusicWorx",
    icon: <MusicWorxIcon />,
  },

  {
    id: 52,
    name: "ClickNCLear",
    icon: <ClickNCLearIcon />,
  },

  {
    id: 53,
    name: "MusicTime",
    icon: <MusicTimeIcon />,
  },

  {
    id: 54,
    name: "ListenOnBadge",
    icon: <ListenOnBadgeIcon />,
  },

  {
    id: 55,
    name: "KDigital",
    icon: <KDigitalIcon />,
  },

  {
    id: 56,
    name: "Resso",
    icon: <RessoIcon />,
  },
];

export default text;
