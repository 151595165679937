import React from "react";
import { Link } from "react-router-dom";
import UpgradeButton from "./UpgradeButton";

function UpgradeBanner() {
  return (
    <div className="flex items-center text-base font-medium">
      <div className="bg-ProImg bg-no-repeat text-center h-[130px] p-5 bg-cover w-full">
        <Link to={"/dashboard/settings/subscriptions"}>
          <UpgradeButton
            className="rounded-[10px] items-baseline mt-12 text-sm  leading-4"
            color="bg-white"
            textColor="text-xd_yellow"
          >
            Get Pro Now
          </UpgradeButton>
        </Link>
      </div>
    </div>
  );
}

export default UpgradeBanner;
