function ArtistIcon() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.126 4.625C11.126 6.415 9.66598 7.875 7.87598 7.875C6.08598 7.875 4.62598 6.415 4.62598 4.625C4.62598 2.835 6.08598 1.375 7.87598 1.375C9.66598 1.375 11.126 2.835 11.126 4.625Z" stroke="#828282" strokeWidth="1.3" />
      <path d="M9.54598 18.465C9.01598 18.565 8.45598 18.625 7.87598 18.625C6.08598 18.625 4.48598 18.105 3.36598 17.305C2.23598 16.495 1.62598 15.455 1.62598 14.375C1.62598 13.295 2.23598 12.255 3.36598 11.445C4.49598 10.645 6.08598 10.125 7.87598 10.125C8.57598 10.125 9.24598 10.205 9.87598 10.355C10.416 10.485 10.916 10.655 11.376 10.865" stroke="#828282" strokeWidth="1.3" strokeLinecap="round" />
      <path d="M12.5558 17.7352C13.9862 17.7352 15.1458 16.5756 15.1458 15.1452C15.1458 13.7148 13.9862 12.5552 12.5558 12.5552C11.1254 12.5552 9.96582 13.7148 9.96582 15.1452C9.96582 16.5756 11.1254 17.7352 12.5558 17.7352Z" stroke="#828282" strokeWidth="1.3" />
      <path d="M15.146 15.145V4.875H18.496" stroke="#828282" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default ArtistIcon;
