import Xmark from "../../components/Icons/Xmark";
import RecieptsTable from "../../components/RecieptsTable/RecieptsTable";
import SearchInput from "../../components/SearchInput/SearchInput";
import SettingsHeader from "./SettingsHeader";

function Reciepts() {
  return (
    <div>
      <div>
        <SettingsHeader heading={"Receipts"} />
        <div className="inline-block my-8 md:hidden md:my-0 px-3">
          <SearchInput
            bgColor="bg-[#F2F2F2]"
            placeholder={"Input your keywords"}
            icon={<Xmark strokeColor="#4F4F4F" />}
          />
        </div>
        <div className="bg-white rounded-[10px]">
          <h1 className="p-4 text-2xl">Subscription Receipts</h1>
          <div className="overflow-x-scroll">
            <RecieptsTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reciepts;
