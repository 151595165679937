/* eslint-disable import/no-anonymous-default-export */
export default () => {
    return (
        <svg
            style={{ width: "100%", height: "100%" }}
            viewBox="0 0 86 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_4733_19434)">
                <circle cx="42.7681" cy="39.0699" r="33.1226" fill="#FFBD59" />
                <circle
                    cx="42.7681"
                    cy="39.0699"
                    r="31.5253"
                    stroke="#FAF7F2"
                    strokeWidth="3.19465"
                />
            </g>
            <rect
                x="19.6855"
                y="15.9873"
                width="46.1648"
                height="46.1648"
                rx="11.8533"
                fill="#FFBD59"
            />
            <rect
                x="31.8525"
                y="38.4717"
                width="5.91551"
                height="10.8829"
                fill="#1F1F1F"
            />
            <rect
                x="40.1172"
                y="33.6074"
                width="5.91551"
                height="15.7466"
                fill="#1F1F1F"
            />
            <rect
                x="48.3789"
                y="28.7441"
                width="5.91551"
                height="20.6101"
                fill="#1F1F1F"
            />
            <defs>
                <filter
                    id="filter0_d_4733_19434"
                    x="0.527597"
                    y="0.476519"
                    width="84.4809"
                    height="84.4809"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3.64716" />
                    <feGaussianBlur stdDeviation="4.55896" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.35 0 0 0 0 0.161718 0 0 0 0 0.025375 0 0 0 0.21 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_4733_19434"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_4733_19434"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
