import { useState } from "react";
import Input from "../Input/Index";
import Tooltip from "../Tooltip/Tooltip";
import IRelease, { IReleaseError } from "../../interfaces/release";
import { useDashboardContext } from "../Dashboard/DashboardContext";
// import Button from "../Button/Button";
// import CreateNewArtist from "../artist/create_artist_form";

interface Props {
  prevRelease?: IRelease;
  errors: IReleaseError;
}

function ReleaseForm({ prevRelease, errors }: Props) {
  const { artists, user } = useDashboardContext();
  const [selectedArtist, setSelectedArtist] = useState<string>(prevRelease?.release_artist?.id || "");
  // const [showNewArtistForm, setShowNewArtistForm] = useState(false);
  return (
    <div>
      <div className="lg:flex gap-10 ">
        <div className="basis-1/2 items-center">
          <Input
            className=""
            id="title"
            placeholder="Release Title"
            type="text"
            label="Release Title"
            value={prevRelease?.title}
            icon={<Tooltip value="Title or Name of the song" />}
            error={errors.Title}
          />
        </div>
        <div className="basis-1/2">
          <label
            htmlFor="release_artist_id"
            className="flex font-normal text-xs text-xd_grey my-3"
          >
            <div className="flex gap-2 items-center">
              Release Artist
              <Tooltip
                value="Provide the name(s) of the primary artist(s) on this release. If they are not in your roster, use the “Artists” menu to add them to your account. Do not enter Featuring or Remixer artists in this field." />
            </div>
          </label>
          <select
            id="release_artist_id"
            value={selectedArtist}
            className="p-4 items-center rounded-md focus:outline-xd_yellow border-xd_grey border-[1px] w-full font-normal text-xs"
            onChange={(e) => setSelectedArtist(e.target.value)}
          >
            <option value="">Select Artist</option>
            {artists.map((artist, index) => (
              <option
                key={index}
                value={artist.id}
              >
                {artist.name}
              </option>
            ))}
          </select>
          {/*
          {showNewArtistForm ? <CreateNewArtist /> : ""}
          {(
            <div className="flex lg:justify-end justify-center my-3">
              <Button
                disabled={showNewArtistForm}
                onClick={() => setShowNewArtistForm(true)}
              >
                Add New Artist
              </Button>
            </div>
          )}*/}
        </div>
      </div>

      <div className="lg:flex gap-10 my-5">
        <div className="basis-1/2">
          <Input
            className=""
            id="record_label"
            placeholder="Record Label"
            type="text"
            label="Record Label"
            icon={<Tooltip value="" />}
            value={prevRelease?.record_label || user.record_label}
            error={errors.RecordLabel}
          />
        </div>
        <div className="basis-1/2"></div>
      </div>

      <div className="lg:flex gap-10 my-5">
        <div className="basis-1/2">
          <Input
            className=""
            id="copyright_holder"
            placeholder="Copyright Holder"
            type="text"
            label="Copyright Holder"
            icon={<Tooltip value="" />}
            value={prevRelease?.copyright_holder || user?.first_name + " " + user?.last_name}
            error={errors.CopyrightHolder}
          />
        </div>
        <div className="basis-1/2">
          <Input
            className=""
            id="copyright_year"
            placeholder="Copyright Year"
            type="number"
            label="Copyright Year"
            icon={<Tooltip value="" />}
            value={prevRelease?.copyright_year || new Date().getFullYear()}
            error={errors.CopyrightYear}
          />
        </div>
      </div>

      <div className="my-5">
        <Input
          className=""
          id="upc_code"
          placeholder="UPC Code"
          type="text"
          label="UPC code"
          icon={<Tooltip value="" />}
          value={prevRelease?.upc_code}
          error={errors.UPCCode}
        />
      </div>
    </div>
  );
}

export default ReleaseForm;

