import React from "react";

interface Props {
  label: string;
  field?: React.ReactNode;
  textColor?: string;
}

function TrackReleaseDetailsField({ field, label, textColor }: Props) {
  return (
    <div>
      <div
        className={`opacity-50 text-stone-900 text-sm font-medium inline-block mr-3`}
      >
        {label}
      </div>
      <div
        className={`inline-block ${
          textColor ? textColor : ""
        } text-base font-medium`}
      >
        {field || "N/A"}
      </div>
    </div>
  );
}

export default TrackReleaseDetailsField;
