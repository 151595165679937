import React from "react";
import LayoutIcon from "../Icons/LayoutIcon";
import MenuIcon from "../Icons/MenuIcon";
import MiniSettingsIcon from "../Icons/MiniSettingsIcon";
import Xmark from "../Icons/Xmark";
import SearchInput from "../SearchInput/SearchInput";
import Select from "../Select/Select";

interface Props {
  toggleview: boolean;
  changeView: React.MouseEventHandler<HTMLDivElement>;
}

function SplitsTableHeader({ toggleview, changeView }: Props) {

  return (
    <div>
      <div className="md:flex items-center justify-between space-y-3">
        <strong className="font-semibold text-xl">Royalties</strong>
        <div className="flex gap-3 justify-between items-center">
          <div className="inline-block">
            {/*
            <Select options={["Owner"]} />
            <Select options={["Release"]} />
            */}
            <SearchInput
              placeholder={"Input your keywords"}
              icon={<Xmark strokeColor="#4F4F4F" />}
            />
          </div>
          <div className="inline-block">
            {/*
            <div className="bg-transparent hover:bg-transparent p-0">
              {<MiniSettingsIcon />}
            </div>
            */}
            <div
              className="bg-transparent hover:bg-transparent p-0 cursor-pointer"
              onClick={changeView}
            >
              {toggleview ? <MenuIcon /> : <LayoutIcon />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SplitsTableHeader;
