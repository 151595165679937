const data = [
    {
        id: "1",
        country: "United States of America",
        streams: "5,412",
    },

    {
        id: "2",
        country: "Nigeria",
        streams: "1,453",
    },

    {
        id: "3",
        country: "Ghana",
        streams: "320",
    },

    {
        id: "4",
        country: "Spain",
        streams: "450",
    },
]
export default data;