/* eslint-disable import/no-anonymous-default-export */
export default () => {
    return (
        <svg style={{ width: "100%", height: "100%" }} viewBox="0 0 80 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.2779 5.07227H25.8983C25.5986 5.07227 25.3437 5.29 25.2969 5.58586L23.5256 16.8165C23.4904 17.0381 23.662 17.2379 23.8868 17.2379H25.9777C26.2774 17.2379 26.5323 17.0201 26.579 16.7236L27.0567 13.6946C27.1028 13.3981 27.3584 13.1803 27.6574 13.1803H29.0439C31.9289 13.1803 33.5939 11.7843 34.0287 9.01775C34.2247 7.8074 34.0371 6.85641 33.4703 6.1904C32.8478 5.45906 31.7438 5.07227 30.2779 5.07227ZM30.7832 9.174C30.5437 10.7455 29.343 10.7455 28.1819 10.7455H27.521L27.9847 7.8106C28.0122 7.63321 28.1659 7.50257 28.3452 7.50257H28.6481C29.439 7.50257 30.1851 7.50257 30.5706 7.95341C30.8005 8.22238 30.8709 8.62198 30.7832 9.174Z" fill="#253B80" />
            <path d="M43.3694 9.124H41.2721C41.0935 9.124 40.9391 9.25464 40.9116 9.43203L40.8187 10.0186L40.6721 9.80602C40.218 9.14705 39.2056 8.92676 38.195 8.92676C35.8774 8.92676 33.898 10.6821 33.5124 13.1444C33.312 14.3727 33.597 15.5472 34.2937 16.3662C34.9328 17.1193 35.8473 17.4331 36.9354 17.4331C38.8028 17.4331 39.8383 16.2324 39.8383 16.2324L39.7448 16.8152C39.7096 17.038 39.8812 17.2378 40.1047 17.2378H41.9939C42.2942 17.2378 42.5478 17.0201 42.5952 16.7236L43.7287 9.54538C43.7645 9.32444 43.5936 9.124 43.3694 9.124ZM40.446 13.2059C40.2437 14.4041 39.2927 15.2084 38.0798 15.2084C37.4707 15.2084 36.984 15.0131 36.6715 14.6429C36.3616 14.2753 36.2437 13.7521 36.3424 13.1694C36.5313 11.9814 37.4983 11.1509 38.6926 11.1509C39.2882 11.1509 39.7723 11.3487 40.0912 11.7221C40.4108 12.0993 40.5376 12.6257 40.446 13.2059Z" fill="#253B80" />
            <path d="M54.5391 9.12402H52.4316C52.2305 9.12402 52.0416 9.22393 51.9276 9.39107L49.0208 13.6728L47.7887 9.55821C47.7112 9.30077 47.4737 9.12402 47.2047 9.12402H45.1336C44.882 9.12402 44.7071 9.36994 44.7872 9.60688L47.1086 16.4194L44.9262 19.5004C44.7545 19.7431 44.9274 20.0767 45.2239 20.0767H47.3289C47.5287 20.0767 47.7157 19.9794 47.8291 19.8154L54.8389 9.69718C55.0066 9.45511 54.8344 9.12402 54.5391 9.12402Z" fill="#253B80" />
            <path d="M61.5167 5.07227H57.1364C56.8373 5.07227 56.5824 5.29 56.5357 5.58586L54.7644 16.8165C54.7291 17.0381 54.9008 17.2379 55.1243 17.2379H57.3721C57.5808 17.2379 57.7595 17.0855 57.7922 16.878L58.2949 13.6946C58.341 13.3981 58.5965 13.1803 58.8956 13.1803H60.2814C63.167 13.1803 64.8314 11.7843 65.2669 9.01775C65.4635 7.8074 65.2745 6.85641 64.7078 6.1904C64.086 5.45906 62.9826 5.07227 61.5167 5.07227ZM62.022 9.174C61.7831 10.7455 60.5824 10.7455 59.4207 10.7455H58.7604L59.2247 7.8106C59.2523 7.63321 59.4047 7.50257 59.5846 7.50257H59.8875C60.6778 7.50257 61.4245 7.50257 61.81 7.95341C62.0399 8.22238 62.1097 8.62198 62.022 9.174Z" fill="#179BD7" />
            <path d="M74.6079 9.124H72.5119C72.3319 9.124 72.1789 9.25464 72.152 9.43203L72.0591 10.0186L71.9118 9.80602C71.4578 9.14705 70.446 8.92676 69.4354 8.92676C67.1178 8.92676 65.139 10.6821 64.7535 13.1444C64.5537 14.3727 64.8374 15.5472 65.5341 16.3662C66.1745 17.1193 67.0877 17.4331 68.1758 17.4331C70.0432 17.4331 71.0787 16.2324 71.0787 16.2324L70.9852 16.8152C70.95 17.038 71.1216 17.2378 71.3464 17.2378H73.2349C73.534 17.2378 73.7888 17.0201 73.8356 16.7236L74.9697 9.54538C75.0043 9.32444 74.8327 9.124 74.6079 9.124ZM71.6845 13.2059C71.4834 14.4041 70.5311 15.2084 69.3182 15.2084C68.7105 15.2084 68.2225 15.0131 67.91 14.6429C67.6 14.2753 67.4835 13.7521 67.5808 13.1694C67.771 11.9814 68.7367 11.1509 69.9311 11.1509C70.5267 11.1509 71.0108 11.3487 71.3297 11.7221C71.6506 12.0993 71.7773 12.6257 71.6845 13.2059Z" fill="#179BD7" />
            <path d="M77.0805 5.38094L75.2829 16.8171C75.2477 17.0387 75.4193 17.2385 75.6428 17.2385H77.45C77.7504 17.2385 78.0052 17.0208 78.0514 16.7243L79.824 5.49429C79.8592 5.27271 79.6876 5.07227 79.4641 5.07227H77.4404C77.2617 5.07291 77.108 5.20355 77.0805 5.38094Z" fill="#179BD7" />
            <path d="M5.33792 19.4206L5.67284 17.2932L4.92678 17.2759H1.36426L3.84003 1.57788C3.84771 1.53049 3.87269 1.4863 3.90919 1.45492C3.9457 1.42354 3.99244 1.40625 4.04111 1.40625H10.048C12.0422 1.40625 13.4184 1.82123 14.137 2.64029C14.4738 3.02453 14.6883 3.42606 14.7921 3.86793C14.901 4.33158 14.9029 4.88552 14.7966 5.56114L14.7889 5.61045V6.04336L15.1257 6.2342C15.4094 6.38469 15.6348 6.55696 15.8078 6.7542C16.0959 7.08272 16.2823 7.50026 16.3611 7.99529C16.4424 8.5044 16.4155 9.11022 16.2823 9.79608C16.1286 10.5851 15.8801 11.2722 15.5446 11.8345C15.2359 12.3525 14.8427 12.7823 14.3758 13.1153C13.9301 13.4316 13.4005 13.6718 12.8017 13.8255C12.2215 13.9766 11.56 14.0528 10.8344 14.0528H10.367C10.0327 14.0528 9.70798 14.1732 9.4531 14.389C9.19759 14.6093 9.02852 14.9103 8.97665 15.2395L8.94143 15.4309L8.3497 19.1805L8.3228 19.3181C8.31576 19.3617 8.30359 19.3835 8.28566 19.3982C8.26965 19.4116 8.2466 19.4206 8.22418 19.4206H5.33792Z" fill="#253B80" />
            <path d="M15.4443 5.66016C15.4264 5.77479 15.4059 5.89198 15.3829 6.01237C14.5907 10.0795 11.8805 11.4846 8.41918 11.4846H6.65681C6.23351 11.4846 5.87681 11.792 5.81085 12.2095L4.90853 17.9321L4.65301 19.5542C4.6101 19.8283 4.82144 20.0755 5.09809 20.0755H8.22386C8.59401 20.0755 8.90844 19.8065 8.96672 19.4415L8.99746 19.2827L9.58598 15.5479L9.62377 15.3429C9.6814 14.9766 9.99648 14.7077 10.3666 14.7077H10.8341C13.8626 14.7077 16.2333 13.4781 16.9262 9.92007C17.2157 8.43371 17.0658 7.19262 16.2999 6.31976C16.0681 6.05656 15.7805 5.83819 15.4443 5.66016Z" fill="#179BD7" />
            <path d="M14.6154 5.33023C14.4943 5.29501 14.3694 5.26299 14.2414 5.23418C14.1126 5.206 13.9807 5.18102 13.845 5.15925C13.3698 5.0824 12.8491 5.0459 12.2914 5.0459H7.58316C7.46725 5.0459 7.3571 5.07215 7.25848 5.11954C7.04139 5.22393 6.88001 5.4295 6.84094 5.68117L5.83936 12.0249L5.81055 12.21C5.87651 11.7925 6.23321 11.4851 6.65651 11.4851H8.41888C11.8802 11.4851 14.5904 10.0794 15.3826 6.0129C15.4062 5.8925 15.4261 5.77531 15.444 5.66068C15.2436 5.55437 15.0265 5.46344 14.7927 5.38595C14.7351 5.36674 14.6756 5.34817 14.6154 5.33023Z" fill="#222D65" />
            <path d="M6.84133 5.68139C6.8804 5.42971 7.04178 5.22414 7.25887 5.1204C7.35813 5.07301 7.46764 5.04675 7.58355 5.04675H12.2917C12.8495 5.04675 13.3702 5.08326 13.8453 5.16011C13.9811 5.18188 14.113 5.20685 14.2418 5.23503C14.3698 5.26385 14.4947 5.29587 14.6157 5.33109C14.6759 5.34902 14.7355 5.36759 14.7938 5.38617C15.0275 5.46365 15.2446 5.55523 15.4451 5.66089C15.6807 4.15788 15.4431 3.13453 14.6305 2.20788C13.7346 1.18773 12.1176 0.750977 10.0484 0.750977H4.04152C3.61886 0.750977 3.25832 1.05837 3.193 1.47655L0.690967 17.336C0.641657 17.6498 0.883726 17.9328 1.20008 17.9328H4.90862L5.83975 12.0252L6.84133 5.68139Z" fill="#253B80" />
        </svg>
    );
};



