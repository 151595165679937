import React, { useState } from "react";
import SplitTableContent from "./SplitsTableContent";
import SplitsTableHeader from "./SplitsTableHeader";

export default function RoyaltySplits() {
  const [toggleview, setToggleview] = useState(false);

  function handleClick() {
    setToggleview((toggleview) => !toggleview);
  }

  return (
    <div className="md:p-6 space-y-3 md:bg-white bg-transparent rounded-[10px] items-center flex-1">
      <SplitsTableHeader toggleview={toggleview} changeView={handleClick} />
      <div className="overflow-x-scroll">
        <SplitTableContent toggleview={toggleview} />
      </div>
    </div>
  );
}
