import React from "react";

interface Props {
  id: string;
  name?: string;
  type?: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  defaultChecked?: boolean;
  checked?: boolean;
}

const CheckBox: React.FC<Props> = ({
  id,
  name,
  type,
  className,
  onChange,
  value,
  defaultChecked,
  checked,
}) => {
  return (
    <div className={`${className}`}>
      <div>
        <input
          className={`text-xd_yellow border-xd_yellow rounded-full p-2 checked:font-bold
           checked:bg-CheckMarkIcon outline-none transition duration-200 align-top bg-no-repeat
            bg-center bg-contain float-left cursor-pointer ${className}`}
          type={type || "radio"}
          name={name}
          id={id}
          onChange={onChange}
          value={value}
          defaultChecked={defaultChecked}
          {...(checked ? { checked: true } : {})}
        />
      </div>
    </div>
  );
};

export default CheckBox;
