import React from 'react'
import InboxIcon from '../../Icons/InboxIcon'

interface Props {
    month: string;
    earned: string;
    toggleview: boolean;
}

function MonthlyRecords({ month, earned, toggleview }: Props) {
    return (
        <div>
            {
                toggleview ?
                    (<div>

                    </div>
                    )
                    :
                    (<div className='bg-white shadow-lg justify-between md:p-8 p-5 rounded-[10px] items-center flex'>
                        <div>
                            <div>{month}</div>
                            <div className='my-3'>{earned}</div>
                        </div>
                        <div className='w-[16px] h-[16px] flex-shrink-0'>
                            <InboxIcon />
                        </div>
                    </div>)
            }
        </div>

    )
}
;
export default MonthlyRecords