/* eslint-disable import/no-anonymous-default-export */
export default () => {
    return (
        <svg style={{ width: "100%", height: "100%" }} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4587 16.2525H5.99296C2.06445 16.2525 2.06445 14.1687 2.06445 12.324V11.6408C2.06445 10.1172 2.06445 8.39551 5.30974 8.39551C6.12277 8.39551 6.42339 8.59364 6.84699 8.90792C6.86748 8.92842 6.89481 8.94208 6.91531 8.96941L7.61219 9.70729C8.19976 10.329 9.26558 10.329 9.85315 9.70729L10.55 8.96941C10.5705 8.94892 10.591 8.92842 10.6184 8.90792C11.042 8.58681 11.3426 8.39551 12.1556 8.39551C15.4009 8.39551 15.4009 10.1172 15.4009 11.6408V12.324C15.3872 14.9339 14.0686 16.2525 11.4587 16.2525ZM5.30974 9.42034C3.08928 9.42034 3.08928 10.1172 3.08928 11.6408V12.324C3.08928 14.196 3.08928 15.2277 5.99296 15.2277H11.4587C13.4947 15.2277 14.3624 14.36 14.3624 12.324V11.6408C14.3624 10.1172 14.3624 9.42034 12.1419 9.42034C11.65 9.42034 11.5475 9.48183 11.2537 9.70046L10.591 10.4042C10.0991 10.9234 9.43639 11.2104 8.72584 11.2104C8.01529 11.2104 7.35257 10.9234 6.86065 10.4042L6.19793 9.70046C5.90414 9.48183 5.80166 9.42034 5.30974 9.42034Z" fill="#2F80ED" />
            <path d="M13.5081 9.4205C13.228 9.4205 12.9957 9.1882 12.9957 8.90808V4.80877C12.9957 3.28519 12.9957 2.58831 10.7753 2.58831H6.67595C4.45549 2.58831 4.45549 3.28519 4.45549 4.80877V8.90808C4.45549 9.1882 4.2232 9.4205 3.94308 9.4205C3.66296 9.4205 3.43066 9.1882 3.43066 8.90808V4.80877C3.43066 3.28519 3.43066 1.56348 6.67595 1.56348H10.7753C14.0206 1.56348 14.0206 3.28519 14.0206 4.80877V8.90808C14.0206 9.1882 13.7883 9.4205 13.5081 9.4205Z" fill="#2F80ED" />
            <path d="M10.0099 7.52776H7.73483C7.45471 7.52776 7.22241 7.29546 7.22241 7.01534C7.22241 6.73522 7.45471 6.50293 7.73483 6.50293H10.0099C10.2901 6.50293 10.5224 6.73522 10.5224 7.01534C10.5224 7.29546 10.2969 7.52776 10.0099 7.52776Z" fill="#2F80ED" />
            <path d="M10.5838 5.47795H7.16769C6.88757 5.47795 6.65527 5.24566 6.65527 4.96554C6.65527 4.68542 6.88757 4.45312 7.16769 4.45312H10.5838C10.8639 4.45312 11.0962 4.68542 11.0962 4.96554C11.0962 5.24566 10.8707 5.47795 10.5838 5.47795Z" fill="#2F80ED" />
        </svg>
    );
};