import Header from "../../components/Header/Header";
import SplitsTable from "../../components/SplitsTable/SplitsTable";

function RoyaltySplits() {
  return (
    <div>
      <div>
        <Header children="Royalty Splits" />
      </div>
      <div className="my-20">
        <SplitsTable />
      </div>
    </div>
  );
}

export default RoyaltySplits;
