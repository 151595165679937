import React from "react";
import Chart from "./Chart";
import ChartHeader from "./ChartHeader";
import TerritoryTable from "../Territory/TerritoryTable";
import ChartTable from "./ChartTable";

function InsightChart() {
  return (
    <div className="w-full h-full bg-white rounded-[10px] md:p-2 overflow-x-scroll">
      <div>
        <div>
          <ChartHeader />
        </div>
        <div className="flex gap-32 my-5">
          <div className="flex-shrink-0">
            <div className="w-full">
              <ChartTable />
            </div>
          </div>
          <div className="">
            <Chart />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InsightChart;
