import {ReactNode} from "react";

interface Props {
  header?: ReactNode;
  subheader?: ReactNode;
  background?: string;
  className?: string
  action?: ReactNode;
}

const Hero: React.FC<Props> = ({ header, subheader, background, action }) => {
  return (
    <div className={`${background || "bg-HeroImg"} h-screen w-full px-2 lg:px-32 py-24 bg-cover`} >
      <div className="h-full before:inline-block before:align-middle before:h-[100%] before:content-{' '}">
        <div className="inline-block align-middle w-full">
          <div className="flex flex-row px-6">
            <div className="lg:basis-1/3 md:basis-1/2 lg:w-full text-white">
              <h1 className="font-sans font-bold text-5xl text-center md:text-start max-w-full">
                {header}
              </h1>
              <div className="my-10">
                <p className="leading-[27px] text-center md:text-start">
                  {subheader}
                </p>
              </div>
              {action}
              {/*buttonText ?
                <div>
                  <Link to={action} className="flex justify-center md:justify-start">
                    <Button color="bg-white">{buttonText}</Button>
                  </Link>
                </div> : ""*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Hero;

