import React from 'react'
import TrackImg from "../../assets/TrackImg.png";

interface Props {
    country: string;
  }

function Territories({country}:Props) {
    return (
        <div className="flex items-center">
            <div className="flex-shrink-0 w-[50px] h-[50px]">
                <img className="w-full h-full" src={TrackImg} alt="" />
            </div>
            <div className="ml-3">
                <p className="text-gray-900 whitespace-no-wrap">
                    <span>{country}</span>
                </p>
            </div>
        </div>
    )
}

export default Territories