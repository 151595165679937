import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import Datedata from "../ReleaseTable/Datedata";
import Table from "../Table/Table";
import MySplits from "./MySplits";
import Splitrelease from "./SplitRelease";
import { IContributor, Track } from "../../interfaces/release";
import { useDashboardContext } from "../Dashboard/DashboardContext";
import SplitGridViewContent from "./SplitGridViewContent";
import RoyaltySplitActions from "./RoyalSplitActions";
//import { getReleaseStatus } from "../../data/getReleaseStatus";
//import sendRequest from "../axios";
//import moment from "moment";

interface Props {
  toggleview: boolean;
}

function GetSplitsTableData(
  data: Track[]
): { [index: string]: React.ReactNode }[] {
  // let TableData: { [index: string]: React.ReactNode; }[] = []
  // Convert Data to TableData
  const { artistMap } = useDashboardContext();
  return data.map((d) => {
    //console.log(d);
    let myContributor: IContributor | undefined = d.contributors.find(contributor => artistMap[contributor.artist_id]);
    return {
      id: d.id,
      release: <Splitrelease image={d.cover_art} track={d.title} />,
      artist: d.release_artist,
      date: <Datedata UploadDate={new Date(d.created_at * 1000)} />,
      contributors: d.contributors.flatMap((contributor) => contributor.name).join(", "),
      //my_role: myContributor?.name,
      my_splits: <MySplits split={myContributor?.split || 0} />,
      // status: getReleaseStatus(d.status),
      action: (
        <RoyaltySplitActions track={d} />
      ),
    };
  });
}

function SplitTableContent({ toggleview }: Props) {
  const { tracks } = useDashboardContext();
  const columns = [
    { title: "Releases", dataIndex: "release", key: "1" },
    { title: "Artist", dataIndex: "artist", key: "2" },
    { title: "Date created", dataIndex: "date", key: "3" },
    { title: "Contributors", dataIndex: "contributors", key: "4" },
    //{ title: "My Role", dataIndex: "my_role", key: "5" },
    { title: "My Split", dataIndex: "my_splits", key: "6" },
    { title: "", dataIndex: "action", key: "7" },
  ];
  return (
    <div className="">
      <div className="inline-block min-w-full">
        {toggleview ? (
          <Table columns={columns} data={GetSplitsTableData(tracks)} />
        ) : (
          <div className="">
            <div className="grid grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-4 md:gap-6 items-start">
              {tracks.map((track, i) =>
                <div key={i}> <SplitGridViewContent track={track} /> </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SplitTableContent;
