import { Radio, RadioGroup } from "@headlessui/react";
import React from "react";
import { useEffect, useMemo, useState } from "react";
import { PuffLoader } from "react-spinners";
import ISubscriptionPlan from "../../interfaces/subplan";
import sendRequest from "../axios";
import Price from "./Price";


const currencies = [
  { label: "Naira", value: "NGN" },
  { label: "US Dollar", value: "USD" },
]

const Index = () => {
  const locale = navigator.language;
  const [plans, setPlans] = useState<ISubscriptionPlan[]>([]);
  const [plansLoading, setPlansLoading] = useState(true);
  const [currency, setCurrency] = useState(locale === "en-NG" ? currencies[0] : currencies[1]);


  const getPlans = (currency: string) => {
    setPlansLoading(true);
    sendRequest("GET", `/api/v1/subscriptionplans?currency=${currency}`, null).then(res => {
      console.log({ plans: res.data.data });
      setPlans(res.data.data);
    }).finally(() => {
      setPlansLoading(false);
    });
  }

  // useEffect(() => {
    // get user's locale
    // const locale = navigator.language;
    // if (locale === "en-NG") {
    //   setCurrency(currencies[0]);
    // } else {
    //   setCurrency(currencies[1]);
    // }
    
  // }, [setCurrency, currencies]);

  useMemo(() => {
    if (currency) {
      getPlans(currency.value);
    }
  }, [currency]);

  return (
    <div className="my-2 container mx-auto">
      <h1 className="font-bold text-center text-4xl">Choose Your Plan</h1>
      <div className="px-10 text-center font-medium text-lg text-xd_gray">
        Take control of your music, select from our diverse plans.
      </div>
      {/*
      <div className="mt-16 flex justify-center">
        <fieldset aria-label="Payment frequency">
          <RadioGroup
            value={currency}
            onChange={setCurrency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs/5 font-semibold ring-1 ring-inset ring-gray-200"
          >
            {currencies.map((option) => (
              <Radio
                key={option.value}
                value={option}
                className="cursor-pointer rounded-full px-2.5 py-1 text-gray-500 data-[checked]:bg-xd_yellow data-[checked]:text-white"
              >
                {option.label}
              </Radio>
            ))}
          </RadioGroup>
        </fieldset>
      </div>
      */}
      <div>
      {plansLoading ?
        <div className="my-10">
          <PuffLoader className="mx-auto h-4 w-4" size={30} color="black" />
        </div> :
        <div className="my-10 md:grid grid-cols-2 gap-10 w-full justify-center space-y-4 md:space-y-0">
          {plans.map((p, index) => {
            return (
              <Price index={index} key={index} subscription_plan={p} />
            );
          })}
        </div>}
        </div>
    </div>
  );
};

export default Index;
