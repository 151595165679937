import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import Input from "../components/Input/Index";
import Button from "../components/Button/Button";
import PuffLoader from "react-spinners/PuffLoader";
import sendRequest from "../components/axios";

function ResetPassword() {
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const [apiMessage, setApiMessage] = useState<string>("");
  const [apiError, setApiError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [title] = useState<string>(searchParams.get("title") || "");
  const [message] = useState<string>(searchParams.get("message") || "");

  const { handleSubmit, resetForm, getFieldProps, errors, touched } = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
      token: token,
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Required"),
      confirm_password: Yup.string().oneOf([Yup.ref("password")], "Passwords must match"),
      token: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      setApiMessage("");
      setApiError("");
      resetForm();
      sendRequest("POST", "/api/v1/reset-password", values)
        .then((res) => {
          // console.log({ res });
          setApiMessage(res.data.message);
          resetForm();
        }).catch((err) => {
          // console.log({ err });
          if (err.response?.data?.errors) {
            setApiError(err.response.data?.errors);
            return;
          }
          setApiError("An error occurred");
        }).finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <AuthLayout>
      <h1 className="text-3xl md:text-big">{title ? title : "Reset Password"}</h1>
       <p className="text-xd_gray font-normal text-sm my-2">
        {message ? message : "Create a new password"}
      </p> 
      <div className="mt-7">
        {apiMessage ? (
          <p className="text-green-700 font-normal text-sm">{apiMessage}</p>
        ) : (
          ""
        )}
        {apiError ? (
          <p className="text-xd_red font-normal text-sm">{apiError}</p>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit}>
          <div className="">
            <Input
              className=""
              id="password"
              placeholder="Password"
              type="password"
              label="Password"
              {...getFieldProps("password")}
              error={errors.password && touched.password ? errors.password : ""}
            />
          </div>
          <div className="">
            <Input
              className=""
              id="confirm_password"
              placeholder="Confirm Password"
              type="password"
              label="Confirm Password"
              {...getFieldProps("confirm_password")}
              error={errors.confirm_password && touched.confirm_password ? errors.confirm_password : ""}
            />
          </div>
          <div className="mt-5">
            <Button disabled={loading} type="submit" className="w-full">
              {loading ? <PuffLoader className="h-4 w-4" size={30} color="white" /> : "Submit "}
            </Button>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
}

export default ResetPassword;
