import Input from "../Input/Index";
import Select from "../Select/Select";

function ChartHeader() {
  return (
    <div className="flex justify-between items-center py-3 md:px-10">
      <div>
        <div className="rounded-[10px] align-middle items-center">
          <div className="flex gap-10">
            <div>
              <span className="mx-1">Date Range:</span>{" "}
              <div className="inline-block">
                <div className="">
                  <Input
                    placeholder={""}
                    type={"date"}
                    inputClassName="h-10 bg-[#F2F2F2]"
                  />
                </div>
              </div>
            </div>
            <div>
              <span className="mx-1">Partner:</span>{" "}
              <div className="inline-block">
                <Select options={[{value:"all", label:"All"}]} bgColor="h-8 rounded-md" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50">
        <span className="mx-1 ">Streams:</span>{" "}
        <div className="inline-block font-semibold">216,913</div>
      </div>
    </div>
  );
}

export default ChartHeader;
