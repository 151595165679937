import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ITab from "../../interfaces/tab";
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  defaultPage: string;
  pageTitle: string;
  tabs: { [index: string]: ITab };
}

const Tab = ({ tabs, pageTitle, defaultPage }: Props) => {
  // search params are values from the url query string
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTabKey, setActiveTabKey] = useState("");

  useEffect(() => {
    const page = searchParams.get(pageTitle);
    if (page) {
      setActiveTabKey(page || defaultPage);
    }
  }, [searchParams]);

  const tabarray: ITab[] = [];

  for (const [key, value] of Object.entries(tabs)) {
    tabarray.push(value);
  }

  return (
    <div className="container mx-auto my-5">
      <div className="lg:px-56 px-2">
        <nav
          className="border-b border-gray-200 -mb-px flex justify-evenly"
          aria-label="Tabs"
        >
          {tabarray.map((tab) => {
            const active = searchParams.get(pageTitle) === tab.id;
            return (
              <span
                key={tab.name}
                className={classNames(
                  active
                    ? "border-xd_yellow text-xd_yellow"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  " w-full py-4 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
                )}
                aria-current={active ? "page" : undefined}
                onClick={() => {
                  // TODO: we might need to set this in one place so we can use only on variable
                  if (tab.shouldSwitch && tab.shouldSwitch()) {
                    // console.log("should switch");
                    setSearchParams((prev) => {
                      prev.set(pageTitle, tab.id);
                      return prev;
                    });
                  }
                }}
              >
                {tab.name}
              </span>
            );
          })}
        </nav>
      </div>
      <div className="">{activeTabKey && tabs[activeTabKey].content}</div>
    </div>
  );
};

export default Tab;
