/* eslint-disable import/no-anonymous-default-export */
interface Props {
    strokeColor?: string
}
export default ({ strokeColor }: Props) => {
    return (
        <svg width="4" height="17" viewBox="0 0 4 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.452637 2.36914C0.452637 3.22373 1.14541 3.9165 2 3.9165C2.85459 3.9165 3.54736 3.22373 3.54736 2.36914C3.54736 1.51456 2.85459 0.821777 2 0.821777C1.14541 0.821777 0.452637 1.51456 0.452637 2.36914Z" fill="#1F1F1F" />
            <path d="M0.452637 8.46387C0.452637 9.31845 1.14541 10.0112 2 10.0112C2.85459 10.0112 3.54736 9.31845 3.54736 8.46387C3.54736 7.60928 2.85459 6.9165 2 6.9165C1.14541 6.9165 0.452637 7.60928 0.452637 8.46387Z" fill="#1F1F1F" />
            <path d="M0.452637 14.5586C0.452637 15.4132 1.14541 16.106 2 16.106C2.85459 16.106 3.54736 15.4132 3.54736 14.5586C3.54736 13.704 2.85459 13.0112 2 13.0112C1.14541 13.0112 0.452637 13.704 0.452637 14.5586Z" fill="#1F1F1F" />
        </svg>
    );
};