import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Button from "../Button/Button";
import Modal from "../modal";
import NewReleaseForm from "../Release/NewReleaseForm";
import { useCreateReleaseContext } from "../Release/CreateReleaseContext";
import IRelease from "../../interfaces/release";
import { useDashboardContext } from "../Dashboard/DashboardContext";

interface Props {
  header?: React.ReactNode;
  subheader?: React.ReactNode;
  buttonText?: string;
  background?: string;
  className?: string;
}

const UploadBanner = ({ header, subheader, buttonText }: Props) => {
  const createReleaseModalQuery = "createreleasemodal";
  const { setRelease, updateNewRelease } = useCreateReleaseContext();
  const { getReleases } = useDashboardContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState(searchParams.get(createReleaseModalQuery) === "true");

  useEffect(() => {
    if (openModal) {
      setSearchParams(params => {
        params.set(createReleaseModalQuery, "true");
        params.set("tabpage", "release-details");
        return params;
      });
    } else {
      setSearchParams((prev) => {
        prev.delete(createReleaseModalQuery);
        prev.delete("tabpage");
        return prev;
      });
    }
  }, [openModal]);

  function showModal() {
    setRelease({} as IRelease);

    updateNewRelease("release-details", new FormData());
    updateNewRelease("release-track-list", new FormData());
    updateNewRelease("release-distribution", new FormData());

    setOpenModal(true);
  }

  function closeModal() {
    setOpenModal(false);
  }

  return (
    <div className="bg-UploadBgImg bg-cover bg-center min-h-full rounded-lg p-8 md:p-16 w-full bg-no-repeat">
      <Modal open={openModal} closeModal={closeModal} openModal={showModal}>
        <NewReleaseForm callback={() => {
          getReleases();
          closeModal();
        }} />
      </Modal>
      <div className="w-full text-white ">
        <h1 className="font-sans font-bold md:text-6xl text-3xl">{header}</h1>
        <div className="py-4">
          <p className="text-xs font-medium">{subheader}</p>
        </div>
        <div className={`${buttonText ? "" : "hidden"}`}>
          <Button color="bg-white" onClick={showModal}>
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UploadBanner;
