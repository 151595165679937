import { Navigate, Outlet } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";

const PrivateRoutes = () => {
  // TODO: do auth check here
  const token = localStorage.getItem("token");
  // console.log({token})
  return token ? <>
    <Dashboard>
      <Outlet />
    </Dashboard>
  </>
    : <Navigate to="/login" />;
};

export default PrivateRoutes;
