import React from "react";
import Question from "./Question";
import faqs from "../../data/faq"

function FaqMainList() {
  return (
    <div className="font-sans text-black">
      <div className="justify-center items-center">
        {faqs.map((text,i) => {
          return (
            <div className="mt-7" key={i}>
            <Question key={text.id} Header={text.header} children={text.body} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FaqMainList;
