import { getReleaseStatus } from "../../data/getReleaseStatus";
import TrackImg from "../../assets/TrackImgLg.png";
import XPieChart from "../PieChart/PieChart";
import { Track } from "../../interfaces/release";
import moment from "moment";
import { useDashboardContext } from "../Dashboard/DashboardContext";
import RoyaltySplitActions from "./RoyalSplitActions";
// import Action from "../Action/Action";

interface Props {
  track: Track;
}

function SplitGridViewContent({ track }: Props) {
  const { artistMap } = useDashboardContext();
  return (
    <div className="my-5">
      <div className="bg-white border border-[#F2F2F2] shadow-md rounded-lg p-2 w-full space-y-4">
        <div className="relative min-h-full">
          <img
            src={track.release?.cover_art ? track.release.cover_art : TrackImg}
            alt=""
            className="w-full h-full rounded"
          />
          <div className="absolute top-1 items-center w-full pt-2 text-center">
            {getReleaseStatus(track.release?.status || "draft")}
          </div>
        </div>
        <div className="my-3 text-sm font-medium flex justify-between">
          <p className="text-ellipsis">{track.title}</p>
          <div className="">
            <RoyaltySplitActions track={track} />
          </div>
        </div>
        <div className="text-xs font-normal">{track.release_artist}</div>
        <div className="text-xd_lightblack text-xs flex justify-between flex-shrink-0 items-center">
          RD: {moment.unix(track.created_at).format("LL")}
          <div className="h-[23px]">
            <XPieChart startAngle={90} split={track.contributors.find(contributor => artistMap[contributor.artist_id])?.split || 0} />
          </div>
        </div>
      </div>
    </div>
  );
}


export default SplitGridViewContent;
