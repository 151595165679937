const languages = [
  "English",
  "Spanish",
  "French",
  "German",
  "Russian",
  "Abkhazia",
  "Afar",
  "Afrikaans",
  "Akan",
  "Albanian",
  "Amharic",
  "Arabic",
  "Aragones",
  "Armenian",
  "Assamese",
  "Avaric",
  "Avestan",
  "Aymara",
  "Azerbajan",
  "Bambara",
  "Bashkir",
  "Basque",
  "Belarusian",
  "Bengali",
  "Binari",
  "Bislama",
  "Bokmavi",
  "Bosnian",
  "Breton",
  "Bulgarian",
  "Bashkir",
  "Basque",
  "Belarusian",
  "Bengali",
  "Bihari",
  "Bislama",
  "Bokmavi",
  "Bosnian",
  "Breton",
  "Bulgarian",
  "Catalan",
  "Central",
  "Khmer",
  "Chamorro",
  "Chechen",
  "Chichewa",
  "Chinese",
  "Church",
  "Slavic",
  "Chuvash",
  "Cornish",
  "Corsican",
  "Cree",
  "Croatian",
  "Czech",
  "Danish",
  "Divehi",
  "Maldivian",
  "Dutch",
  "Dzongkha",
  "Esperanto",
  "Estonian",
  "Ewe",
  "Faroese",
  "Fijian",
  "Finnish",
  "Fulah",
  "Gaelic",
  "Galician",
 "Ganda",
 "Georgian",
 "Greek",
 "Greenlandic ",
 "Guarani ",
 "Gujarati ",
 "Haitian ",
 "Haitian ",
 "Creole ",
 "Hausa ",
 "Hebrow ",
 "Herero",
 "Hindi",
 "Hiri",
 "Motu",
 "Hungarian",
 "Icelandic",
 "Ido",
 "Igbo",
 "Indonesian",
 "Interlingua",
 "Interlingue",
 "Inuktitut",
 "Inupiaq",
 "Irish",
 "Japanese ",
 "Javanese ",
 "Kannada ",
 "Kalaallisut ",
 "kanuni",
 "Kashmiri ",
 "Kazakh ",
 "Kikuyu: Gikuyu",
 "Kinyarwanda ",
 "Kirghiz",
 "Kyrgyz",
 "Komi",
 "Kongo",
 "Korean",
 "Kuanyama. Kwanyama",
 "Kurdish",
 "Lac ",
 "Latin",
 "Latvian ",
 "Flemish",
 "Limburgan ",
 "Lingala ",
 "Lithuanian",
 "Luba-Katanga",
 "Luxembourgish",
 "Letzeburgesch",
 "Macedonian",
 "Malagasy",
 "Malay",
 "Malayalam",
 "Maltese",
 "Manx",
 "Maori",
 "Marathi",
 "Marshallese",
 "Modem",
 "Moldovan",
 "Mongolian",
 "Nauru",
 "Navajo: Navaho",
 "Ndebele",
 "Ndonga",
 "Nepali",
 "Norwegian",
 "Nynorsk",
 "Ojibwa",
 "Oriya",
 "Oromo",
 "Occidental",
 "Ossetian",
 "Ossetic",
 "Pali",
 "Panjabi",
 "Punjabi",
 "Persian",
 "Poush",
 "Portuguese",
 "Quechua",
 "Romanian",
 "Romansh",
 "Rundi",
 "Samoan",
 "Sango",
 "Sardiniart",
 "Sanskrit ",
 "Scottish Gaelic",
 "Serbian",
 "Shona",
 "Sichuan",
 "Sindhi",
 "Sinhalese",
 "Slovak",
 "Somali",
 "Somali",
 "Sotho",
 "Sudanese",
 "Swahili",
 "Swati",
 "Swedish",
 "Tagalog",
 "Tahitian",
 "Tajik",
 "Tamil",
 "Tatar",
 "Telugu",
 "Thai",
 "Tibetan",
 "Tigrinya",
 "Tonga",
 "Tsonga",
 "Tswana",
 "Turkish",
 "Turkmen",
 "Twi",
 "Uighur",
 "Ukrainian",
 "Urdu",
 "Uzbek",
 "Valencian",
 "Venda",
 "Vietnamese",
 "VolapÅk",
 "Walloon",
 "Welsh",
 "Western",
 "Frisiant",
 "Wolof",
 "Xhosa",
 "Yiddish",
 "Yoruba",
 "Zhuang, Chuang",
 "Zulu",
 ]

export default languages;
