import React, { useEffect, useState } from "react";
import ReleaseForm from "../ReleaseForm/ReleaseForm";
import CoverArt from "../CoverArt/CoverArt";
import IRelease, { TrackDetails } from "../../interfaces/release";
import { useCreateReleaseContext } from "./CreateReleaseContext";
import Tooltip from "../Tooltip/Tooltip";
import languages from "../../data/languages";
import { toast } from "react-toastify";
import CheckBox from "../CheckBox/CheckBox";
import { useDashboardContext } from "../Dashboard/DashboardContext";

interface ISize {
  height: number;
  width: number;
}

// TODO: move this into a package
function getImageDimensions(f: any) {
  var _URL = window.URL || window.webkitURL;
  const promise = new Promise<ISize>((resolve, reject) => {
    let img = new Image();
    img.onload = () => {
      // Natural size is the actual image size regardless of rendering.
      // The 'normal' `width`/`height` are for the **rendered** size.
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      // Resolve promise with the width and height
      resolve({ width, height });
    };

    // Reject promise on error
    img.onerror = reject;
    // Setting the source makes it start downloading and eventually call `onload`
    img.src = _URL.createObjectURL(f);
  });

  return promise;
}

interface Props {
  error: string;
  prevRelease?: IRelease;
}

function ReleaseDetailForm({ error, prevRelease }: Props) {
  const { user } = useDashboardContext();
  const {
    newRelease: r,
    release,
    updateNewRelease,
    genres,
  } = useCreateReleaseContext();
  const [selectedGenre, setSelectedGenre] = useState(release?.genre_id);
  const [selectedLanguage, setSelectedLanguage] = useState(release?.language);
  const [apiError, setApiError]: [any, React.Dispatch<any>] = useState({});

  function createRelease(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
  }

  useEffect(() => {
    const releaseFormData = r["release-details"];
    releaseFormData.set("record_label", user.record_label || "");
    releaseFormData.set("copyright_holder", `${user.first_name + " " + user.last_name}`);
    releaseFormData.set("copyright_year", `${new Date().getFullYear()}`);
    updateNewRelease("release-details", releaseFormData);
  }, [user]);

  useEffect(() => {
    if (release.id) {
      const releaseFormData = r["release-details"];
      releaseFormData.set("release_id", release.id);
      releaseFormData.set("title", release.title);
      releaseFormData.set("release_artist_id", release.release_artist_id);
      releaseFormData.set("record_label", release.record_label || "");
      releaseFormData.set("copyright_holder", `${release.copyright_holder}`);
      releaseFormData.set("copyright_year", `${release.copyright_year}`);
      releaseFormData.set("upc_code", release.upc_code);
      releaseFormData.set("genre_id", `${release.genre?.id}`);
      releaseFormData.set("language", `${release.language}`);
      updateNewRelease("release-details", releaseFormData);
    }
  }, [release]);

  useEffect(() => {
    if (error) setApiError(error);
  }, [error]);

  const releaseFields = [
    "title",
    "release_artist_id",
    "record_label",
    "copyright_holder",
    "copyright_year",
    "production_holder",
    "production_year",
    "upc_code",
    "genre_id",
    "language",
    "cover_art",
  ];

  let validateCoverArt = async (files: FileList) => {
    if (!files || files.length < 1) {
      alert("no files");
      return false;
    }
    const f = new File([files[0]], files[0].name, { type: files[0].type });
    var size = await getImageDimensions(f);
    return size.width === 3000 && size.height === 3000;
  }

  function onChange(e: React.FormEvent<HTMLFormElement>) {
    var { id, name, value, type, checked, files }: any = e.target;
    if (!releaseFields.includes(id)) {
      console.log(`${id} not in release fields`);
      return;
    }
    switch (type) {
      case "number":
        value = Number(value);
        r["release-details"].set(id, value);
        break;
      case "file":
        validateCoverArt(files).then(res => {
          if (res) {
            //readFileAsDataURL(files[0]).then((fileBase64) => {
            //console.log({ fileBase64 });
            value = files[0];
            r["release-details"].append(id, value, value.name);
            // console.log(value);
            //}).catch(() => {
            //toast.error("could not upload image");
            //});
          } else {
            toast.error("image size is not 3000x3000 px");
          }
        }).catch(err => {
          console.log({ err })
        });
        break;
      default:
        r["release-details"].set(id, value);
        break;
    }
    updateNewRelease("release-details", r["release-details"]);
  }

  return (
    <div className="px-5 lg:px-40 my-10">
      <form onChange={onChange} onSubmit={createRelease}>
        {/* TODO: create notification banner <p className="text-red-800">{apiMessage}</p> */}
        <ReleaseForm prevRelease={release} errors={apiError} />
        <div>
          {/* <div className="font-medium">Genre</div> */}
          <div className="lg:flex gap-10 ">
            <div className="basis-1/2">
              <label
                htmlFor="genre_id"
                className="flex font-normal text-xs text-xd_grey my-3"
              >
                <div className="flex gap-2 items-center">
                  Genre
                  <Tooltip value="Genre of the song" />
                </div>
              </label>
              <select
                id="genre_id"
                value={selectedGenre}
                onChange={(e) => setSelectedGenre(e.target.value)}
                className="p-4 items-center rounded-md focus:outline-xd_yellow border-xd_grey border-[1px] w-full font-normal text-xs"
              >
                <option value="">Select Genre</option>
                {genres.map((genre, index) => (
                  <option key={index} value={genre.id}>
                    {genre.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="basis-1/2">
              <label
                htmlFor="language"
                className="flex font-normal text-xs text-xd_grey my-3"
              >
                <div className="flex gap-2 items-center">
                  Language
                  <Tooltip value="Enter the language of the release and track title language" />
                </div>
              </label>
              <select
                id="language"
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.target.value)}
                className="p-4 items-center rounded-md focus:outline-xd_yellow border-xd_grey border-[1px] w-full font-normal text-xs"
              >
                <option value="">Select Language</option>
                {languages.map((language, index) => (
                  <option key={index} value={language}>
                    {language}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <br />
        <CoverArt validate={validateCoverArt} prevImage={release?.cover_art} />
        {/* value={release} errors={apiError} /> */}
        {/* {error && <p className="text-red text-right">{error}</p>} */}
      </form>
    </div>
  );
}

export default ReleaseDetailForm;
