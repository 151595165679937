import { useEffect, useState } from "react";
import FileUpload from "../FileUpload/FileUpload";
import { InsertIcon } from "../Icons/Icons";

interface Props {
  prevImage?: string;
  validate?: (files: FileList) => Promise<boolean>
}

function CoverArt({ prevImage, validate }: Props) {
  const [image, setImage] = useState<string>();

  useEffect(() => {
    if (prevImage) setImage(prevImage);
  }, [prevImage]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("cover art onchange has changed");
    var fr = new FileReader();
    fr.onload = function() {
      setImage(fr.result as string);
    };
    let coverArt = e.target.files?.[0];
    if (coverArt) {
      fr.readAsDataURL(coverArt as File);
    }
  };

  return (
    <div>
      <div className="font-medium">Cover Art</div>
      <div className="md:flex md:gap-10 my-10">
        <div className="md:basis-1/2">
          <FileUpload
            icon={<InsertIcon />}
            children={
              image ? <div className="cursor-pointer">
                <img src={image} alt="cover art" />
              </div> : ""
            }
            validate={validate}
            id="cover_art"
            name="cover_art"
            accept=".jpeg, .jpg, .JPG, .png"
            onChange={onChange}
          // TODO: add error handling
          />
        </div>

        <div className="md:basis-1/2 text-xd_lightblack lg:my-0 my-5 leading-6">
          <p>
            Cover art must be square .jpg or .png file and be at least 3000x3000
            px, not blurry or pixelated.
          </p>

          <ol className="list my-5 ">
            It cannot contain:
            <li>- Social Media Logos or Handles</li>
            <li>- Brand Logos</li>
            <li>
              - Any text except for artist names and/or the name of the release
            </li>
          </ol>

          <p className="my-5">
            If your cover art contains any of the above, your release will be
            rejected (these rules are set by the stores and we have to follow
            them).
          </p>
        </div>
      </div>
    </div>
  );
}

export default CoverArt;
