import { useState } from "react";
import { Tooltip, XAxis, YAxis, AreaChart, Area, ResponsiveContainer } from "recharts";

const streamData = [
    { year: "Jan", streams: 1, data: 1.5 },
    { year: "Feb", streams: 2, data: 1 },
    { year: "Mar", streams: 3, data: 3 },
    { year: "Apr", streams: 4, data: 1 },
    { year: "May", streams: 5, data: 4 },
    { year: "Jun", streams: 5, data: 3 },
    { year: "Jul", streams: 5, data: 6 },
    { year: "Aug", streams: 5, data: 3 },
    { year: "Sep", streams: 5, data: 4 },
    { year: "Oct", streams: 5, data: 3 },
    { year: "Nov", streams: 5, data: 7 },
    { year: "Dec", streams: 5, data: 1 },
];

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="bg-black text-white px-3">
                <div className="flex justify-center">
                    <p className="label">{`${label} : ${payload[0].value}`}</p>
                </div>
                <div>
                    <p className="desc">20 Jun, 2022</p>
                </div>
            </div>
        );
    }

    return null;
};

const Chart = () => {
    const [streamsData] = useState(streamData);
    return (
        <div className="w-full">
            <ResponsiveContainer className="p-0" width="100%" aspect={1.8}>
                <AreaChart
                    width={730}
                    height={250}
                    margin={{ top: 10, right: 0, left: 0, bottom: 0 }} data={streamsData}>
                    <defs>
                        <linearGradient id="colorData" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="rgba(255, 189, 89, 1)" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="rgba(255, 189, 89, 1)" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="year" />
                    <YAxis dataKey="data" unit="k" axisLine={false} />
                    <Tooltip contentStyle={{ backgroundColor: "#000", color: "#fff" }} itemStyle={{ color: "#fff" }} cursor={false} content={CustomTooltip} />
                    <Area type="monotone" dataKey="data" stroke="rgba(255, 189, 89, 1)" fillOpacity={1} fill="url(#colorData)" />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

export default Chart;