import { useState } from "react";
import ReleaseTable from "./ReleaseTable";
import ReleaseListHeader from "./ReleaseListHeader";

export default function Releases() {
  const [toggleview, setToggleview] = useState(false);

  function handleClick() {
    setToggleview((toggleview) => !toggleview);
  }

  return (
    <div className="md:bg-white bg-transparent rounded-[10px] items-center flex-1">
      <ReleaseListHeader toggleview={toggleview} changeView={handleClick} />
      <div className="overflow-x-scroll">
        <ReleaseTable toggleview={toggleview} />
      </div>
    </div>
  );
}
