/* eslint-disable import/no-anonymous-default-export */

export default () => {
    return (
        <svg style={{ width: "100%", height: "100%" }} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.810547" y="0.679688" width="48.3785" height="48.3785" rx="10.7421" fill="#59AFFF" fillOpacity="0.2" />
            <circle cx="24.9997" cy="24.8693" r="14.883" fill="#4AA1F2" />
            <path d="M30.9853 30.4033H18.9043V31.4976H18.0391V32.5919H31.8505V31.4976H30.9852L30.9853 30.4033Z" fill="white" />
            <path d="M25.1047 17.1475L17.9146 21.5772V22.8799H31.9764V21.5772L25.1047 17.1475Z" fill="white" />
            <path d="M19.3872 29.2321V30.0006H22.3567V29.2321H21.9627V24.0761H22.3567V23.3076H19.3872V24.0761H19.7812V29.2321H19.3872Z" fill="white" />
            <path d="M23.4604 29.2321V30.0006H26.4298V29.2321H26.0358V24.0761H26.4298V23.3076H23.4604V24.0761H23.8544V29.2321H23.4604Z" fill="white" />
            <path d="M27.5337 29.2321V30.0006H30.5032V29.2321H30.1092V24.0761H30.5032V23.3076H27.5337V24.0761H27.9277V29.2321H27.5337Z" fill="white" />
        </svg>
    );
};

