import { useFormik } from "formik";
import { useState } from "react";
import sendRequest from "../axios";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Input from "../Input/Index";
import { PuffLoader } from "react-spinners";
import Button from "../Button/Button";
import ErrorTypography from "../ErrorTypography";

function PaypalPayment() {
  const [loading, setLoading] = useState(false);
  var addAccountURL = `/api/v1/user/paypalaccount`;

  const PaymentFormSchema = Yup.object().shape({
    full_name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
  });

  const { handleSubmit, touched, errors, values, handleChange } = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      country: "",
    },
    validationSchema: PaymentFormSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        // Change request url
        const response = await sendRequest("PUT", addAccountURL, values);
        console.log(response);
        if (response.status < 300) {
          toast.success(`${response.data.message}`);
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.errors);
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <div className="lg:px-32 p-5 min-w-72">
      <div className="font-medium text-18 leding-[22.56px]">
        Enter Payout Details
      </div>
      <div className="flex justify-center flex-col lg:px-28 mt-5 ">
        <form onSubmit={handleSubmit} onChange={handleChange}>
          <div>
            <Input
              className=""
              id="full_name"
              placeholder={values.full_name === "" ? "John Doe" : values.full_name} 
              type="text"
              label="Full Name"
            />
            {touched.full_name && errors.full_name && (
              <ErrorTypography error={errors.full_name} />
            )}
          </div>
          <div className="my-10">
            <Input
              className=""
              id="email"
              placeholder={values.email === "" ? "johndoe@gmail.com" : values.email}
              type="email"
              label="Paypal Email"
            />
            {touched.email && errors.email && (
              <ErrorTypography error={errors.email} />
            )}
          </div>
          <div>
            <Input
              className=""
              id="country"
              placeholder={values.country === "" ? "United Kingdom" : values.country}
              type="text"
              label="Country"
            />
            {touched.country && errors.country && (
              <ErrorTypography error={errors.country} />
            )}
          </div>
          <div className="my-10 w-full">
            <Button disabled={loading} type="submit" className="w-full">
              {loading ? (
                <PuffLoader className="h-4 w-4" size={25} color="white" />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PaypalPayment;
