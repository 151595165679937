/* eslint-disable import/no-anonymous-default-export */
interface Props {
  strokeColor?: string;
}
export default ({ strokeColor }: Props) => {
  return (
    <svg
      style={{width:"100%", height:"100%"}}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3496 3.40799C10.7943 3.31321 11.1927 3.6682 11.1927 4.12296C11.1927 4.50054 10.9138 4.81677 10.5458 4.90113C7.02357 5.70841 4.39624 8.86181 4.39624 12.6288C4.39624 17.0073 7.94572 20.5568 12.3242 20.5568C16.0862 20.5568 19.2361 17.9366 20.0486 14.4215C20.1336 14.0541 20.4495 13.7759 20.8266 13.7759C21.282 13.7759 21.6371 14.1753 21.5414 14.6205C20.6274 18.8709 16.8478 22.0568 12.3242 22.0568C7.11729 22.0568 2.89624 17.8358 2.89624 12.6288C2.89624 8.0992 6.09063 4.31563 10.3496 3.40799Z"
        fill="#828282"
      />
      <mask
        id="mask0_2899_10555"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="12"
        y="2"
        width="11"
        height="11"
      >
        <path
          d="M20.9109 12.057C22.0155 12.057 22.9311 11.1528 22.7116 10.0703C22.5842 9.44182 22.3963 8.82552 22.1497 8.2302C21.6472 7.01694 20.9106 5.91455 19.982 4.98596C19.0534 4.05738 17.951 3.32078 16.7378 2.81823C16.1425 2.57164 15.5262 2.38377 14.8977 2.25637C13.8151 2.03692 12.9109 2.95246 12.9109 4.05703V10.057C12.9109 11.1616 13.8064 12.057 14.9109 12.057H20.9109Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2899_10555)">
        <path
          d="M20.9109 12.057C22.0155 12.057 22.9311 11.1528 22.7116 10.0703C22.5842 9.44182 22.3963 8.82552 22.1497 8.2302C21.6472 7.01694 20.9106 5.91455 19.982 4.98596C19.0534 4.05738 17.951 3.32078 16.7378 2.81823C16.1425 2.57164 15.5262 2.38377 14.8977 2.25637C13.8151 2.03692 12.9109 2.95246 12.9109 4.05703V10.057C12.9109 11.1616 13.8064 12.057 14.9109 12.057H20.9109Z"
          stroke="#828282"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};
