import React, { useState } from 'react'
import IChart from '../../interfaces/chart'
import ChartData from "../../data/ChartData"
import Table from '../Table/Table'

function GetChartTableData(
    data: IChart[]
): { [index: string]: React.ReactNode }[] {
    return data.map((d, i) => {
        return {
            id: d.id,
            logo: d.logo,
            partners: d.partner,
            streams: d.streams,
        }
    })
}

function ChartTable() {
    const [charts] = useState(ChartData)
    const columns = [
        {title: "", dataIndex: "logo", key: "1" },
        { title: "Partners", dataIndex: "partners", key: "2" },
        { title: "Streams", dataIndex: "streams", key: "3" },
    ];
  return (
    <div className="">
            <div className="inline-block min-w-full">
                <Table data={GetChartTableData(charts)} columns={columns} />
            </div>
        </div>
  )
}

export default ChartTable