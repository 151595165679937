/* eslint-disable import/no-anonymous-default-export */
export default () => {
    return (
        <svg
            style={{ width: "100%", height: "100%" }}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="24.2881" cy="24.3018" r="23.585" fill="#FFBD59" />
            <path
                d="M24.4441 15.082L17.3611 20.6383M24.4441 34.8375L24.4441 15.082L24.4441 34.8375ZM24.4441 15.082L30.873 20.6383L24.4441 15.082Z"
                stroke="#1F1F1F"
                strokeWidth="2.01465"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
