/* eslint-disable import/no-anonymous-default-export */
interface Props {
    strokeColor?: string;
}
export default ({ strokeColor }: Props) => {
    return (
        <svg style={{ width: "100%", height: "100%" }} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.08521 14.4336L11.5852 7.24609L19.0852 14.4336" stroke={`${strokeColor || "#1F1F1F"}`} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    );
};




