/* eslint-disable import/no-anonymous-default-export */
export default () => {
    return (
        <svg
            style={{ width: "100%", height: "100%" }}
            viewBox="0 0 85 86"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_4733_19332)">
                <circle cx="42.3804" cy="39.4693" r="33.1226" fill="#FFBD59" />
                <circle
                    cx="42.3804"
                    cy="39.4693"
                    r="31.5253"
                    stroke="#FAF7F2"
                    strokeWidth="3.19465"
                />
            </g>
            <rect
                x="19.2979"
                y="16.3867"
                width="46.1648"
                height="46.1648"
                rx="11.8533"
                fill="#FFBD59"
            />
            <path
                d="M33.69 42.6134C35.9226 42.6134 37.7325 40.8035 37.7325 38.5708C37.7325 36.3382 35.9226 34.5283 33.69 34.5283C31.4574 34.5283 29.6475 36.3382 29.6475 38.5708C29.6475 40.8035 31.4574 42.6134 33.69 42.6134Z"
                fill="#1F1F1F"
            />
            <path
                d="M49.1579 32.5931C51.293 32.5931 53.0238 30.8623 53.0238 28.7272C53.0238 26.5922 51.293 24.8613 49.1579 24.8613C47.0228 24.8613 45.292 26.5922 45.292 28.7272C45.292 30.8623 47.0228 32.5931 49.1579 32.5931Z"
                fill="#1F1F1F"
            />
            <path
                d="M35.9746 36.5927L46.3076 30.627"
                stroke="#1F1F1F"
                strokeWidth="2.10921"
            />
            <path
                d="M49.1577 44.5566C51.2879 44.5566 53.0147 46.2834 53.0147 48.4136C53.0147 50.5437 51.2879 52.2705 49.1577 52.2705C47.0276 52.2705 45.3008 50.5437 45.3008 48.4136C45.3008 46.2834 47.0276 44.5566 49.1577 44.5566Z"
                fill="#1F1F1F"
            />
            <path
                d="M35.9746 40.8896L46.3076 46.8554"
                stroke="#1F1F1F"
                strokeWidth="2.10921"
            />
            <defs>
                <filter
                    id="filter0_d_4733_19332"
                    x="0.139902"
                    y="0.875933"
                    width="84.4809"
                    height="84.4809"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3.64716" />
                    <feGaussianBlur stdDeviation="4.55896" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.35 0 0 0 0 0.161718 0 0 0 0 0.025375 0 0 0 0.21 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_4733_19332"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_4733_19332"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
