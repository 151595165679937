import React from "react";
import { HiCheck } from "react-icons/hi";

interface Props {
  children?: React.ReactNode;
}

const Feature: React.FC<Props> = ({ children }) => {
  return (
    <div className="flex flex-row gap-3 text-13  items-center">
      <div className="bg-xd_yellow rounded-3xl w-5 h-5">
        <HiCheck className="text-black m-1 text-13 " />
      </div>

      {children}
    </div>
  );
};

export default Feature;
