import React, { useEffect } from "react";
import CheckBox from "../CheckBox/CheckBox";
import { useCreateReleaseContext } from "./CreateReleaseContext";
import sendRequest from "../axios";
import iconList from "../../data/StoreIconList";
import Input from "../Input/Index";
import XDSwitch from "../Switch/Index";
import { useDashboardContext } from "../Dashboard/DashboardContext";
import { addDays } from "../../utils/date";

interface TrackPriceProps {
  checked?: boolean;
  category?: string;
  value: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface StoreOptionProps {
  header?: React.ReactNode;
  body?: React.ReactNode;
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

interface IStore {
  id: string;
  name: string;
  logo_url: string;
}

const TrackPrice: React.FC<TrackPriceProps> = ({
  value,
  checked,
  onChange,
  category,
  label,
}) => {
  return (
    <div className="items-center flex flex-col">
      <div className="text-13 font-medium text-[#828282] leading-[15.26px]">
        {category}
      </div>
      <div className="flex gap-2 items-center">
        <CheckBox
          defaultChecked={checked}
          name="price_per_track"
          id={value}
          value={value}
          type="radio"
          onChange={onChange}
        />
        <label htmlFor="price_per_track">{label}</label>
      </div>
    </div>
  );
};

const StoreOption: React.FC<StoreOptionProps> = ({
  body,
  header,
  id,
  onChange,
  checked,
}) => {
  return (
    <div className="flex gap-3 items-center">
      <div>
        <CheckBox
          value={id}
          name={"store_option"}
          id={id}
          onChange={onChange}
          defaultChecked={checked === true}
        />
      </div>
      <label htmlFor={id}>
        <div>{header}</div>
        <div className="text-xd_grey">{body}</div>
      </label>
    </div>
  );
};
const AllStoreOption = "all-stores";
const CustomSelectionOption = "custom-selection";

function Distribution() {
  const { currentPlan } = useDashboardContext();
  const { newRelease, release } = useCreateReleaseContext();
  const [stores, setStores] = React.useState([] as IStore[]);
  const [selectedStores, setSelectedStores] = React.useState(new Set());
  const [storeOption, setStoreOption] = React.useState(CustomSelectionOption);
  const [preOrder, setPreOrder] = React.useState(false);
  const storesKey = "stores";

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    newRelease["release-distribution"].set(name, value);
  }

  useEffect(() => {
    console.log({ currentPlan });
    sendRequest("get", "/api/v1/stores", {})
      .then((res) => {
        setStores(res.data.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  useEffect(() => {
    // console.log("i changed to: "+storeOption)
    if (storeOption === CustomSelectionOption) {
      newRelease["release-distribution"].set(
        storesKey,
        JSON.stringify(Array.from(selectedStores))
      );
    } else {
      newRelease["release-distribution"].set(storesKey, `["all"]`);
    }
  }, [selectedStores, storeOption]);

  useEffect(() => {
    newRelease["release-distribution"].delete("id");
    newRelease["release-distribution"].set("release_id", release.id);
    // TODO: specify the currency dynamically
    newRelease["release-distribution"].set("currency_code", "USD");

    if (release.distribution?.stores?.length === stores.length) {
      setStoreOption(AllStoreOption);
    }
    if (release.distribution?.stores?.length) {
      setSelectedStores(
        new Set(release.distribution?.stores?.map((store) => store.id))
      );
    }

    if (release.distribution?.price_per_track) {
      newRelease["release-distribution"].set(
        "price_per_track",
        `${release.distribution?.price_per_track}`
      );
    }

    if (release.distribution?.preorder_date) {
      newRelease["release-distribution"].set(
        "pre_order_date",
        new Date(release.distribution?.preorder_date || "")
          .toISOString()
          .slice(0, 10)
      );

      setPreOrder(true);
    }
    if (release.distribution?.release_time) {
      newRelease["release-distribution"].set(
        "release_time",
        new Date(release.distribution?.release_time || "")
          .toISOString()
          .slice(0, 16)
      );
    }
    if (release.distribution?.existing_artist_name) {
      newRelease["release-distribution"].set(
        "existing_artist_name",
        release.distribution?.existing_artist_name
      );
    }
    if (release.distribution?.prev_spotify_url) {
      newRelease["release-distribution"].set(
        "prev_spotify_url",
        release.distribution?.prev_spotify_url
      );
    }
    if (release.distribution?.prev_apple_music_url) {
      newRelease["release-distribution"].set(
        "prev_apple_music_url",
        release.distribution?.prev_apple_music_url
      );
    }
  }, [release, stores]);

  useEffect(() => {
    if (!preOrder) {
      newRelease["release-distribution"].delete("pre_order_date");
    }
  }, [preOrder]);

  const prices = [
    {
      label: "$0.69",
      value: 69,
      category: "Single Low",
    },
    {
      label: "$0.99",
      value: 99,
      category: "Single Mid",
    },
    {
      label: "$1.29",
      value: 129,
      category: "Single High",
    },
    {
      label: "$2.99",
      value: 299,
      category: "Mini EP",
    },
    {
      label: "$2.99",
      value: 299,
      category: "Standard EP",
    },
    {
      label: "$10.99",
      value: 1099,
      category: "Album Low",
    },
    {
      label: "$11.99",
      value: 1199,
      category: "Album High",
    },
  ];

  return (
    <div>
      <div className="mt-6 mx-2 lg:mx-44 md:mx-20">
        <div className="space-y-10 m-5 items-center">
          <div>
            <h1 className=" font-medium text-18">Track Pricing</h1>
            <div className="text-xd_grey font-medium my-2 text-13">
              How much would you charge for each track
            </div>
            <div className="flex gap-6 flex-wrap">
              {prices.map((price, i) => {
                return (
                  <TrackPrice
                    key={i}
                    checked={
                      release.distribution?.price_per_track == price.value
                    }
                    category={price.category}
                    label={price.label}
                    value={`${price.value}`}
                    onChange={onChange}
                  />
                );
              })}
            </div>
          </div>
          <div className="">
            <h1 className="font-medium text-18 mb-5">Select Music Stores</h1>
            <div className="lg:flex gap-5">
              <div>
                <StoreOption
                  id={AllStoreOption}
                  header={"All Stores"}
                  body={"Select All stores"}
                  onChange={(e) => {
                    console.log("option: " + e.target.value);
                    setStoreOption(e.target.value);
                  }}
                  checked={storeOption === AllStoreOption}
                />
              </div>
              <div className="mt-7 lg:mt-0 md:mt-7">
                <StoreOption
                  id={CustomSelectionOption}
                  header={"Custom Selection"}
                  body="Select the stores you want your track to be distributed to."
                  onChange={(e) => {
                    console.log("option custom: " + e.target.value);
                    setStoreOption(e.target.value);
                  }}
                  checked={storeOption === CustomSelectionOption}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10 items-center">
          <div className="">
            <div className="mt-10">
              <div className="bg-xd_bleach border-gray-400 border-[1px] rounded-md m-2 ">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 p-4">
                  {stores.map((store, i) => {
                    return (
                      <div
                        key={i}
                        className="border-gray-400 border-[1px] h-[100px] p-4 rounded-[4px] text-center items-center flex justify-center relative">
                        <div className="h-[100%]">
                          <CheckBox
                            className="absolute top-2 right-2"
                            type="checkbox"
                            id={`${store.id}`}
                            value={store.id}
                            checked={
                              storeOption === AllStoreOption ||
                              selectedStores.has(store.id)
                            }
                            onChange={(e) => {
                              //console.log(e.target.checked);
                              if (e.target.checked) {
                                selectedStores.add(store.id);
                              } else {
                                if (storeOption === AllStoreOption) {
                                  setStoreOption(CustomSelectionOption);
                                }
                                selectedStores.delete(store.id);
                              }
                              setSelectedStores(new Set(selectedStores));
                            }}
                          />
                          <label className=" w-full" htmlFor={`${store.id}`}>
                            {store.logo_url ? (
                              <img src={store.logo_url} alt={store.name} />
                            ) : (
                              iconList[i]?.icon
                            )}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex md:flex-row flex-col justify-between items-end gap-8 px-2 lg:px-0 mt-10">
          <div className=" w-full">
            <div className="font-medium text-18 mb-3">Release Date & Time</div>
            <div className="">
              {/* TODO: specify timezone */}
              <div className="space-y-2">
                <Input
                  className=""
                  id="release_time"
                  placeholder=""
                  type="datetime-local"
                  label=""
                  name="release_time"
                  min={addDays(new Date(), currentPlan?.minimal_release_date || 0).toISOString().slice(0, 16)}
                  value={
                    release.distribution?.release_time &&
                      !release.distribution?.release_time.startsWith("0001-01-01")
                      ? new Date(release.distribution?.release_time || "")
                        .toISOString()
                        .slice(0, 16)
                      : ""
                  }
                  onChange={onChange}
                />
                <div className="text-xd_grey text-xs">
                  IMPORTANT: Please submit your release at least 2weeks to release date.
                  This will aid the delivery process and make sure your release is out on time.
                </div>
              </div>
            </div>
          </div>
          {/* <div className="px-2 lg:px-40 my-20"> */}
          <div className="w-full">
            <div className="font-medium text-18 flex items-center gap-2">
              <div>Pre-Order</div>
              <div className="font-normal text-sm">(Optional)</div>
              <div>
                <XDSwitch checked={preOrder} onChange={setPreOrder} />
              </div>
            </div>
            {preOrder ? (
              <div className="">
                <Input
                  className=""
                  id="Release Date"
                  placeholder=""
                  type="date"
                  label="Pre-Order Date"
                  name="pre_order_date"
                  min={new Date().toISOString().slice(0, 10)}
                  value={
                    release.distribution?.preorder_date
                      ? new Date(release.distribution?.preorder_date || "")
                        .toISOString()
                        .slice(0, 10)
                      : ""
                  }
                  onChange={onChange}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Distribution;
