function PlaylistIcon() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5989 15.1813C15.4167 15.1813 16.8903 13.7077 16.8903 11.89C16.8903 10.0722 15.4167 8.59863 13.5989 8.59863C11.7812 8.59863 10.3076 10.0722 10.3076 11.89C10.3076 13.7077 11.7812 15.1813 13.5989 15.1813Z" stroke="#828282" strokeWidth="1.3" strokeLinecap="round" />
      <path d="M16.8901 12.0526V0.817871H20.9025" stroke="#828282" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.07324 0.817871H13.4012M1.07324 5.46993H11.7381M1.07324 10.122H7.87687M1.07324 14.774H7.87687" stroke="#828282" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}

export default PlaylistIcon;
